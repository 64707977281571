import { ComponentProps, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Tooltip, useMediaQuery, Theme } from '@mui/material';
import { ConstructionRounded as GenerateIcon } from '@mui/icons-material';

import { GenerateState, ProjectState } from 'api';
import { useKeyboardShortcut, usePartitionStates, useSnackbar } from 'hooks';

interface Props {
  onOpen: () => void;
}

export const ProjectBuildFilesButton: FC<Props> = ({ onOpen }) => {
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'));
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const { allPartitionsAreSaved } = usePartitionStates();

  const { data: generateState } = useQuery<GenerateState>({
    queryKey: ['generate', { projectId }],
  });

  const color = useMemo<ComponentProps<typeof Button>['color']>(() => {
    if (generateState) {
      if (generateState.projectState === ProjectState.GenerationFailed)
        return 'error';
      if (generateState.projectState === ProjectState.GenerationCanceled)
        return 'warning';
      if (
        generateState.projectState === ProjectState.Generating ||
        generateState.projectState === ProjectState.GenerationQueued
      )
        return 'secondary';
    }
    return 'primary';
  }, [generateState]);

  useKeyboardShortcut({ key: 'g', ctrl: true }, () => {
    if (allPartitionsAreSaved) onOpen();
    else
      enqueueSnackbar({
        key: 'missing_settings',
        message: t('missing_settings'),
        variant: 'info',
      });
  });

  if (!generateState) return null;

  return (
    <Tooltip
      title={t(
        allPartitionsAreSaved
          ? 'generate_build_files_helper'
          : 'missing_settings',
      )}
      placement="bottom"
    >
      <span>
        <Button
          variant="contained"
          color={color}
          size="small"
          startIcon={!xs && <GenerateIcon />}
          onClick={onOpen}
          disabled={!allPartitionsAreSaved}
          sx={{ minWidth: { xs: 32, sm: 160 }, width: { xs: 32, sm: 'auto' } }}
        >
          {xs ? (
            <GenerateIcon />
          ) : (
            t(`generate_build_files_${generateState.projectState}`, {
              progress: generateState.progressPercentage,
            })
          )}
        </Button>
      </span>
    </Tooltip>
  );
};
