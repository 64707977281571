import { useCallback, useEffect } from 'react';

import { useSelector } from 'hooks';
import { selectKeyboardShortcuts } from 'slices/appSlice';

export interface KeyboardShortcut {
  key: string;
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
  meta?: boolean;
}

export const useKeyboardShortcut = (
  {
    key,
    ctrl = false,
    shift = false,
    alt = false,
    meta = false,
  }: KeyboardShortcut,
  callback: () => void,
  disabled?: boolean,
): void => {
  const keyboardShortcuts = useSelector(selectKeyboardShortcuts);

  const handleKeyDown = useCallback<(event: KeyboardEvent) => void>(
    (event) => {
      if (
        keyboardShortcuts &&
        event.key?.toLowerCase() === key.toLowerCase() &&
        event.ctrlKey === ctrl &&
        event.altKey === alt &&
        event.shiftKey === shift &&
        event.metaKey === meta &&
        !disabled
      ) {
        event.preventDefault();
        callback();
      }
    },
    [alt, callback, ctrl, disabled, key, keyboardShortcuts, meta, shift],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
