import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import 'cross-fetch/polyfill';

import 'utils/i18n';
import 'styles';
import { SnackbarProvider, ThemeProvider, QueryProvider } from 'providers';
import { store } from 'utils';
import { App } from 'App';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider>
        <CssBaseline enableColorScheme />
        <SnackbarProvider>
          <QueryProvider>
            <App />
          </QueryProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StoreProvider>
  </StrictMode>,
);
