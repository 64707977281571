import axios from 'axios';
import buildQuery from 'odata-query';

import {
  ProjectRequestParams,
  FilteredProjects,
  ODataProjects,
  ProjectPreviewWithEtag,
  ProjectPreviewResponse,
  ProjectSettingsResponseWithEtag,
  ProjectSettingsResponse,
  CreateProject,
  UpdateProject,
  CopyProject,
  ProcessStep,
} from 'api';

export const getProjects = async (
  params?: ProjectRequestParams,
): Promise<FilteredProjects> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataProjects>(`projects${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getProjectPreview = async (
  id: string,
): Promise<ProjectPreviewWithEtag> => {
  const { headers, data } = await axios.get<ProjectPreviewResponse>(
    `projects/${id}/preview`,
  );
  return { etag: String(headers.etag), data };
};

export const getProjectSettings = async (
  id: string,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.get<ProjectSettingsResponse>(
    `projects/${id}/settings`,
  );
  return { etag: String(headers.etag), data };
};

export const createProject = async (
  body: CreateProject,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.post<ProjectSettingsResponse>(
    'projects',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const copyProject = async (
  id: string,
  body: CopyProject,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.post<ProjectSettingsResponse>(
    `projects/${id}/copy`,
    body,
  );
  return { etag: String(headers.etag), data };
};

export const deleteProject = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`projects/${id}`, { headers: { 'If-Match': etag } });
};

export const updateProjectSettings = async (
  id: string,
  body: UpdateProject,
  etag: string,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.put<ProjectSettingsResponse>(
    `projects/${id}/settings`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};

export const getProjectLayerImage = async (
  id: string,
  params: { layer: number; processStep: ProcessStep },
): Promise<string> => {
  const { data } = await axios.get<string>(`projects/${id}/layer-image`, {
    params,
  });
  return data;
};

export const getProjectBuildFileLink = async (id: string): Promise<string> => {
  const { data } = await axios.get<string>(`projects/${id}/build-file-link`);
  return data;
};
