import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  TextField,
  Button,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import {
  AddCircleOutlineRounded as AddButtonIcon,
  AddRounded as AddIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';

interface Props {
  iconButtonProps: IconButtonProps;
  onAddPartition: (breakpoint: number) => void;
  options: number[];
  getOptionDisabled?: (option: number) => boolean;
  getOptionLabel?: (option: number) => string;
}

export const ProjectAddPartitionForm: FC<Props> = ({
  iconButtonProps,
  onAddPartition,
  options,
  getOptionDisabled,
  getOptionLabel,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number | null>(null);

  const handleClose = useCallback<() => void>(() => {
    setValue(null);
    setOpen(false);
  }, [setValue, setOpen]);

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
        {...iconButtonProps}
      >
        <AddButtonIcon />
      </IconButton>
      <AppDialog
        open={open}
        title={t('choose_a_breakpoint')}
        onClose={handleClose}
        actions={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              if (value) onAddPartition(value);
              handleClose();
            }}
            disabled={value === null}
            fullWidth
          >
            {t('add_partition')}
          </Button>
        }
        dialogProps={{ maxWidth: 'xs' }}
      >
        <Autocomplete
          value={value}
          onChange={(_e, v) => {
            setValue(v);
          }}
          options={options}
          renderInput={(params) => <TextField {...params} />}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getOptionLabel}
          fullWidth
        />
      </AppDialog>
    </>
  );
};
