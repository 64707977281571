import { useMemo } from 'react';

import {
  Partition,
  Selection,
  isValidHeatBalancePartition,
  isValidJumpSafePartition,
  isValidMeltPartition,
  isValidSpatterSafePartition,
} from 'api';
import { PartitionQuery } from 'utils';
import { useDispatch, useSelector } from 'hooks';
import { PrimaryMenuTab, selectPrimaryMenu } from 'slices/editorSlice';
import {
  setMeltPartitionSelection,
  setMeltPartitions,
  setSelectedMeltPartitionIds,
} from 'slices/meltSlice';
import {
  setHeatBalancePartitionSelection,
  setHeatBalancePartitions,
  setSelectedHeatBalancePartitionIds,
} from 'slices/heatBalanceSlice';
import {
  setJumpSafePartitionSelection,
  setJumpSafePartitions,
  setSelectedJumpSafePartitionIds,
} from 'slices/jumpSafeSlice';
import {
  setSelectedSpatterSafePartitionIds,
  setSpatterSafePartitionSelection,
  setSpatterSafePartitions,
} from 'slices/spatterSafeSlice';

interface ProcessStepActions {
  setSelection: (selection: Selection) => void;
  setPartitions: (partitions: Partition<unknown>[]) => void;
  setSelectedPartitions: (query: PartitionQuery) => void;
}

export const useProcessStepActions = () => {
  const dispatch = useDispatch();
  const { window } = useSelector(selectPrimaryMenu);

  const { setSelection, setPartitions, setSelectedPartitions } =
    useMemo((): ProcessStepActions => {
      switch (window) {
        case PrimaryMenuTab.jump_safe:
          return {
            setSelection: (selection) =>
              dispatch(setJumpSafePartitionSelection(selection)),
            setPartitions: (partitions) => {
              if (partitions.every(isValidJumpSafePartition))
                dispatch(setJumpSafePartitions(partitions));
              else throw new Error('invalid partitions');
            },
            setSelectedPartitions: (query) =>
              dispatch(setSelectedJumpSafePartitionIds(query)),
          };
        case PrimaryMenuTab.spatter_safe:
          return {
            setSelection: (selection) =>
              dispatch(setSpatterSafePartitionSelection(selection)),
            setPartitions: (partitions) => {
              if (partitions.every(isValidSpatterSafePartition))
                dispatch(setSpatterSafePartitions(partitions));
              else throw new Error('invalid partitions');
            },
            setSelectedPartitions: (query) =>
              dispatch(setSelectedSpatterSafePartitionIds(query)),
          };
        case PrimaryMenuTab.melt:
          return {
            setSelection: (selection) =>
              dispatch(setMeltPartitionSelection(selection)),
            setPartitions: (partitions) => {
              if (partitions.every(isValidMeltPartition))
                dispatch(setMeltPartitions(partitions));
              else throw new Error('invalid partitions');
            },
            setSelectedPartitions: (query) =>
              dispatch(setSelectedMeltPartitionIds(query)),
          };
        case PrimaryMenuTab.heat_balance:
          return {
            setSelection: (selection) =>
              dispatch(setHeatBalancePartitionSelection(selection)),
            setPartitions: (partitions) => {
              if (partitions.every(isValidHeatBalancePartition))
                dispatch(setHeatBalancePartitions(partitions));
              else throw new Error('invalid partitions');
            },
            setSelectedPartitions: (query) =>
              dispatch(setSelectedHeatBalancePartitionIds(query)),
          };
        default:
          return {
            setSelection: () => {
              return;
            },
            setPartitions: () => {
              return;
            },
            setSelectedPartitions: () => {
              return;
            },
          };
      }
    }, [dispatch, window]);

  return useMemo(
    (): ProcessStepActions => ({
      setSelection,
      setPartitions,
      setSelectedPartitions,
    }),
    [setSelection, setPartitions, setSelectedPartitions],
  );
};
