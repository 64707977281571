import {
  FC,
  useMemo,
  useState,
  createRef,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { HeatBalanceForm } from 'components/AppProcessStepForms';
import { ProjectMenuPanelGroup } from 'components/ProjectMenuPanelGroup';
import { AppTextForm } from 'components/AppFormControl';
import { useSnackbar } from 'hooks';
import {
  ErrorResponse,
  HeatBalance,
  HeatBalancePreset,
  ProjectSettingsResponseWithEtag,
  createHeatBalancePreset,
} from 'api';
import { defaultHeatBalance } from 'slices/heatBalanceSlice';

interface Props {
  open: boolean;
  onClose: () => void;
  initialSetting?: HeatBalance;
}

export const CreateHeatBalancePresetDialog: FC<Props> = ({
  open,
  onClose,
  initialSetting = defaultHeatBalance,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [values, setValues] = useState<HeatBalance>(initialSetting);

  const [validationError, setValidationError] = useState<
    (keyof HeatBalancePreset)[]
  >([]);
  const refs = useMemo(
    (): Record<keyof HeatBalancePreset, RefObject<HTMLInputElement>> => ({
      name: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
      type: createRef<HTMLInputElement>(),
      area: createRef<HTMLInputElement>(),
      repetitions: createRef<HTMLInputElement>(),
      pointSpreadAlgName: createRef<HTMLInputElement>(),
      pointSpreadSettings: createRef<HTMLInputElement>(),
      dwellTimeAlgName: createRef<HTMLInputElement>(),
      dwellTimeSettings: createRef<HTMLInputElement>(),
      gridSizeMillimeter: createRef<HTMLInputElement>(),
      gridOffsetPercent: createRef<HTMLInputElement>(),
      beamPowerWatt: createRef<HTMLInputElement>(),
      spotSizeMicrometer: createRef<HTMLInputElement>(),
      seeds: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const { data: project } = useQuery<ProjectSettingsResponseWithEtag>({
    queryKey: ['project-settings', { projectId }],
    enabled: !!projectId,
  });

  useEffect(() => {
    if (project) setMaterial(project.data.material);
  }, [project]);

  const handleResetForm = useCallback<() => void>(() => {
    setName('');
    setMaterial('');
    setValues(initialSetting);
  }, [initialSetting]);

  const handleClose = useCallback<() => void>(() => {
    onClose();
    setValidationError([]);
    handleResetForm();
  }, [handleResetForm, onClose]);

  const { mutate: onCreatePreset, isPending: isCreating } = useMutation({
    mutationFn: () => createHeatBalancePreset({ name, material, ...values }),
    onSuccess: ({ data }) => {
      enqueueSnackbar({
        key: `create_preset_success_${Date.now()}`,
        message: t('create_preset_success', { name: data.name }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['heat-balance-presets'],
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      enqueueSnackbar({
        key: `create_preset_fail_${Date.now()}`,
        message: t('create_preset_fail'),
        variant: 'error',
        persist: true,
      });
      if (response?.status === 400) {
        if (response.data.errors) {
          const errorKeys = Object.keys(response.data.errors)
            .map<keyof HeatBalancePreset | undefined>((key) => {
              switch (key) {
                case 'Name':
                  return 'name';
                case 'Material':
                  return 'material';
                case 'spotSizeMicrometer':
                  return 'spotSizeMicrometer';
                case 'beamPowerWatt':
                  return 'beamPowerWatt';
                case 'gridOffsetPercent':
                  return 'gridOffsetPercent';
                case 'gridSizeMillimeter':
                  return 'gridSizeMillimeter';
                case 'dwellTimeAlgName':
                  return 'dwellTimeAlgName';
                case 'dwellTimeSettings':
                  return 'dwellTimeSettings';
                case 'pointSpreadAlgName':
                  return 'pointSpreadAlgName';
                case 'pointSpreadSettings':
                  return 'pointSpreadSettings';
                case 'seeds':
                  return 'seeds';
                default:
                  return undefined;
              }
            })
            .filter((key): key is keyof HeatBalancePreset => !!key);
          setValidationError(errorKeys);
          refs[errorKeys[0]]?.current?.focus();
        }
      }
    },
  });

  useEffect(() => {
    setValidationError([]);
  }, [name, material, values]);

  useEffect(() => {
    setValues(initialSetting);
  }, [initialSetting]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('new_preset')}
      actions={
        <Button
          onClick={() => onCreatePreset()}
          variant="contained"
          disabled={isCreating}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {t('create')}
        </Button>
      }
    >
      <Stack spacing={2}>
        <ProjectMenuPanelGroup title={t('info')}>
          <AppTextForm
            ref={refs.name}
            label={t('name')}
            value={name}
            onChange={setName}
            helperText={t('preset_info_name_helper')}
            error={validationError.includes('name')}
            errorText={t('validations:create_preset.name')}
          />
          <AppTextForm
            ref={refs.material}
            label={t('material')}
            value={material}
            onChange={setMaterial}
            helperText={t('preset_info_material_helper')}
            error={validationError.includes('material')}
            errorText={t('validations:create_preset.material')}
          />
        </ProjectMenuPanelGroup>
        <HeatBalanceForm
          values={values}
          onChangeValues={setValues}
          refs={refs}
          errors={validationError.filter(
            (key): key is keyof HeatBalance =>
              key !== 'name' && key !== 'material',
          )}
        />
      </Stack>
    </AppDialog>
  );
};
