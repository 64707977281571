import { createRef, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, CircularProgress, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { ErrorResponse, CopyProject, copyProject } from 'api';
import { useSelector, useSnackbar } from 'hooks';
import { selectProjectSettings } from 'slices/projectSettingsSlice';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ProjectSaveAsDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { name, description, material } = useSelector(selectProjectSettings);
  const [overrideValues, setOverrideValues] = useState({
    name,
    description,
    material,
  });
  const [validationError, setValidationError] = useState<(keyof CopyProject)[]>(
    [],
  );

  const refs = useMemo(
    (): Record<keyof CopyProject, RefObject<HTMLInputElement>> => ({
      name: createRef<HTMLInputElement>(),
      description: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const { mutateAsync: saveProjectAs, isPending: isSavingLoading } =
    useMutation({
      mutationFn: () => copyProject(projectId, overrideValues),
      onSuccess: ({ data }) => {
        enqueueSnackbar({
          key: `create_project_success_${Date.now()}`,
          message: t('create_project_success', {
            projectName: data.name,
          }),
          variant: 'success',
        });
        void queryClient.invalidateQueries({
          queryKey: ['project', { projectId }],
        });
        onClose();
        window.open(`${window.location.origin}/projects/${data.id}`, '_blank');
      },
      onError: ({ response }: AxiosError<ErrorResponse>) => {
        enqueueSnackbar({
          key: `create_project_fail_${Date.now()}`,
          message: t('create_project_fail'),
          variant: 'error',
          persist: true,
        });
        if (response?.status === 400) {
          if (response.data.errors) {
            const errorKeys = Object.keys(response.data.errors)
              .map<keyof CopyProject | undefined>((key) => {
                if (key === 'Name') return 'name';
                if (key === 'Material') return 'material';
                if (key === 'Description') return 'description';
                return undefined;
              })
              .filter((key): key is keyof CopyProject => !!key);
            setValidationError(errorKeys);
            refs[errorKeys[0]]?.current?.focus();
          }
        }
      },
    });

  useEffect(() => {
    setOverrideValues({ name, description, material });
  }, [name, description, material]);

  useEffect(() => {
    setValidationError([]);
  }, [overrideValues]);

  useEffect(() => {
    return () => {
      setValidationError([]);
    };
  }, []);

  return (
    <AppDialog
      open={open}
      title={t('save_project_as')}
      actions={
        <Button
          onClick={() => void saveProjectAs()}
          variant="contained"
          disabled={isSavingLoading}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {isSavingLoading ? <CircularProgress /> : t('save')}
        </Button>
      }
      onClose={onClose}
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={overrideValues.name}
          onChange={(value) => {
            setOverrideValues((prevState) => ({ ...prevState, name: value }));
          }}
          componentProps={{ inputRef: refs.name }}
          helperText={t('project_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:duplicate_project.name')}
        />
        <AppTextForm
          ref={refs.material}
          label={t('material')}
          value={overrideValues.material}
          onChange={(value) => {
            setOverrideValues((prevState) => ({
              ...prevState,
              material: value,
            }));
          }}
          componentProps={{ inputRef: refs.material }}
          helperText={t('project_material_helper')}
          error={validationError.includes('material')}
          errorText={t('validations:duplicate_project.material')}
        />
        <AppTextForm
          label={t('description')}
          value={overrideValues.description}
          onChange={(value) => {
            setOverrideValues((prevState) => ({
              ...prevState,
              description: value,
            }));
          }}
          componentProps={{ multiline: true, inputRef: refs.description }}
          helperText={t('project_description_helper')}
          error={validationError.includes('description')}
          errorText={t('validations:duplicate_project.description')}
        />
      </Stack>
    </AppDialog>
  );
};
