import { FC, ReactNode } from 'react';
import {
  createTheme,
  Theme,
  PaletteColorOptions,
  lighten,
  darken,
  alpha,
  ThemeProvider as Provider,
} from '@mui/material';

const primary = '#70BC87';
const primaryLight = '#B6D6C0';
const secondary = '#56C6DB';
const error = '#E95343';
const warning = '#FFA22D';

const textPrimary = '#FAFAFA';
const textSecondary = '#121212';
const textDisabled = '#B9B9B9';

const bgDark = '#181818';
const bgLight = '#212121';

const lightenRate = 0.2;
const darkenRate = 0.3;

const spacing = 4;

const getColorOptions = (
  color: string,
  options?: { light?: string; dark?: string; contrastText?: string },
): PaletteColorOptions => ({
  main: color,
  light: lighten(color, lightenRate),
  dark: darken(color, darkenRate),
  ...options,
});

export const theme: Theme = createTheme({
  palette: {
    primary: getColorOptions(primary, {
      light: primaryLight,
      contrastText: textSecondary,
    }),
    secondary: getColorOptions(secondary, { contrastText: textSecondary }),
    error: getColorOptions(error, { contrastText: textPrimary }),
    warning: getColorOptions(warning, { contrastText: textSecondary }),
    success: getColorOptions(primary, { contrastText: textSecondary }),
    info: getColorOptions(secondary, { contrastText: textSecondary }),
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      disabled: textDisabled,
    },
    background: {
      default: bgDark,
      paper: bgLight,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    mode: 'dark',
    divider: textPrimary,
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Roboto',
      'system-ui',
      '-apple-system',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(', '),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.64rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.285rem',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '1.142rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '0.9rem',
    },
    body2: {
      fontSize: '0.7rem',
    },
  },
  spacing,
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          paddingBottom: spacing * 2,
          paddingTop: spacing * 2,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: textPrimary,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: bgLight,
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          paddingLeft: spacing * 4,
          paddingRight: spacing * 4,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        defaultExpanded: true,
      },
      styleOverrides: {
        root: {
          '&:before': {
            background: 'none',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: 'stretch',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: textDisabled,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: 'none',
          padding: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: textPrimary,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: textPrimary,
          fontWeight: 'bold',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: textDisabled,
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          height: '100%',
          width: 'auto',
          overflow: 'auto',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        stickyHeader: {
          borderCollapse: 'collapse',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(250, 250, 250, .25)',
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: `${textPrimary} !important`,
        },
        icon: {
          color: 'inherit !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: bgLight,
          width: '100%',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '100%',
          paddingBottom: spacing * 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: bgLight,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: bgLight,
          height: 50,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: textPrimary,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 50,
          borderRadius: 4,
          backgroundColor: `${textPrimary} !important`,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 24.5,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
        },
        fontSizeLarge: {
          fontSize: 30,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: textPrimary,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontWeight: 'bold',
        },
        containedPrimary: {
          color: textSecondary,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: textSecondary,
          fontWeight: 'bold',
          borderColor: primary,
          backgroundColor: alpha(primaryLight, 0.2),
          '&:hover': {
            backgroundColor: alpha(primaryLight, 0.3),
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: textSecondary,
            backgroundColor: primary,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.7rem',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          marginTop: '0 !important',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
  },
});

interface Props {
  children: ReactNode;
}

export const ThemeProvider: FC<Props> = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
);
