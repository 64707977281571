import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, SvgIcon, Typography, alpha } from '@mui/material';

import { MenuItem } from 'components/ProjectPanelMenu';

interface Props extends Omit<MenuItem<string>, 'Panel'> {
  active: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const ProjectMenuItem: FC<Props> = ({
  Icon,
  name,
  disabled,
  active,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      sx={{
        width: 1,
        color: active ? 'primary.main' : 'text.primary',
        p: 2,
        '&:disabled': {
          color: 'text.disabled',
        },
        '&:hover, &:focus, &:active': {
          backgroundColor: ({ palette }) => alpha(palette.primary.light, 0.1),
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Box component="div">
        <SvgIcon fontSize="large">
          <Icon />
        </SvgIcon>
        <Typography variant="body2">{t(name)}</Typography>
      </Box>
    </ButtonBase>
  );
};
