import { FC, ReactNode } from 'react';
import {
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { CloseRounded as CloseIcon } from '@mui/icons-material';

interface Props {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  title?: ReactNode | string;
  actions?: ReactNode;
  anchor?: DrawerProps['anchor'];
  children?: ReactNode;
}

export const AppPreviewDrawer: FC<Props> = ({
  open,
  onClose,
  isLoading,
  title,
  actions,
  anchor = 'right',
  children,
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      sx={{ width: { xs: 1, md: 450 } }}
      PaperProps={{ sx: { width: { xs: 1, md: 450 } } }}
    >
      <Toolbar />
      {isLoading ? (
        <Skeleton variant="rectangular" sx={{ flex: 1 }} />
      ) : (
        <Stack
          height={1}
          p={2}
          spacing={4}
          position="relative"
          overflow="hidden"
        >
          {title && (
            <Stack spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {typeof title === 'string' ? (
                  <Typography variant="h4">{title}</Typography>
                ) : (
                  title
                )}
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider />
            </Stack>
          )}
          <Stack flex={1} spacing={4} overflow="auto">
            {children}
          </Stack>
          {actions}
        </Stack>
      )}
    </Drawer>
  );
};
