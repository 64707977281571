import {
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Stack } from '@mui/material';
import {
  SearchRounded as SearchIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';

import { AppButton } from 'components/AppButton';

export interface Props {
  searchPhrase: string | undefined;
  onSearch: (searchPhrase: string | undefined) => void;
  buttonProps?: ComponentProps<typeof AppButton>['buttonProps'];
}

export const SearchActionButton = forwardRef<HTMLDivElement, Props>(
  ({ searchPhrase, onSearch, buttonProps }, ref) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [active, setActive] = useState<boolean>(searchPhrase ? true : false);
    const [search, setSearch] = useState<string | undefined>(searchPhrase);

    const handleButtonClick = useCallback(() => {
      setActive((prevState) => {
        if (prevState) setSearch('');
        else inputRef.current?.focus();
        return !prevState;
      });
    }, []);

    useEffect(() => {
      if (search !== searchPhrase) {
        const timeout = setTimeout(() => {
          onSearch(search?.length ? search : undefined);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }, [onSearch, search, searchPhrase]);

    return (
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        width={150}
      >
        <Input
          inputRef={inputRef}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          placeholder={t('search_placeholder')}
          sx={{
            px: active ? 2 : 0,
            m: 0,
            height: 30,
            width: active ? 120 : 0,
            borderWidth: active ? 2 : 0,
            borderTopLeftRadius: '3px',
            borderBottomLeftRadius: '3px',
            borderStyle: 'solid',
            borderColor: 'primary.main',
            borderRight: 'none',
            background: 'none',
            transition: ({ transitions }) =>
              transitions.create(['width', 'padding'], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),
          }}
          disableUnderline
        />
        <AppButton
          title={t('search')}
          buttonProps={{
            ...buttonProps,
            onClick: handleButtonClick,
          }}
          buttonSx={
            active
              ? {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeft: 'none',
                  borderColor: 'primary.main',
                  '&:hover, &:focus, &:active': {
                    borderColor: 'primary.main',
                  },
                }
              : undefined
          }
        >
          {active ? <CloseIcon /> : <SearchIcon />}
        </AppButton>
      </Stack>
    );
  },
);
SearchActionButton.displayName = 'SearchActionButton';
