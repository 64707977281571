import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button, IconButton, Skeleton } from '@mui/material';
import {
  CheckRounded as SubmitIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppToggleButtonForm } from 'components/AppFormControl';
import { updateUser, ErrorResponse, getUserPreview, UserRole } from 'api';
import { useSnackbar } from 'hooks';

const SELECTABLE_ROLES: string[] = [UserRole.OrganizationAdmin, UserRole.User];

const isUserRole = (arg: unknown): arg is UserRole =>
  typeof arg === 'string' && SELECTABLE_ROLES.includes(arg);

interface Props {
  userId: string | undefined;
  onClose: () => void;
}

export const UpdateUserDialog: FC<Props> = ({ userId, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [role, setRole] = useState<UserRole>();

  const handleClose = useCallback<() => void>(() => {
    setRole(undefined);
    onClose();
  }, [onClose]);

  const {
    data: preview,
    isLoading: isPreviewLoading,
    isRefetching: isPreviewRefetching,
    isError: isPreviewError,
    isRefetchError: isPreviewRefetchError,
  } = useQuery({
    queryKey: ['user', { userId }],
    queryFn: () => getUserPreview(userId!),
    enabled: !!userId,
  });

  useEffect(() => {
    if (isPreviewError || isPreviewRefetchError)
      enqueueSnackbar({
        key: `get_user_preview_fail_${Date.now()}`,
        message: t('get_user_preview_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isPreviewError, isPreviewRefetchError, t]);

  const { mutate: onUpdateUser, isPending: isUpdating } = useMutation({
    mutationKey: ['user', { userId }],
    mutationFn: () => {
      if (!preview) throw new Error('user preview does not exist');
      if (!role) throw new Error('role cannot be undefined');
      return updateUser(
        { email: preview.data.email, type: role },
        preview.etag,
      );
    },

    onSuccess: () => {
      enqueueSnackbar({
        key: `update_user_success_${Date.now()}`,
        message: t('update_user_success', {
          email: preview?.data.email,
          role: t(`userRole:${role}`),
        }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['user', { userId }],
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'update_user_decrepit',
          message: t('update_user_decrepit', {
            email: preview?.data.email,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({ queryKey: ['user', { userId }] })
                  .then(() => {
                    closeSnackbar('update_user_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `update_user_fail_${Date.now()}`,
          message: t('update_user_fail', { email: preview?.data.email }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  const handleSubmit = useCallback<() => void>(() => {
    if (role !== preview?.data.type) {
      onUpdateUser();
    } else {
      handleClose();
    }
  }, [preview?.data.type, handleClose, onUpdateUser, role]);

  useEffect(() => {
    if (preview?.data.type) setRole(preview?.data.type);
  }, [preview?.data.type]);

  return (
    <AppDialog
      open={!!userId}
      onClose={handleClose}
      title={t('update_user')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={<SubmitIcon />}
          disabled={isUpdating || isPreviewLoading || isPreviewRefetching}
          fullWidth
        >
          {t('update')}
        </Button>
      }
    >
      <Stack spacing={3}>
        {role ? (
          <AppToggleButtonForm
            label={t('role')}
            value={role}
            onChange={(value) => {
              if (isUserRole(value)) setRole(value);
            }}
            buttons={SELECTABLE_ROLES.map((value) => ({
              value,
              text: t(`userRole:${value}`),
            }))}
            helperText={t('update_user_role_helper')}
          />
        ) : (
          <Skeleton />
        )}
      </Stack>
    </AppDialog>
  );
};
