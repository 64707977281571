import axios from 'axios';
import buildQuery from 'odata-query';

import {
  ModelRequestParams,
  FilteredModels,
  ODataModels,
  ModelPreviewWithEtag,
  ModelPreview,
  CreateModel,
} from 'api';

export const getModels = async (
  params?: ModelRequestParams,
): Promise<FilteredModels> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataModels>(`models${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getModelPreview = async (
  id: string,
): Promise<ModelPreviewWithEtag> => {
  const { data, headers } = await axios.get<ModelPreview>(`models/${id}`);
  return { etag: String(headers.etag), data };
};

export const getModelFile = async (id: string): Promise<string> => {
  const { data } = await axios.get<string>(`models/${id}/file`);
  return data;
};

export const createModel = async (
  body: CreateModel,
): Promise<ModelPreviewWithEtag> => {
  const { data, headers } = await axios.post<ModelPreview>('models', body);
  return { etag: String(headers.etag), data };
};

export const deleteModel = async (id: string, etag: string): Promise<void> => {
  await axios.delete(`models/${id}`, { headers: { 'If-Match': etag } });
};
