import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  EditRounded as EditIcon,
  ContentCopyRounded as DuplicateIcon,
  DeleteRounded as DeleteIcon,
  SaveRounded as SaveIcon,
  CloseRounded as CancelIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { AppPreviewDrawer } from 'components/AppPreviewDrawer';
import { StartHeatForm } from 'components/AppProcessStepForms';
import { ProjectMenuPanelGroup } from 'components/ProjectMenuPanelGroup';
import { AppTextForm } from 'components/AppFormControl';
import { DuplicateStartHeatPresetDialog as DuplicatePresetDialog } from 'components/DuplicatePresetDialog';
import { DeletePresetDialog } from 'components/DeletePresetDialog';
import { useSnackbar, useSearchParams } from 'hooks';
import { checkWritePermission } from 'utils';
import {
  ErrorResponse,
  getStartHeatPreset,
  StartHeat,
  StartHeatPreset,
  updateStartHeatPreset,
  UserPreviewWithEtag,
} from 'api';
import { defaultStartHeat } from 'slices/startHeatSlice';

type PresetSettings = Omit<StartHeatPreset, 'name' | 'material'>;

export const StartHeatPresetPreview: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [{ rowId: presetId }, { deleteSearchParam }] = useSearchParams();
  const [name, setName] = useState('');
  const [material, setMaterial] = useState('');
  const [values, setValues] = useState<PresetSettings>();
  const [inUpdateState, setInUpdateState] = useState(false);
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [validationError, setValidationError] = useState<
    (keyof StartHeatPreset)[]
  >([]);
  const refs = useMemo<
    Record<keyof StartHeatPreset, RefObject<HTMLInputElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
      area: createRef<HTMLInputElement>(),
      targetTemperatureDegreeCelsius: createRef<HTMLInputElement>(),
      temperatureSensor: createRef<HTMLInputElement>(),
      pointSpreadAlgName: createRef<HTMLInputElement>(),
      pointSpreadSettings: createRef<HTMLInputElement>(),
      dwellTimeAlgName: createRef<HTMLInputElement>(),
      dwellTimeSettings: createRef<HTMLInputElement>(),
      gridSizeMillimeter: createRef<HTMLInputElement>(),
      beamPowerWatt: createRef<HTMLInputElement>(),
      gridOffsetPercent: createRef<HTMLInputElement>(),
      spotSizeMicrometer: createRef<HTMLInputElement>(),
      seeds: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const {
    data: preset,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['start-heat-preset', { presetId }],
    queryFn: () => getStartHeatPreset(presetId!),
    enabled: !!presetId,
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        key: `get_preset_fail_${Date.now()}`,
        message: t('get_preset_fail'),
        variant: 'error',
      });
      deleteSearchParam('rowId');
    }
  }, [deleteSearchParam, enqueueSnackbar, isError, t]);

  const { mutate: onUpdatePreset, isPending: isUpdating } = useMutation({
    mutationKey: ['start-heat-preset', { presetId }],
    mutationFn: () => {
      if (!preset) throw new Error('preset does not exist');
      if (!values) throw new Error('preset values do not exist');
      return updateStartHeatPreset(
        preset.data.id,
        { name, material, ...values },
        preset.etag,
      );
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `update_preset_success_${Date.now()}`,
        message: t('update_preset_success', { name }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['start-heat-presets'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['start-heat-preset', { presetId }],
      });
      setInUpdateState(false);
      setValidationError([]);
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'update_preset_decrepit',
          message: t('update_preset_decrepit', { name }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({
                    queryKey: ['start-heat-preset', { presetId }],
                  })
                  .then(() => {
                    closeSnackbar('update_preset_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `update_preset_fail_${Date.now()}`,
          message: t('update_preset_fail', { name }),
          variant: 'error',
          persist: true,
        });
        if (response?.status === 400) {
          if (response.data.errors) {
            const errorKeys = Object.keys(response.data.errors)
              .map<keyof StartHeatPreset | undefined>((key) => {
                switch (key) {
                  case 'Name':
                    return 'name';
                  case 'Material':
                    return 'material';
                  case 'spotSizeMicrometer':
                    return 'spotSizeMicrometer';
                  case 'beamPowerWatt':
                    return 'beamPowerWatt';
                  case 'gridOffsetPercent':
                    return 'gridOffsetPercent';
                  case 'gridSizeMillimeter':
                    return 'gridSizeMillimeter';
                  case 'dwellTimeAlgName':
                    return 'dwellTimeAlgName';
                  case 'dwellTimeSettings':
                    return 'dwellTimeSettings';
                  case 'pointSpreadAlgName':
                    return 'pointSpreadAlgName';
                  case 'pointSpreadSettings':
                    return 'pointSpreadSettings';
                  case 'seeds':
                    return 'seeds';
                  default:
                    return undefined;
                }
              })
              .filter((key): key is keyof StartHeatPreset => !!key);
            setValidationError(errorKeys);
            refs[errorKeys[0]]?.current?.focus();
          }
        }
      }
    },
  });

  const { data: currentUser } = useQuery<UserPreviewWithEtag>({
    queryKey: ['current-user'],
  });

  const userHasWritePermission = useMemo<boolean>(
    () => checkWritePermission(currentUser?.data, preset?.data.createdBy),
    [currentUser?.data, preset?.data.createdBy],
  );

  const resetForm = useCallback<() => void>(() => {
    if (preset) {
      setName(preset.data.name);
      setMaterial(preset.data.material);
      setValues({
        area: preset.data.area,
        targetTemperatureDegreeCelsius:
          preset.data.targetTemperatureDegreeCelsius,
        temperatureSensor: preset.data.temperatureSensor,
        pointSpreadAlgName: preset.data.pointSpreadAlgName,
        pointSpreadSettings: preset.data.pointSpreadSettings,
        dwellTimeAlgName: preset.data.dwellTimeAlgName,
        dwellTimeSettings: preset.data.dwellTimeSettings,
        gridSizeMillimeter: preset.data.gridSizeMillimeter,
        beamPowerWatt: preset.data.beamPowerWatt,
        gridOffsetPercent: preset.data.gridOffsetPercent,
        spotSizeMicrometer: preset.data.spotSizeMicrometer,
        seeds: preset.data.seeds,
      });
    }
  }, [preset]);

  const onCancelUpdate = useCallback<() => void>(() => {
    resetForm();
    setInUpdateState(false);
    setValidationError([]);
  }, [resetForm]);

  const onClose = useCallback<() => void>(() => {
    setInUpdateState(false);
    setValidationError([]);
    setValues(undefined);
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    setValidationError([]);
  }, [name, material, values]);

  if (isLoading) return <AppPreviewDrawer open onClose={onClose} isLoading />;

  if (preset)
    return (
      <>
        <AppPreviewDrawer
          open
          onClose={onClose}
          title={preset.data.name}
          actions={
            <Stack direction="row" spacing={2}>
              {inUpdateState ? (
                <>
                  <Button
                    onClick={onCancelUpdate}
                    variant="outlined"
                    color="inherit"
                    startIcon={<CancelIcon />}
                    disabled={isUpdating}
                    sx={{ flex: 1 }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    onClick={() => onUpdatePreset()}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    disabled={isUpdating}
                    sx={{ flex: 2 }}
                  >
                    {t('save')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setInUpdateState(true)}
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    disabled={!userHasWritePermission}
                    fullWidth
                  >
                    {t('update')}
                  </Button>
                  <Button
                    onClick={() => setDuplicateDialog(true)}
                    variant="outlined"
                    color="secondary"
                    startIcon={<DuplicateIcon />}
                    fullWidth
                  >
                    {t('duplicate')}
                  </Button>

                  <Button
                    onClick={() => setDeleteDialog(true)}
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    disabled={!userHasWritePermission}
                    fullWidth
                  >
                    {t('delete')}
                  </Button>
                </>
              )}
            </Stack>
          }
        >
          {inUpdateState ? (
            <ProjectMenuPanelGroup title={t('info')}>
              <Stack spacing={2}>
                <AppTextForm
                  ref={refs.name}
                  label={t('name')}
                  value={name}
                  onChange={setName}
                  helperText={t('preset_info_name_helper')}
                  error={validationError.includes('name')}
                  errorText={t('validations:update_preset.name')}
                />
                <AppTextForm
                  ref={refs.material}
                  label={t('material')}
                  value={material}
                  onChange={setMaterial}
                  helperText={t('preset_info_material_helper')}
                  error={validationError.includes('material')}
                  errorText={t('validations:update_preset.material')}
                />
              </Stack>
            </ProjectMenuPanelGroup>
          ) : (
            <Stack spacing={1}>
              <Typography variant="h5">{t('info')}</Typography>
              <Typography>
                {t('preview_material', { value: preset.data.material })}
              </Typography>
              <Typography>
                {t('preview_created', {
                  value: preset.data.createdBy
                    ? `${preset.data.createdBy.email} (${t('dateTime', {
                        value: new Date(preset.data.createdDate),
                      })})`
                    : t('dateTime', {
                        value: new Date(preset.data.createdDate),
                      }),
                })}
              </Typography>
              <Typography>
                {t('preview_updated', {
                  value: preset.data.updatedBy
                    ? `${preset.data.updatedBy.email} (${t('dateTime', {
                        value: new Date(preset.data.updatedDate),
                      })})`
                    : t('dateTime', {
                        value: new Date(preset.data.updatedDate),
                      }),
                })}
              </Typography>
            </Stack>
          )}
          <Stack spacing={2}>
            {!inUpdateState && (
              <Typography variant="h5">{t('settings')}</Typography>
            )}
            <StartHeatForm
              values={values ?? defaultStartHeat}
              onChangeValues={setValues}
              disabled={!inUpdateState}
              refs={refs}
              errors={validationError.filter(
                (key): key is keyof StartHeat =>
                  key !== 'name' && key !== 'material',
              )}
            />
          </Stack>
        </AppPreviewDrawer>
        <DuplicatePresetDialog
          open={duplicateDialog}
          onClose={() => setDuplicateDialog(false)}
          presetId={preset.data.id}
        />
        {userHasWritePermission && (
          <DeletePresetDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            processStep="startHeat"
            presetId={preset.data.id}
          />
        )}
      </>
    );

  return null;
};
