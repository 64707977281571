import axios from 'axios';
import buildQuery from 'odata-query';

import {
  JumpSafePresetRequestParams,
  FilteredJumpSafePresetResponses,
  ODataJumpSafePresetResponses,
  JumpSafePresetResponseWithEtag,
  JumpSafePresetResponse,
  JumpSafePreset,
  SpatterSafePresetRequestParams,
  FilteredSpatterSafePresetResponses,
  ODataSpatterSafePresetResponses,
  SpatterSafePresetResponseWithEtag,
  SpatterSafePresetResponse,
  SpatterSafePreset,
  MeltPresetRequestParams,
  FilteredMeltPresetResponses,
  ODataMeltPresetResponses,
  MeltPresetResponseWithEtag,
  MeltPresetResponse,
  MeltPreset,
  HeatBalancePresetRequestParams,
  FilteredHeatBalancePresetResponses,
  ODataHeatBalancePresetResponses,
  HeatBalancePresetResponseWithEtag,
  HeatBalancePresetResponse,
  HeatBalancePreset,
  StartHeatPreset,
  StartHeatPresetResponseWithEtag,
  StartHeatPresetResponse,
  StartHeatPresetRequestParams,
  FilteredStartHeatPresetResponses,
  ODataStartHeatPresetResponses,
} from 'api';

export const getStartHeatPresets = async (
  params?: StartHeatPresetRequestParams,
): Promise<FilteredStartHeatPresetResponses> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataStartHeatPresetResponses>(
    `startheatpresets${query}`,
  );
  return { count: data['@odata.count'], values: data.value };
};

export const getStartHeatPreset = async (
  id: string,
): Promise<StartHeatPresetResponseWithEtag> => {
  const { headers, data } = await axios.get<StartHeatPresetResponse>(
    `startheatpresets/${id}`,
  );
  return { etag: String(headers.etag), data };
};

export const createStartHeatPreset = async (
  body: StartHeatPreset,
): Promise<StartHeatPresetResponseWithEtag> => {
  const { headers, data } = await axios.post<StartHeatPresetResponse>(
    'startheatpresets',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const deleteStartHeatPreset = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`startheatpresets/${id}`, {
    headers: { 'If-Match': etag },
  });
};

export const updateStartHeatPreset = async (
  id: string,
  body: StartHeatPreset,
  etag: string,
): Promise<StartHeatPresetResponseWithEtag> => {
  const { headers, data } = await axios.put<StartHeatPresetResponse>(
    `startheatpresets/${id}`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};

export const getJumpSafePresets = async (
  params?: JumpSafePresetRequestParams,
): Promise<FilteredJumpSafePresetResponses> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataJumpSafePresetResponses>(
    `jumpsafepresets${query}`,
  );
  return { count: data['@odata.count'], values: data.value };
};

export const getJumpSafePreset = async (
  id: string,
): Promise<JumpSafePresetResponseWithEtag> => {
  const { headers, data } = await axios.get<JumpSafePresetResponse>(
    `jumpsafepresets/${id}`,
  );
  return { etag: String(headers.etag), data };
};

export const createJumpSafePreset = async (
  body: JumpSafePreset,
): Promise<JumpSafePresetResponseWithEtag> => {
  const { headers, data } = await axios.post<JumpSafePresetResponse>(
    'jumpsafepresets',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const deleteJumpSafePreset = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`jumpsafepresets/${id}`, {
    headers: { 'If-Match': etag },
  });
};

export const updateJumpSafePreset = async (
  id: string,
  body: JumpSafePreset,
  etag: string,
): Promise<JumpSafePresetResponseWithEtag> => {
  const { headers, data } = await axios.put<JumpSafePresetResponse>(
    `jumpsafepresets/${id}`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};

export const getSpatterSafePresets = async (
  params?: SpatterSafePresetRequestParams,
): Promise<FilteredSpatterSafePresetResponses> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataSpatterSafePresetResponses>(
    `spattersafepresets${query}`,
  );
  return { count: data['@odata.count'], values: data.value };
};

export const getSpatterSafePreset = async (
  id: string,
): Promise<SpatterSafePresetResponseWithEtag> => {
  const { headers, data } = await axios.get<SpatterSafePresetResponse>(
    `spattersafepresets/${id}`,
  );
  return { etag: String(headers.etag), data };
};

export const createSpatterSafePreset = async (
  body: SpatterSafePreset,
): Promise<SpatterSafePresetResponseWithEtag> => {
  const { headers, data } = await axios.post<SpatterSafePresetResponse>(
    'spattersafepresets',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const deleteSpatterSafePreset = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`spattersafepresets/${id}`, {
    headers: { 'If-Match': etag },
  });
};

export const updateSpatterSafePreset = async (
  id: string,
  body: SpatterSafePreset,
  etag: string,
): Promise<SpatterSafePresetResponseWithEtag> => {
  const { headers, data } = await axios.put<SpatterSafePresetResponse>(
    `spattersafepresets/${id}`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};

export const getMeltPresets = async (
  params?: MeltPresetRequestParams,
): Promise<FilteredMeltPresetResponses> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataMeltPresetResponses>(
    `meltpresets${query}`,
  );
  return { count: data['@odata.count'], values: data.value };
};

export const getMeltPreset = async (
  id: string,
): Promise<MeltPresetResponseWithEtag> => {
  const { headers, data } = await axios.get<MeltPresetResponse>(
    `meltpresets/${id}`,
  );
  return { etag: String(headers.etag), data };
};

export const createMeltPreset = async (
  body: MeltPreset,
): Promise<MeltPresetResponseWithEtag> => {
  const { headers, data } = await axios.post<MeltPresetResponse>(
    'meltpresets',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const deleteMeltPreset = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`meltpresets/${id}`, { headers: { 'If-Match': etag } });
};

export const updateMeltPreset = async (
  id: string,
  body: MeltPreset,
  etag: string,
): Promise<MeltPresetResponseWithEtag> => {
  const { headers, data } = await axios.put<MeltPresetResponse>(
    `meltpresets/${id}`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};

export const getHeatBalancePresets = async (
  params?: HeatBalancePresetRequestParams,
): Promise<FilteredHeatBalancePresetResponses> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataHeatBalancePresetResponses>(
    `heatbalancepresets${query}`,
  );
  return { count: data['@odata.count'], values: data.value };
};

export const getHeatBalancePreset = async (
  id: string,
): Promise<HeatBalancePresetResponseWithEtag> => {
  const { headers, data } = await axios.get<HeatBalancePresetResponse>(
    `heatbalancepresets/${id}`,
  );
  return { etag: String(headers.etag), data };
};

export const createHeatBalancePreset = async (
  body: HeatBalancePreset,
): Promise<HeatBalancePresetResponseWithEtag> => {
  const { headers, data } = await axios.post<HeatBalancePresetResponse>(
    'heatbalancepresets',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const deleteHeatBalancePreset = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`heatbalancepresets/${id}`, {
    headers: { 'If-Match': etag },
  });
};

export const updateHeatBalancePreset = async (
  id: string,
  body: HeatBalancePreset,
  etag: string,
): Promise<HeatBalancePresetResponseWithEtag> => {
  const { headers, data } = await axios.put<HeatBalancePresetResponse>(
    `heatbalancepresets/${id}`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};
