import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppNumberForm, AppSelectForm } from 'components/AppFormControl';
import { Area, AreaShape, Coordinates, isAreaShape } from 'api';
import { Stack } from '@mui/material';

type Props =
  | {
      value: Area;
      onChange: (v: Area) => void;
      nullable?: false;
      disabled?: boolean;
    }
  | {
      value: Area | null;
      onChange: (v: Area | null) => void;
      nullable: true;
      disabled?: boolean;
    };

const defaultValue: Area = {
  origin: { x: 0, y: 0 },
  shape: AreaShape.Circle,
  distanceMillimeter: 40,
  rotationDegree: 0,
};

export const ProjectExposureAreaForm: FC<Props> = ({
  value,
  onChange,
  nullable,
  disabled,
}) => {
  const { t } = useTranslation();

  const distanceMinAndMax = useMemo(():
    | { min: number; max: number }
    | undefined => {
    switch (value?.shape) {
      case AreaShape.Circle:
        return { min: 1, max: 50 };
      case AreaShape.Square:
        return { min: 1, max: 70 };
      default:
        return;
    }
  }, [value?.shape]);

  const options = useMemo(() => {
    const shapes = Object.keys(AreaShape).map((areaShape) => ({
      label: t(areaShape.toLocaleLowerCase()),
      value: areaShape,
    }));
    return nullable
      ? [{ label: t('melt_area'), value: 'melt_area' }, ...shapes]
      : shapes;
  }, [nullable, t]);

  const onChangeShape = useCallback(
    (shape: string | number) => {
      if (isAreaShape(shape)) onChange({ ...(value ?? defaultValue), shape });
      if (nullable && shape === 'melt_area') onChange(null);
    },
    [nullable, onChange, value],
  );

  const onChangeOrigin = useCallback(
    (k: keyof Coordinates, v: number) => {
      onChange({
        ...(value ?? defaultValue),
        origin: { ...(value?.origin ?? defaultValue.origin), [k]: v },
      });
    },
    [onChange, value],
  );

  const onChangeDistance = useCallback(
    (distanceMillimeter: number) => {
      onChange({ ...(value ?? defaultValue), distanceMillimeter });
    },
    [onChange, value],
  );

  const onChangeRotation = useCallback(
    (rotationDegree: number) => {
      onChange({ ...(value ?? defaultValue), rotationDegree });
    },
    [onChange, value],
  );

  return (
    <Stack spacing={2}>
      <AppSelectForm
        label={t('shape')}
        value={value?.shape ?? 'melt_area'}
        onChange={onChangeShape}
        options={options}
        disabled={disabled}
      />
      {value !== null && (
        <>
          <Stack spacing={2} direction="row">
            <AppNumberForm
              label={t('x')}
              helperText={t('area_origin_x_helper')}
              value={value.origin.x}
              onChange={(v) => onChangeOrigin('x', v)}
              unit={t('unit:millimeter')}
              disabled={disabled}
            />
            <AppNumberForm
              label={t('y')}
              helperText={t('area_origin_y_helper')}
              value={value.origin.y}
              onChange={(v) => onChangeOrigin('y', v)}
              unit={t('unit:millimeter')}
              disabled={disabled}
            />
          </Stack>
          <AppNumberForm
            label={t(`area_${value.shape.toLocaleLowerCase()}_distance`)}
            helperText={t(
              `area_${value.shape.toLocaleLowerCase()}_distance_helper`,
            )}
            errorText={t(
              `validations:process_step_parameters.area_${value.shape.toLocaleLowerCase()}_distance`,
            )}
            value={value.distanceMillimeter}
            onChange={onChangeDistance}
            unit={t('unit:millimeter')}
            {...distanceMinAndMax}
            disabled={disabled}
          />
          {value.shape !== AreaShape.Circle && (
            <AppNumberForm
              label={t('area_rotation')}
              helperText={t('area_rotation_helper')}
              value={value.rotationDegree}
              onChange={onChangeRotation}
              unit={t('unit:degree')}
              disabled={disabled}
            />
          )}
        </>
      )}
    </Stack>
  );
};
