import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Button, Stack } from '@mui/material';
import {
  PersonAddRounded as AddUserIcon,
  EditRounded as EditIcon,
  DeleteRounded as DeleteIcon,
} from '@mui/icons-material';

import {
  UpdateOrganizationDialog,
  DeleteOrganizationDialog,
  AddUserToOrganizationDialog,
} from 'pages/Organizations';
import { AppPreviewDrawer } from 'components/AppPreviewDrawer';
import { getOrganizationPreview } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

export const AppOrganizationPreview: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [{ rowId: organizationId }, { deleteSearchParam }] = useSearchParams();
  const [addUserDialog, setAddUserDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const onClose = useCallback<() => void>(() => {
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  const {
    data: preview,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['organization', { organizationId }],
    queryFn: () => getOrganizationPreview(organizationId!),
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        key: `get_organization_preview_fail_${Date.now()}`,
        message: t('get_organization_preview_fail'),
        variant: 'error',
      });
      onClose();
    }
  }, [enqueueSnackbar, isError, onClose, t]);

  if (isLoading) return <AppPreviewDrawer open onClose={onClose} isLoading />;

  if (preview)
    return (
      <>
        <AppPreviewDrawer
          open
          onClose={onClose}
          title={preview.data.name}
          actions={
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => setUpdateDialog(true)}
                variant="outlined"
                color="inherit"
                startIcon={<EditIcon />}
                fullWidth
              >
                {t('update')}
              </Button>
              <Button
                onClick={() => setDeleteDialog(true)}
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                fullWidth
              >
                {t('delete')}
              </Button>
            </Stack>
          }
        >
          <Button
            onClick={() => setAddUserDialog(true)}
            variant="contained"
            color="primary"
            startIcon={<AddUserIcon />}
            fullWidth
          >
            {t('add_user_to_organization')}
          </Button>
        </AppPreviewDrawer>
        <UpdateOrganizationDialog
          organization={updateDialog ? preview : undefined}
          onClose={() => setUpdateDialog(false)}
        />
        <DeleteOrganizationDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          organizationId={preview.data.id}
        />
        <AddUserToOrganizationDialog
          open={addUserDialog}
          onClose={() => setAddUserDialog(false)}
          organizationId={preview.data.id}
        />
      </>
    );

  return null;
};
