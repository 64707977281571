/**
 * @summary Function to return number of decimal digits of a number
 * @param {number}  n number with decimals
 * @returns {number}
 */
const countDecimals = (n: number): number =>
  Math.floor(n.valueOf()) === n.valueOf()
    ? 0
    : n.toString().split('.')[1].length || 0;

/**
 * @summary Function to return correct addition value of two float numbers
 * @param {number}  n1  first number
 * @param {number}  n2  second number
 * @returns {number}
 */
export const add = (n1: number, n2: number): number =>
  Number((n1 + n2).toFixed(Math.max(countDecimals(n1), countDecimals(n2))));

/**
 * @summary Function to return correct subtraction value of two float numbers
 * @param {number}  n1  first number
 * @param {number}  n2  second number
 * @returns {number}
 */
export const subtract = (n1: number, n2: number): number =>
  Number((n1 - n2).toFixed(Math.max(countDecimals(n1), countDecimals(n2))));

/**
 * @summary Function to return correct multiplication value of two float numbers
 * @param {number}  n1  first number
 * @param {number}  n2  second number
 * @returns {number}
 */
export const multiply = (n1: number, n2: number): number =>
  Number((n1 * n2).toFixed(Math.max(countDecimals(n1), countDecimals(n2))));
