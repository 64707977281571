import { ReactNode, useMemo } from 'react';
import { AlertColor } from '@mui/material';
import {
  useSnackbar as useNotistack,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack';

import { AppSnackbar } from 'components/AppSnackbar';

interface ProviderContext {
  enqueueSnackbar: (args: {
    key: SnackbarKey;
    message: SnackbarMessage;
    variant: AlertColor;
    persist?: boolean;
    action?: ReactNode;
  }) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

export const useSnackbar = (): ProviderContext => {
  const { enqueueSnackbar, closeSnackbar } = useNotistack();

  return useMemo<ProviderContext>(
    () => ({
      enqueueSnackbar: ({ key, message, variant, persist, action }) =>
        enqueueSnackbar(message, {
          key,
          variant,
          persist,
          content: (_key, message) => (
            <AppSnackbar
              snackbarKey={key}
              message={message}
              severity={variant}
              action={action}
            />
          ),
        }),
      closeSnackbar: closeSnackbar,
    }),
    [closeSnackbar, enqueueSnackbar],
  );
};
