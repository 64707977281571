import { FC, RefObject, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';

import {
  AppNumberForm,
  AppSelectForm,
  AppNumberListForm,
} from 'components/AppFormControl';
import { ProjectAlgorithmSetting } from 'components/ProjectAlgorithmSetting';
import { ProjectMenuPanelGroup } from 'components/ProjectMenuPanelGroup';
import { ProjectExposureAreaForm } from 'components/ProjectExposureAreaForm';
import {
  Algorithm,
  AlgorithmSetting,
  getAlgorithms,
  isTemperatureSensor,
  StartHeat,
  TemperatureSensor,
} from 'api';
import { useSnackbar } from 'hooks';

const getAlgorithmSettingsProps = (
  algorithms: Algorithm[],
  algorithmName: string,
): AlgorithmSetting[] =>
  algorithms.find(({ name }) => name === algorithmName)?.algorithmSettings ??
  [];

interface Props {
  values: StartHeat;
  disabled?: boolean;
  refs?: Record<keyof StartHeat, RefObject<HTMLInputElement>>;
  errors?: (keyof StartHeat)[];
  onChangeValues?: (newValues: StartHeat) => void;
}

export const StartHeatForm: FC<Props> = ({
  values,
  disabled,
  refs,
  errors,
  onChangeValues,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const temperatureSensorOptions = useMemo(
    () =>
      Object.keys(TemperatureSensor).map((value) => ({
        label: t(value),
        value,
      })),
    [t],
  );

  const {
    data: algorithms = { dwellTimeAlgorithms: [], pointSpreadAlgorithms: [] },
    isLoading: isAlgorithmsLoading,
    isError: isAlgorithmsError,
  } = useQuery({
    queryKey: ['algorithms'],
    queryFn: () => getAlgorithms(),
  });

  useEffect(() => {
    if (isAlgorithmsError) {
      enqueueSnackbar({
        key: `algorithms_not_found_${Date.now()}`,
        message: t('algorithms_not_found'),
        variant: 'error',
        persist: true,
      });
      navigate('/projects');
    }
  }, [enqueueSnackbar, isAlgorithmsError, navigate, t]);

  const pointSpreadSettingsProps = useMemo(
    (): AlgorithmSetting[] =>
      getAlgorithmSettingsProps(
        algorithms.pointSpreadAlgorithms,
        values.pointSpreadAlgName,
      ),
    [algorithms.pointSpreadAlgorithms, values.pointSpreadAlgName],
  );

  const dwellTimeSettingsProps = useMemo(
    (): AlgorithmSetting[] =>
      getAlgorithmSettingsProps(
        algorithms.dwellTimeAlgorithms,
        values.dwellTimeAlgName,
      ),
    [algorithms.dwellTimeAlgorithms, values.dwellTimeAlgName],
  );

  const handleChangeValue = useCallback(
    <T extends keyof StartHeat>(key: T, value: StartHeat[T]): void => {
      if (onChangeValues) {
        const newValue: Partial<StartHeat> = { [key]: value };
        switch (key) {
          case 'pointSpreadAlgName':
            newValue.pointSpreadSettings = getAlgorithmSettingsProps(
              algorithms.pointSpreadAlgorithms,
              String(value),
            ).map(({ defaultValue }) => defaultValue ?? '');
            break;
          case 'dwellTimeAlgName':
            newValue.dwellTimeSettings = getAlgorithmSettingsProps(
              algorithms.dwellTimeAlgorithms,
              String(value),
            ).map(({ defaultValue }) => defaultValue ?? '');
            break;
        }
        onChangeValues({ ...values, ...newValue });
      }
    },
    [algorithms, onChangeValues, values],
  );

  return (
    <Stack spacing={2} overflow="hidden">
      <ProjectMenuPanelGroup title={t('general')}>
        <AppNumberForm
          label={t('target_temperature')}
          helperText={t('target_temperature_helper')}
          error={errors?.includes('targetTemperatureDegreeCelsius')}
          errorText={t(
            'validations:process_step_parameters.targetTemperatureDegreeCelsius',
          )}
          value={values.targetTemperatureDegreeCelsius}
          onChange={(value) => {
            handleChangeValue('targetTemperatureDegreeCelsius', value);
          }}
          unit={t('unit:degree_celsius')}
          min={0}
          max={1400}
          componentProps={{
            disabled,
            ref: refs?.targetTemperatureDegreeCelsius,
          }}
        />
        <AppSelectForm
          label={t('temperature_sensor')}
          helperText={t('temperature_sensor_helper')}
          value={values.temperatureSensor}
          onChange={(value) => {
            if (isTemperatureSensor(value))
              handleChangeValue('temperatureSensor', value);
          }}
          options={temperatureSensorOptions}
          disabled={disabled}
        />
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup title={t('exposure_area')}>
        <ProjectExposureAreaForm
          value={values.area}
          onChange={(v) => handleChangeValue('area', v)}
          disabled={disabled}
        />
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup title={t('beam')}>
        <AppNumberForm
          label={t('spot_size')}
          helperText={t('spot_size_helper')}
          error={errors?.includes('spotSizeMicrometer')}
          errorText={t(
            'validations:process_step_parameters.spotSizeMicrometer',
          )}
          value={values.spotSizeMicrometer}
          onChange={(value) => {
            handleChangeValue('spotSizeMicrometer', value);
          }}
          unit={t('unit:micrometer')}
          min={200}
          max={2000}
          componentProps={{ disabled, ref: refs?.spotSizeMicrometer }}
        />
        <AppNumberForm
          label={t('beam_power')}
          helperText={t('beam_power_helper')}
          error={errors?.includes('beamPowerWatt')}
          errorText={t('validations:process_step_parameters.beamPowerWatt')}
          value={values.beamPowerWatt}
          onChange={(value) => {
            handleChangeValue('beamPowerWatt', value);
          }}
          unit={t('unit:watt')}
          min={0}
          max={6000}
          componentProps={{ disabled, inputRef: refs?.beamPowerWatt }}
        />
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup title={t('grid')}>
        <AppNumberForm
          label={t('grid_size')}
          helperText={t('grid_size_helper')}
          error={errors?.includes('gridSizeMillimeter')}
          errorText={t(
            'validations:process_step_parameters.gridSizeMillimeter',
          )}
          value={values.gridSizeMillimeter}
          onChange={(value) => {
            handleChangeValue('gridSizeMillimeter', value);
          }}
          min={0.0001}
          max={1}
          step={0.0001}
          unit={t('unit:millimeter')}
          componentProps={{ disabled, inputRef: refs?.gridSizeMillimeter }}
        />
        <AppNumberForm
          label={t('grid_offset')}
          helperText={t('grid_offset_helper')}
          error={errors?.includes('gridOffsetPercent')}
          errorText={t('validations:process_step_parameters.gridOffsetPercent')}
          value={values.gridOffsetPercent}
          onChange={(value) => {
            handleChangeValue('gridOffsetPercent', value);
          }}
          min={0}
          max={100}
          step={1}
          unit={t('unit:percent')}
          componentProps={{ disabled }}
        />
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup
        title={t('spot_spread')}
        loading={isAlgorithmsLoading}
      >
        <AppSelectForm
          label={t('method')}
          helperText={
            values.pointSpreadAlgName &&
            t(`algorithms:pointSpread.${values.pointSpreadAlgName}_helper`)
          }
          error={errors?.includes('pointSpreadAlgName')}
          errorText={t(
            'validations:process_step_parameters.pointSpreadAlgName',
          )}
          value={values.pointSpreadAlgName}
          onChange={(value) => {
            handleChangeValue(
              'pointSpreadAlgName',
              value as StartHeat['pointSpreadAlgName'],
            );
          }}
          options={
            algorithms.pointSpreadAlgorithms
              .map(({ name }) =>
                name
                  ? {
                      value: name,
                      label: t(`algorithms:pointSpread.${name}`),
                      tooltip: t(`algorithms:pointSpread.${name}_helper`),
                    }
                  : undefined,
              )
              .filter((value) => value) as {
              value: string;
              label: string;
            }[]
          }
          componentProps={{ disabled, inputRef: refs?.pointSpreadAlgName }}
        />
        {pointSpreadSettingsProps.map((props, index) => (
          <ProjectAlgorithmSetting
            key={`${values.pointSpreadAlgName}.${props.settingsName}`}
            {...props}
            value={values.pointSpreadSettings.at(index)}
            setValue={(v: string) => {
              handleChangeValue(
                'pointSpreadSettings',
                pointSpreadSettingsProps.map(({ defaultValue }, i) =>
                  index === i
                    ? v
                    : values.pointSpreadSettings[i] || (defaultValue ?? ''),
                ),
              );
            }}
            method="pointSpread"
            error={errors?.includes('pointSpreadSettings')}
            disabled={disabled}
          />
        ))}
        <AppNumberListForm
          label={t('seeds')}
          helperText={t('seeds_helper')}
          error={errors?.includes('seeds')}
          errorText={t('validations:process_step_parameters.seeds')}
          value={values.seeds}
          onChange={(value) => handleChangeValue('seeds', value)}
          componentProps={{
            disabled,
            inputRef: refs?.seeds,
            placeholder: '1, 2, 3',
          }}
        />
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup
        title={t('dwell_time')}
        loading={isAlgorithmsLoading}
      >
        <AppSelectForm
          label={t('method')}
          helperText={
            values.dwellTimeAlgName &&
            t(`algorithms:dwellTime.${values.dwellTimeAlgName}_helper`)
          }
          error={errors?.includes('dwellTimeAlgName')}
          errorText={t('validations:process_step_parameters.dwellTimeAlgName')}
          value={values.dwellTimeAlgName}
          onChange={(value) => {
            handleChangeValue(
              'dwellTimeAlgName',
              value as StartHeat['dwellTimeAlgName'],
            );
          }}
          options={
            algorithms.dwellTimeAlgorithms
              .map(({ name }) =>
                name
                  ? {
                      value: name,
                      label: t(`algorithms:dwellTime.${name}`),
                      tooltip: t(`algorithms:dwellTime.${name}_helper`),
                    }
                  : undefined,
              )
              .filter((value) => value) as {
              value: string;
              label: string;
            }[]
          }
          componentProps={{ disabled, inputRef: refs?.dwellTimeAlgName }}
        />
        {dwellTimeSettingsProps.map((props, index) => (
          <ProjectAlgorithmSetting
            key={`${values.dwellTimeAlgName}.${props.settingsName}`}
            {...props}
            value={values.dwellTimeSettings.at(index)}
            setValue={(v: string) => {
              handleChangeValue(
                'dwellTimeSettings',
                dwellTimeSettingsProps.map(({ defaultValue }, i) =>
                  index === i
                    ? v
                    : values.dwellTimeSettings[i] || (defaultValue ?? ''),
                ),
              );
            }}
            method="dwellTime"
            error={errors?.includes('dwellTimeSettings')}
            disabled={disabled}
          />
        ))}
      </ProjectMenuPanelGroup>
    </Stack>
  );
};
