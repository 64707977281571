import { QueryOptions } from 'odata-query';

import { User } from 'api';

export type SuccessResponse<T> = Readonly<T> & {
  readonly createdDate: string;
  readonly createdBy: User;
  readonly updatedDate: string;
  readonly updatedBy: User;
};

export interface ResponseWithEtag<T> {
  readonly etag: string;
  readonly data: T;
}

export type ODataRequestParams<T> = Partial<QueryOptions<T> & { count: true }>;

export interface ODataResponse<T> {
  readonly '@odata.context': string;
  readonly '@odata.count': number;
  readonly value: T[];
}

export interface FilteredResponse<T> {
  readonly count: number;
  readonly values: T[];
}

/**
 * @example {
    type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
    title: 'One or more validation errors occurred.',
    status: 400,
    traceId: '00-120aaf2fac463819e1910200437b174e-56cb4dd52f24d210-00',
    errors: { Name: ['The Name field is required.'] },
  }
 */
export interface ErrorResponse {
  readonly type?: string;
  readonly title?: string;
  readonly status?: number;
  readonly traceId?: string;
  readonly errors?: Record<string, string[]>;
}

export interface Coordinates {
  x: number;
  y: number;
}

export enum AreaShape {
  Circle = 'Circle',
  Square = 'Square',
}

export interface Area {
  origin: Coordinates;
  shape: AreaShape;
  distanceMillimeter: number;
  rotationDegree: number;
}
