/**
 * Move an item inside an array to a new position
 * @param array The array you want to reorder
 * @param value The value inside the array you want to move
 * @param steps The number of steps to move the value
 * @returns The reordered array
 */
export const moveArray = <T>(array: T[], value: T, steps: number): T[] => {
  const index = array.indexOf(value);
  if (index >= 0 && index + steps >= 0 && index + steps < array.length)
    return array.toSpliced(index, 1).toSpliced(index + steps, 0, value);
  return array;
};
