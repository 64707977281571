import { FC } from 'react';
import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  Navigate,
} from 'react-router-dom';

import { Login } from 'pages/Login';
import { VerifyAccount } from 'pages/VerifyAccount';
import { SetPassword } from 'pages/SetPassword';
import { ForgotPassword } from 'pages/ForgotPassword';

export const Public: FC = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/" element={<Login />} />
        <Route path="/verify-account" element={<VerifyAccount />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};
