import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  Stack,
  Button,
  Theme,
  useMediaQuery,
  Typography,
} from '@mui/material';

import { ReactComponent as FreemeltPixelmeltLogo } from 'assets/logo/freemelt-pixelmelt.svg';
import { AppTextForm } from 'components/AppFormControl';
import { useAuthentication, useSnackbar } from 'hooks';
import { validateEmail } from 'utils';

type VerifyAccountKeys = 'verificationCode' | 'email';

export const VerifyAccount: FC = () => {
  const { t } = useTranslation();
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'), {
    noSsr: true,
  });
  const navigate = useNavigate();
  const { confirmSignUp } = useAuthentication();
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const returnToLoginPage = useCallback<() => void>(
    () => navigate(`/?email=${email}`),
    [email, navigate],
  );

  const [validationError, setValidationError] = useState<VerifyAccountKeys[]>(
    [],
  );
  const refs = useMemo<Record<VerifyAccountKeys, RefObject<HTMLInputElement>>>(
    () => ({
      verificationCode: createRef<HTMLInputElement>(),
      email: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const handleConfirm = useCallback<() => void>(() => {
    if (!verificationCode) {
      setValidationError(['verificationCode']);
      refs.verificationCode.current?.focus();
    } else if (!validateEmail(email)) {
      setValidationError(['email']);
      refs.email.current?.focus();
    } else {
      setIsVerifying(true);
      confirmSignUp({ email, code: verificationCode })
        .then(() => {
          closeSnackbar('confirm_user_fail');
          enqueueSnackbar({
            key: 'confirm_user_success',
            message: t('confirm_user_success'),
            variant: 'success',
            persist: true,
          });
          navigate(`/set-password?email=${email}`);
        })
        .catch(() => {
          enqueueSnackbar({
            key: 'confirm_user_fail',
            message: t('confirm_user_fail'),
            variant: 'error',
            persist: true,
          });
        })
        .finally(() => {
          setIsVerifying(false);
        });
    }
  }, [
    closeSnackbar,
    confirmSignUp,
    email,
    enqueueSnackbar,
    navigate,
    refs,
    t,
    verificationCode,
  ]);

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={xs}>
      <Stack
        height={1}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        py={10}
      >
        <Stack width={0.6} spacing={2}>
          <Typography variant="h5">
            {t('verify_account_dialog_title')}
          </Typography>
          <Typography sx={{ pb: 4 }}>
            {t('verify_account_dialog_description')}
          </Typography>
          <AppTextForm
            label={t('email')}
            helperText={t('verify_account_email_helper')}
            value={email}
            onChange={setEmail}
            componentProps={{
              type: 'email',
              inputRef: refs.email,
            }}
            error={validationError.includes('email')}
            errorText={t('validations:verify_account.email')}
          />
          <AppTextForm
            label={t('verification_code')}
            helperText={t('verification_code_helper')}
            value={verificationCode}
            onChange={setVerificationCode}
            componentProps={{ sx: { mb: 4 }, inputRef: refs.verificationCode }}
            error={validationError.includes('verificationCode')}
            errorText={t('validations:verify_account.verificationCode')}
          />
          <Stack direction="row" spacing={2}>
            <Button
              onClick={returnToLoginPage}
              variant="outlined"
              color="inherit"
              sx={{ flex: 1 }}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              disabled={isVerifying}
              sx={{ flex: 2 }}
            >
              {t('verify_account')}
            </Button>
          </Stack>
        </Stack>
        <FreemeltPixelmeltLogo style={{ width: '50%', alignSelf: 'center' }} />
      </Stack>
    </Dialog>
  );
};
