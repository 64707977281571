import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Paper } from '@mui/material';

import { ProjectPanel } from 'components/ProjectPanel';
import { ProjectTreeView } from 'components/ProjectTreeView';
import { ProjectPartitionForm } from 'components/ProjectPartitionForm';
import { ModelPreview, ProjectSettingsResponseWithEtag } from 'api';
import { useSelector, useKeyboardShortcut, useProcessStepActions } from 'hooks';
import { selectPartitionSelection, selectPartitions } from 'utils';
import { selectProjectSettings } from 'slices/projectSettingsSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const SelectionPanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const { setSelectedPartitions } = useProcessStepActions();
  const partitionSelection = useSelector(selectPartitionSelection);
  const partitions = useSelector(selectPartitions);
  const { objectExposureOrder } = useSelector(selectProjectSettings);

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>({
    queryKey: ['project-settings', { projectId }],
  });

  const model = useMemo<ModelPreview | undefined>(
    () => projectSettings?.data.model,
    [projectSettings?.data.model],
  );

  useKeyboardShortcut({ key: 'a', ctrl: true }, () => {
    setSelectedPartitions({ target: undefined });
  });

  return (
    <ProjectPanel
      title={t('selection')}
      scale={3}
      options={
        <ProjectPartitionForm
          modelInfo={model?.sliceInfo}
          modelName={model?.name}
          disabled={!userHasWritePermission || generateHasInitiated}
        />
      }
    >
      <Paper sx={{ height: 1, overflow: 'hidden auto' }}>
        <ProjectTreeView
          modelInfo={model?.sliceInfo}
          modelName={model?.name}
          partitions={partitions}
          selection={partitionSelection}
          objectExposureOrder={objectExposureOrder}
          selectable
        />
      </Paper>
    </ProjectPanel>
  );
};
