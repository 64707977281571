import { CreateProject, ProjectSettings, UpdateProject } from 'api';

export const projectSettingsRequirements = {
  layerFeed: {
    buildPistonDistanceMillimeter: { min: 0 },
    powderPistonDistanceMillimeter: { min: 0 },
    recoaterSpeedMillimeterPerSecond: { min: 14 },
  },
};

// TODO: Add project validations
export const isValidCreateProject = (value: CreateProject) => true;
export const isValidUpdateProject = (value: UpdateProject) => true;
export const isValidProjectSettings = (value: ProjectSettings) => true;
