import { FC, useEffect, useState } from 'react';

import { Public } from 'layouts/Public';
import { Private } from 'layouts/Private';
import { AppTransition } from 'components/AppTransition';
import { useAuthentication, useSelector } from 'hooks';
import { selectUser } from 'slices/userSlice';

export const App: FC = () => {
  const { getSession } = useAuthentication();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSession().then(
      () => {
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  }, [getSession]);

  return isLoading ? <AppTransition /> : user ? <Private /> : <Public />;
};
