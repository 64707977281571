import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'utils';

export enum View {
  ModelPreview,
  BuildPreview,
}
export enum PrimaryMenuTab {
  project = 'project',
  start_heat = 'start_heat',
  jump_safe = 'jump_safe',
  spatter_safe = 'spatter_safe',
  melt = 'melt',
  heat_balance = 'heat_balance',
}
export interface PrimaryMenu {
  window: PrimaryMenuTab;
  open: boolean;
}
export enum SecondaryMenuTab {
  selection = 'selection',
  energy = 'energy',
}
export interface SecondaryMenu {
  windows: SecondaryMenuTab[];
  open: boolean;
}

export interface ModelHighlight {
  objectId: number | null;
  startZMillimeter: number;
  endZMillimeter: number;
}
export interface ModelPreviewSettings {
  axesHelper: boolean;
  buildTank: boolean;
  highlightSelected: boolean;
  highlightUnsliced: boolean;
  startPlate: boolean;
}
export interface BuildPreviewSettings {
  drawerExtended: boolean;
  gridLines: boolean;
}

export interface EditorState {
  view: View;
  primaryMenu: PrimaryMenu;
  secondaryMenu: SecondaryMenu;
  modelHighlights: ModelHighlight[];
  modelPreviewSettings: ModelPreviewSettings;
  buildPreviewSettings: BuildPreviewSettings;
}

const initialState: EditorState = {
  view: View.ModelPreview,
  primaryMenu: { window: PrimaryMenuTab.project, open: true },
  secondaryMenu: {
    windows: [SecondaryMenuTab.selection, SecondaryMenuTab.energy],
    open: true,
  },
  modelHighlights: [],
  modelPreviewSettings: {
    axesHelper: false,
    buildTank: false,
    highlightSelected: true,
    highlightUnsliced: false,
    startPlate: true,
  },
  buildPreviewSettings: {
    drawerExtended: true,
    gridLines: false,
  },
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    resetEditor: (state) => {
      state.view = initialState.view;
      state.primaryMenu = initialState.primaryMenu;
      state.secondaryMenu = initialState.secondaryMenu;
      state.modelHighlights = initialState.modelHighlights;
      state.modelPreviewSettings = initialState.modelPreviewSettings;
      state.buildPreviewSettings = initialState.buildPreviewSettings;
    },
    setView: (state, { payload }: PayloadAction<{ view: View }>) => {
      state.view = payload.view;
    },
    setPrimaryMenu: (
      state,
      { payload }: PayloadAction<{ open?: boolean; tab?: PrimaryMenuTab }>,
    ) => {
      if (payload.open !== undefined) state.primaryMenu.open = payload.open;
      if (payload.tab !== undefined) {
        if (state.primaryMenu.window === payload.tab)
          state.primaryMenu.open = !state.primaryMenu.open;
        else state.primaryMenu = { window: payload.tab, open: true };
      }
    },
    setSecondaryMenu: (
      state,
      { payload }: { payload: { open?: boolean; tab?: SecondaryMenuTab } },
    ) => {
      if (payload.open !== undefined) {
        state.secondaryMenu.open = payload.open;
      }
      if (payload.tab !== undefined) {
        if (state.secondaryMenu.windows.includes(payload.tab)) {
          if (state.secondaryMenu.open)
            state.secondaryMenu.windows = state.secondaryMenu.windows.filter(
              (tab) => tab !== payload.tab,
            );
          else state.secondaryMenu = { windows: [payload.tab], open: true };
        } else
          state.secondaryMenu = {
            windows: [...state.secondaryMenu.windows, payload.tab],
            open: true,
          };
      }
    },
    setModelHighlights: (
      state,
      { payload }: PayloadAction<ModelHighlight[]>,
    ) => {
      state.modelHighlights = payload;
    },
    setModelPreviewSetting: (
      state,
      {
        payload,
      }: PayloadAction<{ key: keyof ModelPreviewSettings; value: boolean }>,
    ) => {
      state.modelPreviewSettings[payload.key] = payload.value;
    },
    setBuildPreviewSetting: (
      state,
      {
        payload,
      }: PayloadAction<{ key: keyof BuildPreviewSettings; value: boolean }>,
    ) => {
      state.buildPreviewSettings[payload.key] = payload.value;
    },
  },
});

export const {
  resetEditor,
  setView,
  setPrimaryMenu,
  setSecondaryMenu,
  setModelHighlights,
  setModelPreviewSetting,
  setBuildPreviewSetting,
} = editorSlice.actions;

export const selectView = (state: RootState): View => state.editor.view;
export const selectPrimaryMenu = (state: RootState): PrimaryMenu =>
  state.editor.primaryMenu;
export const selectSecondaryMenu = (state: RootState): SecondaryMenu =>
  state.editor.secondaryMenu;
export const selectModelHighlights = (state: RootState): ModelHighlight[] =>
  state.editor.modelHighlights;
export const selectModelPreviewSettings = (
  state: RootState,
): ModelPreviewSettings => state.editor.modelPreviewSettings;
export const selectBuildPreviewSettings = (
  state: RootState,
): BuildPreviewSettings => state.editor.buildPreviewSettings;

export default editorSlice.reducer;
