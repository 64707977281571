import { FC } from 'react';
import { Cylinder } from '@react-three/drei';
import { Color } from 'three';

import { useMaterial } from 'hooks';

export const ProjectStartPlate: FC = () => {
  const material = useMaterial({ color: new Color(0.01, 0.01, 0.01) });

  return (
    <Cylinder
      args={[50, 50, 10, 100]}
      position={[0, 0, -5]}
      rotation={[Math.PI / 2, 0, 0]}
      material={material}
    />
  );
};
