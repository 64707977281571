import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState, PartitionQuery, extractPartitionIds } from 'utils';
import {
  SpatterSafe,
  Partition,
  PartitionSettings,
  Selection,
} from 'api/types';

export const defaultSpatterSafe: SpatterSafe = {
  repetitions: 10,
  area: null,
  spotSizeMicrometer: 2000,
  beamPowerWatt: 600,
  gridSizeMillimeter: 0.1,
  gridOffsetPercent: 0,
  seeds: [],
  pointSpreadAlgName: '',
  dwellTimeAlgName: '',
  pointSpreadSettings: [],
  dwellTimeSettings: [],
};

export const initialState: PartitionSettings<SpatterSafe> = {
  selection: Selection.model,
  partitions: [],
  selectedPartitionIds: [],
};

const spatterSafeSlice = createSlice({
  name: 'spatterSafe',
  initialState,
  reducers: {
    loadSpatterSafe: (
      state,
      { payload }: PayloadAction<PartitionSettings<SpatterSafe>>,
    ) => {
      state.selection = payload.selection;
      state.partitions = payload.partitions;
      state.selectedPartitionIds = payload.selectedPartitionIds;
    },
    resetSpatterSafePartitions: (state) => {
      state.partitions = initialState.partitions;
    },
    setSpatterSafePartitionSelection: (
      state,
      { payload }: PayloadAction<Selection>,
    ) => {
      state.selection = payload;
    },
    setSpatterSafePartitions: (
      state,
      { payload }: PayloadAction<Partition<SpatterSafe>[]>,
    ) => {
      state.partitions = payload;
      state.selectedPartitionIds = [];
    },
    setSelectedSpatterSafePartitionIds: (
      state,
      { payload }: PayloadAction<PartitionQuery>,
    ) => {
      state.selectedPartitionIds = extractPartitionIds({
        query: payload,
        partitions: state.partitions,
        selectedPartitionIds: state.selectedPartitionIds,
      });
    },
    setDraft: (state, { payload }: PayloadAction<SpatterSafe>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: payload,
              },
            }
          : partition,
      );
    },
    saveDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
                saved: partition.parameters.draft,
              },
            }
          : partition,
      );
    },
    discardDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
              },
            }
          : partition,
      );
    },
    setSkipSpatterSafe: (state, { payload }: PayloadAction<boolean>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: { ...partition.parameters, skip: payload },
            }
          : partition,
      );
    },
  },
});

export const {
  loadSpatterSafe,
  resetSpatterSafePartitions,
  setSpatterSafePartitionSelection,
  setSpatterSafePartitions,
  setSelectedSpatterSafePartitionIds,
  setDraft,
  saveDraft,
  discardDraft,
  setSkipSpatterSafe,
} = spatterSafeSlice.actions;

export const selectSpatterSafePartitionSelection = (
  state: RootState,
): Selection => state.spatterSafe.selection;
export const selectSpatterSafePartitions = (
  state: RootState,
): Partition<SpatterSafe>[] => state.spatterSafe.partitions;
export const selectSelectedSpatterSafePartitionIds = (
  state: RootState,
): string[] => state.spatterSafe.selectedPartitionIds;

export const selectSelectedSpatterSafePartitions = createSelector(
  selectSpatterSafePartitions,
  selectSelectedSpatterSafePartitionIds,
  (partitions, selectedPartitionIds): Partition<SpatterSafe>[] =>
    partitions.filter(({ id }) => selectedPartitionIds.includes(id)),
);
export const selectAllSpatterSafePartitionsAreSaved = createSelector(
  selectSpatterSafePartitions,
  (partitions): boolean =>
    partitions.every(({ parameters }) => !!parameters.saved || parameters.skip),
);
export const selectSkipSpatterSafe = createSelector(
  selectSelectedSpatterSafePartitions,
  (selectedPartitions): Record<string, boolean> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.skip]),
    ),
);
export const selectSpatterSafeDraftValues = createSelector(
  selectSelectedSpatterSafePartitions,
  (selectedPartitions): Record<string, SpatterSafe | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.draft]),
    ),
);
export const selectSpatterSafeSavedValues = createSelector(
  selectSelectedSpatterSafePartitions,
  (selectedPartitions): Record<string, SpatterSafe | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.saved]),
    ),
);

export default spatterSafeSlice.reducer;
