import axios from 'axios';
import buildQuery from 'odata-query';

import {
  CreateOrganization,
  FilteredOrganizations,
  ODataOrganizations,
  Organization,
  OrganizationRequestParams,
  OrganizationWithEtag,
  UpdateOrganization,
} from 'api';

export const getOrganizations = async (
  params?: OrganizationRequestParams,
): Promise<FilteredOrganizations> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataOrganizations>(`organizations${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getOrganizationPreview = async (
  id: string,
): Promise<OrganizationWithEtag> => {
  const { headers, data } = await axios.get<Organization>(
    `organizations/${id}`,
  );
  return { etag: String(headers.etag), data };
};

export const createOrganization = async (
  body: CreateOrganization,
): Promise<OrganizationWithEtag> => {
  const { headers, data } = await axios.post<Organization>(
    'organizations',
    body,
  );
  return { etag: String(headers.etag), data };
};

export const updateOrganization = async (
  id: string,
  body: UpdateOrganization,
  etag: string,
): Promise<void> => {
  const { data } = await axios.put<void>(`organizations/${id}`, body, {
    headers: { 'If-Match': etag },
  });
  return data;
};

export const deleteOrganization = async (
  id: string,
  etag: string,
): Promise<void> => {
  await axios.delete(`organizations/${id}`, { headers: { 'If-Match': etag } });
};
