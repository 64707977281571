import { useCallback, useMemo } from 'react';
import {
  createSearchParams,
  useSearchParams as useReactRouterDomSearchParams,
} from 'react-router-dom';

const searchParamKeys = [
  'rowId',
  'pageSize',
  'currentPage',
  'sortBy',
  'sortDir',
  'search',
  'email',
] as const;

type SearchParamKey = (typeof searchParamKeys)[number];
type SearchParams = Partial<Record<SearchParamKey, string>>;
type SetSearchParams = (searchParams: SearchParams) => void;
type UpdateSearchParam = (keyValue: [SearchParamKey, string]) => void;
type UpdateSearchParams = (keyValues: [SearchParamKey, string][]) => void;
type DeleteSearchParam = (key: SearchParamKey) => void;
type DeleteSearchParams = (keys: SearchParamKey[]) => void;
interface SearchParamsCallbacks {
  setSearchParams: SetSearchParams;
  updateSearchParam: UpdateSearchParam;
  updateSearchParams: UpdateSearchParams;
  deleteSearchParam: DeleteSearchParam;
  deleteSearchParams: DeleteSearchParams;
}
type UseSearchParams = [SearchParams, SearchParamsCallbacks];

const isSearchParams = (value: Record<string, string>): value is SearchParams =>
  Object.keys(value).every((k) =>
    Array.from<string>(searchParamKeys).includes(k),
  );

export const useSearchParams = (): UseSearchParams => {
  const [params, setParams] = useReactRouterDomSearchParams();

  const searchParams = useMemo((): SearchParams => {
    const result = Object.fromEntries([...params]);
    return isSearchParams(result) ? result : {};
  }, [params]);

  const setUnsortedParams = useCallback<(arg: [string, string][]) => void>(
    (arg) => {
      setParams(createSearchParams(arg.sort(([a], [b]) => (a > b ? 1 : -1))), {
        replace: true,
      });
    },
    [setParams],
  );

  const setSearchParams = useCallback<SetSearchParams>(
    (arg) => {
      setUnsortedParams(Object.entries(arg));
    },
    [setUnsortedParams],
  );

  const updateSearchParam = useCallback<UpdateSearchParam>(
    ([key, value]) => {
      setUnsortedParams([
        ...[...params].filter(([k]) => k !== key),
        [key, value],
      ]);
    },
    [params, setUnsortedParams],
  );

  const updateSearchParams = useCallback<UpdateSearchParams>(
    (keyValues) => {
      setUnsortedParams([
        ...[...params].filter(([k]) => !keyValues.some(([key]) => k === key)),
        ...keyValues,
      ]);
    },
    [params, setUnsortedParams],
  );

  const deleteSearchParam = useCallback<DeleteSearchParam>(
    (key) => {
      setUnsortedParams([...[...params].filter(([k]) => k !== key)]);
    },
    [params, setUnsortedParams],
  );

  const deleteSearchParams = useCallback<DeleteSearchParams>(
    (keys) => {
      setUnsortedParams([
        ...[...params].filter(([k]) => !(keys as string[]).includes(k)),
      ]);
    },
    [params, setUnsortedParams],
  );

  return useMemo<UseSearchParams>(
    () => [
      searchParams,
      {
        setSearchParams,
        updateSearchParam,
        updateSearchParams,
        deleteSearchParam,
        deleteSearchParams,
      },
    ],
    [
      deleteSearchParam,
      deleteSearchParams,
      searchParams,
      setSearchParams,
      updateSearchParam,
      updateSearchParams,
    ],
  );
};
