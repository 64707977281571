import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Stack, Button, Skeleton } from '@mui/material';
import {
  CheckRounded as SubmitIcon,
  CloseRounded as CancelIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppSelectForm } from 'components/AppFormControl';
import { HeatBalanceForm } from 'components/AppProcessStepForms';
import { getHeatBalancePresets, HeatBalance } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (settings: HeatBalance) => void;
}

export const LoadHeatBalancePresetDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activePreset, setActivePreset] = useState<string>('');

  const {
    data: presets,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['heat-balance-presets'],
    queryFn: () => getHeatBalancePresets(),
    enabled: open,
    placeholderData: keepPreviousData,
    staleTime: 0,
  });

  useEffect(() => {
    if (isError)
      enqueueSnackbar({
        key: `get_presets_fail_${Date.now()}`,
        message: t('get_presets_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isError, t]);

  const presetSettings = useMemo<HeatBalance | undefined>(() => {
    const preset = presets?.values.find(({ id }) => id === activePreset);
    return preset
      ? {
          active: true,
          type: preset.type,
          repetitions: preset.repetitions,
          area: preset.area,
          pointSpreadAlgName: preset.pointSpreadAlgName,
          pointSpreadSettings: preset.pointSpreadSettings,
          dwellTimeAlgName: preset.dwellTimeAlgName,
          dwellTimeSettings: preset.dwellTimeSettings,
          gridSizeMillimeter: preset.gridSizeMillimeter,
          beamPowerWatt: preset.beamPowerWatt,
          gridOffsetPercent: preset.gridOffsetPercent,
          spotSizeMicrometer: preset.spotSizeMicrometer,
          seeds: preset.seeds,
        }
      : undefined;
  }, [activePreset, presets?.values]);

  const handleClose = useCallback<() => void>(() => {
    setActivePreset('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback<() => void>(() => {
    if (presetSettings) onSubmit(presetSettings);
    handleClose();
  }, [handleClose, onSubmit, presetSettings]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('load_preset')}
      actions={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
            sx={{ flex: 1 }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SubmitIcon />}
            onClick={handleSubmit}
            disabled={!activePreset}
            sx={{ flex: 2 }}
          >
            {t('apply')}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2}>
        {isLoading ? (
          <Skeleton sx={{ height: 50 }} />
        ) : (
          <AppSelectForm
            label={t('choose_preset')}
            helperText={t('choose_preset_helper')}
            value={activePreset}
            onChange={(value) => {
              setActivePreset(value as string);
            }}
            options={
              presets?.values.map(({ id, name, material }) => ({
                value: id,
                label: name,
                tooltip: t('preview_material', { value: material }),
              })) ?? []
            }
          />
        )}
        {presetSettings && <HeatBalanceForm values={presetSettings} disabled />}
      </Stack>
    </AppDialog>
  );
};
