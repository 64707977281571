import { FC } from 'react';
import { List, Paper, Stack, SvgIconProps } from '@mui/material';
import {
  AllInboxRounded as ManagementIcon,
  TuneRounded as PresetsIcon,
  HelpRounded as HelpIcon,
  SettingsRounded as SettingsIcon,
} from '@mui/icons-material';

import { HomeRoute, homeRoutes } from 'layouts/Private';
import { AppSidebarMenuGroup } from 'components/AppSidebar';
import { ReactComponent as FreemeltLogo } from 'assets/logo/freemelt.svg';

const menuGroups: {
  title: HomeRoute['title'];
  Icon: FC<SvgIconProps>;
}[] = [
  { title: 'management', Icon: ManagementIcon },
  { title: 'presets', Icon: PresetsIcon },
  { title: 'help', Icon: HelpIcon },
  { title: 'settings', Icon: SettingsIcon },
];

interface Props {
  expanded: boolean;
}

export const AppSidebar: FC<Props> = ({ expanded }) => {
  return (
    <Paper
      sx={{
        minWidth: expanded ? { xs: 1, sm: 300 } : { xs: 0, sm: 65 },
        width: expanded ? { xs: 1, sm: 300 } : { xs: 0, sm: 65 },
        transition: 'width 0.5s, min-width 0.5s',
        bgcolor: 'background.default',
        overflow: 'hidden auto',
      }}
      square
    >
      <Stack height={1} justifyContent="space-between">
        <List>
          {menuGroups.map(({ title, Icon }, key) => (
            <AppSidebarMenuGroup
              key={key}
              expanded={expanded}
              groupTitle={title}
              Icon={Icon}
              items={homeRoutes.filter(({ group }) => group === title)}
            />
          ))}
        </List>
        <Stack alignItems="center" p={2}>
          <FreemeltLogo style={{ margin: 'auto' }} />
        </Stack>
      </Stack>
    </Paper>
  );
};
