import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Tooltip, Button } from '@mui/material';
import {
  CheckRounded as ApplyIcon,
  HistoryRounded as DiscardIcon,
} from '@mui/icons-material';

import { AppSwitchForm } from 'components/AppFormControl';

export interface Props {
  onApply: () => void;
  onDiscard: () => void;
  disabled: boolean;
  applyDisabled: boolean;
  discardDisabled: boolean;
  skip?: {
    values: Record<string, boolean>;
    onChange: (value: boolean) => void;
  };
}

export const ProcessStepPanelActions: FC<Props> = ({
  onApply,
  onDiscard,
  disabled,
  applyDisabled,
  discardDisabled,
  skip,
}) => {
  const { t } = useTranslation();

  const skipValues = useMemo(
    () => (skip ? Object.values(skip.values) : undefined),
    [skip],
  );

  const isSkipping = useMemo(
    (): boolean => !!skipValues?.length && skipValues.every((value) => value),
    [skipValues],
  );

  const skipVaries = useMemo(
    () =>
      !!skipValues && skipValues.some((value) => value !== skipValues.at(0)),
    [skipValues],
  );

  return (
    <Stack m={2} spacing={2} alignItems="stretch">
      {skip && (
        <Stack pl={1}>
          <AppSwitchForm
            label={t('skip_process_step')}
            helperText={t('skip_process_step_helper')}
            varies={skipVaries}
            checked={isSkipping}
            onChange={skip.onChange}
            disabled={disabled}
          />
        </Stack>
      )}
      <Stack direction="row" spacing={2} flex={1}>
        <Tooltip
          title={t(
            disabled || discardDisabled
              ? 'discard_changes_disabled'
              : isSkipping
                ? 'skipping_partition'
                : 'discard_changes_helper',
          )}
        >
          <span style={{ flex: 1 }}>
            <Button
              onClick={onDiscard}
              startIcon={<DiscardIcon />}
              variant="outlined"
              color="inherit"
              disabled={disabled || discardDisabled || isSkipping}
              fullWidth
            >
              {t('discard_changes')}
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={t(
            disabled || applyDisabled
              ? 'apply_settings_disabled'
              : isSkipping
                ? 'skipping_partition'
                : 'apply_settings_helper',
          )}
        >
          <span style={{ flex: 2 }}>
            <Button
              onClick={onApply}
              startIcon={<ApplyIcon />}
              variant="contained"
              color="primary"
              disabled={disabled || applyDisabled || isSkipping}
              fullWidth
            >
              {t('apply')}
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
