import { FC } from 'react';

import {
  StartHeatPresetPreview,
  JumpSafePresetPreview,
  SpatterSafePresetPreview,
  MeltPresetPreview,
  HeatBalancePresetPreview,
} from 'components/AppPresetPreview';
import { ProcessSteps } from 'api';

interface Props {
  processStep: keyof ProcessSteps;
}

export const AppPresetPreview: FC<Props> = ({ processStep }) => {
  switch (processStep) {
    case 'startHeat':
      return <StartHeatPresetPreview />;
    case 'jumpSafe':
      return <JumpSafePresetPreview />;
    case 'spatterSafe':
      return <SpatterSafePresetPreview />;
    case 'melt':
      return <MeltPresetPreview />;
    case 'heatBalance':
      return <HeatBalancePresetPreview />;
  }
};
