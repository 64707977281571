import axios from 'axios';
import buildQuery from 'odata-query';

import {
  CreateUser,
  FilteredUsers,
  ODataUsers,
  UpdateUser,
  UserPreview,
  UserPreviewWithEtag,
  UserRequestParams,
} from 'api';

export const getUsers = async (
  params?: UserRequestParams,
): Promise<FilteredUsers> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataUsers>(`users${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getCurrentUser = async (): Promise<UserPreviewWithEtag> => {
  const { headers, data } = await axios.get<UserPreview>(`users/currentuser`);
  return { etag: String(headers.etag), data };
};

export const getUserPreview = async (
  id: string,
): Promise<UserPreviewWithEtag> => {
  const { headers, data } = await axios.get<UserPreview>(`users/${id}`);
  return { etag: String(headers.etag), data };
};

export const createUser = async (
  body: CreateUser,
): Promise<UserPreviewWithEtag> => {
  const { headers, data } = await axios.post<UserPreview>('users', body);
  return { etag: String(headers.etag), data };
};

export const addUserToOrganization = async (
  organizationId: string,
  body: CreateUser,
): Promise<UserPreviewWithEtag> => {
  const { headers, data } = await axios.post<UserPreview>(
    `users/${organizationId}`,
    body,
  );
  return { etag: String(headers.etag), data };
};

export const updateUser = async (
  body: UpdateUser,
  etag: string,
): Promise<void> => {
  const { data } = await axios.put<void>(`users`, body, {
    headers: { 'If-Match': etag },
  });
  return data;
};

export const deleteUser = async (id: string, etag: string): Promise<void> => {
  await axios.delete(`users/${id}`, { headers: { 'If-Match': etag } });
};
