import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Grid, Stack, Typography } from '@mui/material';
import {
  LaunchRounded as OpenIcon,
  ContentCopyRounded as DuplicateIcon,
  DeleteRounded as DeleteIcon,
  DownloadRounded as DownloadIcon,
} from '@mui/icons-material';

import { AppPreviewDrawer } from 'components/AppPreviewDrawer';
import { DuplicateProjectDialog, DeleteProjectDialog } from 'pages/Projects';
import { App3DView } from 'components/App3DView';
import {
  getProjectBuildFileLink,
  getProjectPreview,
  ProjectState,
  UserPreviewWithEtag,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';
import { checkWritePermission, subtract } from 'utils';

export const AppProjectPreview: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [{ rowId: projectId }, { deleteSearchParam }] = useSearchParams();

  const onClose = useCallback<() => void>(() => {
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  const {
    data: preview,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['project', { projectId }],
    queryFn: () => getProjectPreview(projectId!),
    enabled: !!projectId,
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        key: `get_project_preview_fail_${Date.now()}`,
        message: t('get_project_preview_fail'),
        variant: 'error',
      });
      onClose();
    }
  }, [enqueueSnackbar, isError, onClose, t]);

  const { data: currentUser } = useQuery<UserPreviewWithEtag>({
    queryKey: ['current-user'],
  });

  const userHasWritePermission = useMemo<boolean>(
    () => checkWritePermission(currentUser?.data, preview?.data.createdBy),
    [currentUser?.data, preview?.data.createdBy],
  );

  const { mutate: downloadBuildFile, isPending: isPendingDownloadLink } =
    useMutation({
      mutationFn: (id: string) => getProjectBuildFileLink(id),
      onSuccess: (link, id) => {
        const anchor = document.createElement('a');
        anchor.href = link;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      },
      onError: () => {
        enqueueSnackbar({
          key: `download_build_files_fail_${Date.now()}`,
          message: t('download_build_files_fail'),
          variant: 'error',
        });
      },
    });

  if (isLoading) return <AppPreviewDrawer open onClose={onClose} isLoading />;

  if (preview)
    return (
      <>
        <AppPreviewDrawer
          open
          onClose={onClose}
          title={preview.data.name}
          actions={
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => navigate(preview.data.id)}
                variant="outlined"
                startIcon={<OpenIcon />}
                fullWidth
              >
                {t('open')}
              </Button>
              <Button
                onClick={() => setDuplicateDialog(true)}
                variant="outlined"
                color="secondary"
                startIcon={<DuplicateIcon />}
                fullWidth
              >
                {t('duplicate')}
              </Button>
              <Button
                onClick={() => setDeleteDialog(true)}
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                disabled={!userHasWritePermission}
                fullWidth
              >
                {t('delete')}
              </Button>
            </Stack>
          }
        >
          <Stack spacing={1} flex={1}>
            <Typography variant="h5">{t('model')}</Typography>
            <Grid container flex={1}>
              <Grid
                item
                xs={12}
                lg={5}
                pr={{ xs: 0, lg: 2 }}
                pb={{ xs: 2, lg: 0 }}
              >
                <Typography flex={1}>
                  {t('preview_fileName', {
                    value: preview.data.model.name,
                  })}
                </Typography>
                <Typography flex={1}>
                  {t('preview_layerThickness', {
                    value:
                      preview.data.model.sliceInfo.layerThicknessMillimeter,
                  })}
                </Typography>
                <Typography flex={1}>
                  {t('preview_height', {
                    value: subtract(
                      preview.data.model.sliceInfo.endZMillimeter,
                      subtract(
                        preview.data.model.sliceInfo.startZMillimeter,
                        preview.data.model.sliceInfo.layerThicknessMillimeter,
                      ),
                    ),
                  })}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={7}
                sx={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'text.primary',
                  borderRadius: ({ shape }) => `${shape.borderRadius}px`,
                  boxSizing: 'border-box',
                }}
              >
                <App3DView
                  model={preview.data.model}
                  modelTransform={preview.data.modelTransform}
                  modelPreviewSettings={{
                    axesHelper: false,
                    buildTank: false,
                    highlightSelected: false,
                    highlightUnsliced: false,
                    startPlate: true,
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5">{t('general')}</Typography>
            <Typography>
              {t('preview_material', { value: preview.data.material })}
            </Typography>
            <Typography>
              {t('preview_description', { value: preview.data.description })}
            </Typography>
            <Typography>
              {t('preview_created', {
                value: preview.data.createdBy
                  ? `${preview.data.createdBy.email} (${t('dateTime', {
                      value: new Date(preview.data.createdDate),
                    })})`
                  : t('dateTime', {
                      value: new Date(preview.data.createdDate),
                    }),
              })}
            </Typography>
            <Typography>
              {t('preview_updated', {
                value: preview.data.updatedBy
                  ? `${preview.data.updatedBy.email} (${t('dateTime', {
                      value: new Date(preview.data.updatedDate),
                    })})`
                  : t('dateTime', {
                      value: new Date(preview.data.updatedDate),
                    }),
              })}
            </Typography>
          </Stack>
          <Stack spacing={1} flex={1}>
            <Typography variant="h5">{t('build_files')}</Typography>
            <Typography>
              {t('preview_status', {
                value:
                  t(`projectState:${preview.data.state}`) +
                  (preview.data.state === ProjectState.Generating
                    ? ` (${preview.data.progressPercentage}%)`
                    : ''),
              })}
            </Typography>
            <Button
              onClick={() => downloadBuildFile(projectId!)}
              color="inherit"
              variant="outlined"
              startIcon={<DownloadIcon />}
              disabled={
                !projectId ||
                isPendingDownloadLink ||
                preview.data.state !== ProjectState.GeneratedSuccessfully
              }
              fullWidth
            >
              {t('download_build_files')}
            </Button>
          </Stack>
        </AppPreviewDrawer>
        <DuplicateProjectDialog
          open={duplicateDialog}
          onClose={() => setDuplicateDialog(false)}
          project={preview.data}
        />
        {userHasWritePermission && (
          <DeleteProjectDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            projectId={preview.data.id}
          />
        )}
      </>
    );

  return null;
};
