import { FC } from 'react';
import { Object3D, Color } from 'three';

import { useMaterial } from 'hooks';

interface Props {
  mesh: Object3D;
  height: { startZMillimeter: number; endZMillimeter: number };
  highlights: {
    startZMillimeter: number[];
    endZMillimeter: number[];
    size: number;
  };
}

export const ProjectMesh: FC<Props> = ({ mesh, height, highlights }) => {
  const material = useMaterial({
    color: new Color(0.7, 0.7, 0.7),
    onBeforeCompile: highlights.size
      ? (parameters) => {
          parameters.uniforms.startZMillimeter = {
            value: highlights.startZMillimeter,
          };
          parameters.uniforms.endZMillimeter = {
            value: highlights.endZMillimeter,
          };
          parameters.uniforms.size = { value: highlights.size };
          parameters.uniforms.objectStart = { value: height.startZMillimeter };
          parameters.uniforms.objectEnd = { value: height.endZMillimeter };
          parameters.vertexShader = `varying vec3 vPos;
${parameters.vertexShader}`.replace(
            `#include <begin_vertex>`,
            `
    #include <begin_vertex>
    vPos = vec3(position);`,
          );
          parameters.fragmentShader = `
uniform float startZMillimeter[450];
uniform float endZMillimeter[450];
uniform int size;
uniform float objectStart;
uniform float objectEnd;
varying vec3 vPos;
${parameters.fragmentShader}`.replace(
            `vec4 diffuseColor = vec4( diffuse, opacity );`,
            `
  vec3 color = vec3(0.7, 0.7, 0.7);
  for (int i = 0; i < size; i++) {
    if (vPos.z >= startZMillimeter[i] && vPos.z <= endZMillimeter[i]) {
      color = vec3(0.35, 0.7, 0.45);
    };
  };
  if (vPos.z < objectStart || vPos.z > objectEnd) {
    color = vec3(1, 0.04, 0.04);
  };
  vec4 diffuseColor = vec4( color, opacity );
`,
          );
        }
      : (parameters) => {
          parameters.uniforms.objectStart = { value: height.startZMillimeter };
          parameters.uniforms.objectEnd = { value: height.endZMillimeter };
          parameters.vertexShader = `varying vec3 vPos;
${parameters.vertexShader}`.replace(
            `#include <begin_vertex>`,
            `
  #include <begin_vertex>
  vPos = vec3(position);`,
          );
          parameters.fragmentShader = `
uniform float objectStart;
uniform float objectEnd;
varying vec3 vPos;
${parameters.fragmentShader}`.replace(
            `vec4 diffuseColor = vec4( diffuse, opacity );`,
            `
vec3 color = vec3(0.7, 0.7, 0.7);
if (vPos.z < objectStart || vPos.z > objectEnd) {
  color = vec3(1, 0.04, 0.04);
};
vec4 diffuseColor = vec4( color, opacity );
`,
          );
        },
  });

  return (
    <mesh {...mesh} material={material}>
      {undefined}
    </mesh>
  );
};
