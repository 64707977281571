import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ForwardToInboxRounded as EmailIcon } from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppButtonForm } from 'components/AppFormControl';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AppContactUsDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('contact_us')}
      actions={
        <AppButtonForm
          label={t('email_support')}
          helperText={t('email_support_helper')}
          componentProps={{
            href: 'mailto:support@pixelmelt.io',
            startIcon: <EmailIcon />,
          }}
        />
      }
    >
      {t('contact_us_dialog_description')}
    </AppDialog>
  );
};
