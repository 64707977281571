import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TuneRounded as LoadIcon,
  SaveRounded as SaveIcon,
} from '@mui/icons-material';

import { ProjectPanel } from 'components/ProjectPanel';
import { StartHeatForm } from 'components/AppProcessStepForms';
import { LoadStartHeatPresetDialog } from 'components/AppLoadPresetDialogs';
import { AppIconButton } from 'components/AppIconButton';
import { AppConfirmationDialog } from 'components/AppDialog';
import { CreateStartHeatPresetDialog as CreatePresetDialog } from 'components/CreatePresetDialog';
import { StartHeat } from 'api';
import {
  useSelector,
  useDispatch,
  useKeyboardShortcut,
  useSnackbar,
} from 'hooks';
import {
  setDraft,
  saveDraft,
  discardDraft,
  defaultStartHeat,
  selectStartHeatDraftValue,
  selectStartHeatSavedValue,
} from 'slices/startHeatSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const StartHeatPanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const draftValue = useSelector(selectStartHeatDraftValue);
  const savedValue = useSelector(selectStartHeatSavedValue);

  const [loadPresetDialog, setLoadPresetDialog] = useState(false);
  const [savePresetDialog, setSavePresetDialog] = useState(false);
  const [discardDraftDialog, setDiscardDraftDialog] = useState(false);

  const values = useMemo(
    (): StartHeat => draftValue ?? savedValue ?? defaultStartHeat,
    [draftValue, savedValue],
  );

  const disabled = useMemo(
    (): boolean => !userHasWritePermission || generateHasInitiated,
    [userHasWritePermission, generateHasInitiated],
  );

  const saveDraftDisabled = useMemo(
    (): boolean =>
      !draftValue?.pointSpreadAlgName || !draftValue?.dwellTimeAlgName,
    [draftValue],
  );

  const handleUpdateDraft = useCallback(
    (parameters: StartHeat): void => {
      dispatch(setDraft(parameters));
    },
    [dispatch],
  );

  const handleSaveDraft = useCallback((): void => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else if (saveDraftDisabled) {
      enqueueSnackbar({
        key: 'save_settings_disabled',
        message: t('apply_settings_failed'),
        variant: 'info',
      });
    } else {
      dispatch(saveDraft());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    generateHasInitiated,
    saveDraftDisabled,
    t,
    userHasWritePermission,
  ]);

  const handleDiscardDraft = useCallback((): void => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else if (!draftValue) {
      enqueueSnackbar({
        key: 'discard_settings_disabled',
        message: 'discard_settings_disabled',
        variant: 'info',
      });
    } else {
      dispatch(discardDraft());
    }
  }, [
    draftValue,
    dispatch,
    enqueueSnackbar,
    generateHasInitiated,
    t,
    userHasWritePermission,
  ]);

  useKeyboardShortcut({ key: 'm', ctrl: true }, handleSaveDraft);
  useKeyboardShortcut({ key: 'z', ctrl: true }, () =>
    setDiscardDraftDialog(true),
  );

  return (
    <>
      <ProjectPanel
        title={t('start_heat')}
        actions={{
          onApply: handleSaveDraft,
          onDiscard: () => setDiscardDraftDialog(true),
          disabled: disabled,
          applyDisabled: saveDraftDisabled,
          discardDisabled: !draftValue,
        }}
        options={
          <>
            <AppIconButton
              onClick={() => setLoadPresetDialog(true)}
              icon={<LoadIcon />}
              helperText={t('load_preset')}
              disabled={disabled}
            />
            <AppIconButton
              onClick={() => setSavePresetDialog(true)}
              icon={<SaveIcon />}
              helperText={t('save_preset')}
              disabled={disabled}
            />
          </>
        }
        rootProps={{
          onClick: () => {
            if (!userHasWritePermission) {
              enqueueSnackbar({
                key: 'no_write_permission',
                message: t('no_write_permission'),
                variant: 'info',
              });
            } else if (generateHasInitiated) {
              enqueueSnackbar({
                key: 'project_settings_blocked',
                message: t('project_settings_blocked'),
                variant: 'info',
              });
            }
          },
        }}
      >
        <StartHeatForm
          values={values}
          onChangeValues={handleUpdateDraft}
          disabled={disabled}
        />
      </ProjectPanel>
      <LoadStartHeatPresetDialog
        open={loadPresetDialog}
        onClose={() => setLoadPresetDialog(false)}
        onSubmit={handleUpdateDraft}
      />
      <CreatePresetDialog
        open={savePresetDialog}
        onClose={() => setSavePresetDialog(false)}
        initialSetting={values}
      />
      <AppConfirmationDialog
        open={discardDraftDialog}
        onClose={() => setDiscardDraftDialog(false)}
        onConfirm={() => {
          handleDiscardDraft();
          setDiscardDraftDialog(false);
        }}
        title={t('discard_changes')}
        text={t('discard_changes_confirmation')}
      />
    </>
  );
};
