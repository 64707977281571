import { PartitionEnergyRequest } from 'api/types';
import { hasProperties } from 'utils';

export const isValidPartitionEnergyRequest = (
  request: unknown[],
): request is PartitionEnergyRequest =>
  request.every(
    (value) =>
      hasProperties(
        value,
        'partitionId',
        'beamPowerWatt',
        'gridSizeMillimeter',
        'dwellTimeAlgName',
        'dwellTimeSettings',
      ) &&
      typeof value.partitionId === 'string' &&
      typeof value.beamPowerWatt === 'number' &&
      typeof value.gridSizeMillimeter === 'number' &&
      typeof value.dwellTimeAlgName === 'string' &&
      Array.isArray(value.dwellTimeSettings) &&
      value.dwellTimeSettings.every((v) => typeof v === 'string'),
  );
