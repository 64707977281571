import { FC, ReactNode } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowDropDownRounded as ExpandMoreIcon } from '@mui/icons-material';

interface Props {
  title: string;
  loading?: boolean;
  children: ReactNode;
}

export const ProjectMenuPanelGroup: FC<Props> = ({
  title,
  loading,
  children,
}) => {
  return (
    <Accordion sx={{ py: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Skeleton variant="rectangular" sx={{ height: 30 }} />
        ) : (
          <Stack spacing={2}>{children}</Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
