import { Area, ResponseWithEtag } from 'api';

export enum Selection {
  model = 'model',
  objects = 'objects',
}

export enum HeatBalanceType {
  custom = 'custom',
  predefined = 'predefined',
}

export interface ExposureParameters {
  pointSpreadAlgName: string;
  pointSpreadSettings: string[];
  dwellTimeAlgName: string;
  dwellTimeSettings: string[];
  gridSizeMillimeter: number;
  gridOffsetPercent: number;
  beamPowerWatt: number;
  spotSizeMicrometer: number;
  seeds: number[];
}

interface HeatParameters extends ExposureParameters {
  repetitions: number;
  area: Area | null;
}

export enum TemperatureSensor {
  Sensor1 = 'Sensor1',
  Sensor2 = 'Sensor2',
  Sensor3 = 'Sensor3',
  Sensor4 = 'Sensor4',
}

export interface StartHeat extends ExposureParameters {
  targetTemperatureDegreeCelsius: number;
  temperatureSensor: TemperatureSensor;
  area: Area;
}

export type JumpSafe = HeatParameters;

export type SpatterSafe = HeatParameters;

export type Melt = ExposureParameters;

export interface HeatBalance extends HeatParameters {
  type: HeatBalanceType;
}

interface Savable<T> {
  draft: T | null;
  saved: T | null;
  skip: boolean;
}

export interface Partition<TParameters> {
  id: string;
  objectId: number | null;
  startZMillimeter: number;
  endZMillimeter: number;
  parameters: Savable<TParameters>;
}

export interface PartitionSettings<TParameters> {
  selection: Selection;
  partitions: Partition<TParameters>[];
  selectedPartitionIds: string[];
}

export interface ProcessSteps {
  startHeat: PartitionSettings<StartHeat>;
  jumpSafe: PartitionSettings<JumpSafe>;
  spatterSafe: PartitionSettings<SpatterSafe>;
  melt: PartitionSettings<Melt>;
  heatBalance: PartitionSettings<HeatBalance>;
}

export type ProcessStepsResponse = Readonly<ProcessSteps>;
export type ProcessStepsResponseWithEtag =
  ResponseWithEtag<ProcessStepsResponse>;
export type UpdateProcessSteps = ProcessSteps;
