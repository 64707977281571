import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import {
  KeyRounded as PasswordIcon,
  DeleteRounded as DeleteIcon,
} from '@mui/icons-material';

import { ChangePasswordDialog, DeleteAccountDialog } from 'pages/Account';
import { AppTextForm } from 'components/AppFormControl';
import { useSelector } from 'hooks';
import { selectUser } from 'slices/userSlice';

export const Account: FC = () => {
  const { t } = useTranslation();
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const user = useSelector(selectUser);

  return (
    <>
      <Stack
        component={Paper}
        divider={<Divider light />}
        height={1}
        overflow="hidden"
        spacing={2}
        m={2}
        p={2}
      >
        <Stack direction="row" minHeight={75} spacing={8} p={4}>
          <Typography variant="h5" align="right" flex={1}>
            {t('profile')}
          </Typography>
          <Stack flex={3} spacing={2}>
            <AppTextForm
              label={t('email')}
              value={user?.email ?? ''}
              componentProps={{ disabled: true }}
            />
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setChangePasswordDialog(true)}
              startIcon={<PasswordIcon />}
            >
              {t('change_password')}
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" minHeight={75} spacing={8} p={4}>
          <Typography variant="h5" align="right" flex={1}>
            {t('delete_account')}
          </Typography>
          <Stack flex={3} spacing={2}>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setDeleteDialog(true)}
              startIcon={<DeleteIcon />}
            >
              {t('delete_account')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <ChangePasswordDialog
        open={changePasswordDialog}
        onClose={() => setChangePasswordDialog(false)}
      />
      <DeleteAccountDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
      />
    </>
  );
};
