import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState, PartitionQuery, extractPartitionIds } from 'utils';
import { JumpSafe, Partition, PartitionSettings, Selection } from 'api/types';

export const defaultJumpSafe: JumpSafe = {
  repetitions: 10,
  area: null,
  spotSizeMicrometer: 2000,
  beamPowerWatt: 600,
  gridSizeMillimeter: 0.1,
  gridOffsetPercent: 0,
  seeds: [],
  pointSpreadAlgName: '',
  dwellTimeAlgName: '',
  pointSpreadSettings: [],
  dwellTimeSettings: [],
};

export const initialState: PartitionSettings<JumpSafe> = {
  selection: Selection.model,
  partitions: [],
  selectedPartitionIds: [],
};

const jumpSafeSlice = createSlice({
  name: 'jumpSafe',
  initialState,
  reducers: {
    loadJumpSafe: (
      state,
      { payload }: PayloadAction<PartitionSettings<JumpSafe>>,
    ) => {
      state.selection = payload.selection;
      state.partitions = payload.partitions;
      state.selectedPartitionIds = payload.selectedPartitionIds;
    },
    resetJumpSafePartitions: (state) => {
      state.partitions = initialState.partitions;
    },
    setJumpSafePartitionSelection: (
      state,
      { payload }: PayloadAction<Selection>,
    ) => {
      state.selection = payload;
    },
    setJumpSafePartitions: (
      state,
      { payload }: PayloadAction<Partition<JumpSafe>[]>,
    ) => {
      state.partitions = payload;
      state.selectedPartitionIds = [];
    },
    setSelectedJumpSafePartitionIds: (
      state,
      { payload }: PayloadAction<PartitionQuery>,
    ) => {
      state.selectedPartitionIds = extractPartitionIds({
        query: payload,
        partitions: state.partitions,
        selectedPartitionIds: state.selectedPartitionIds,
      });
    },
    setDraft: (state, { payload }: PayloadAction<JumpSafe>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: payload,
              },
            }
          : partition,
      );
    },
    saveDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
                saved: partition.parameters.draft,
              },
            }
          : partition,
      );
    },
    discardDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
              },
            }
          : partition,
      );
    },
    setSkipJumpSafe: (state, { payload }: PayloadAction<boolean>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: { ...partition.parameters, skip: payload },
            }
          : partition,
      );
    },
  },
});

export const {
  loadJumpSafe,
  resetJumpSafePartitions,
  setJumpSafePartitionSelection,
  setJumpSafePartitions,
  setSelectedJumpSafePartitionIds,
  setDraft,
  saveDraft,
  discardDraft,
  setSkipJumpSafe,
} = jumpSafeSlice.actions;

export const selectJumpSafePartitionSelection = (state: RootState): Selection =>
  state.jumpSafe.selection;
export const selectJumpSafePartitions = (
  state: RootState,
): Partition<JumpSafe>[] => state.jumpSafe.partitions;
export const selectSelectedJumpSafePartitionIds = (
  state: RootState,
): string[] => state.jumpSafe.selectedPartitionIds;

export const selectSelectedJumpSafePartitions = createSelector(
  selectJumpSafePartitions,
  selectSelectedJumpSafePartitionIds,
  (partitions, selectedPartitionIds): Partition<JumpSafe>[] =>
    partitions.filter(({ id }) => selectedPartitionIds.includes(id)),
);
export const selectAllJumpSafePartitionsAreSaved = createSelector(
  selectJumpSafePartitions,
  (partitions): boolean =>
    partitions.every(({ parameters }) => !!parameters.saved || parameters.skip),
);
export const selectSkipJumpSafe = createSelector(
  selectSelectedJumpSafePartitions,
  (selectedPartitions): Record<string, boolean> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.skip]),
    ),
);
export const selectJumpSafeDraftValues = createSelector(
  selectSelectedJumpSafePartitions,
  (selectedPartitions): Record<string, JumpSafe | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.draft]),
    ),
);
export const selectJumpSafeSavedValues = createSelector(
  selectSelectedJumpSafePartitions,
  (selectedPartitions): Record<string, JumpSafe | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.saved]),
    ),
);

export default jumpSafeSlice.reducer;
