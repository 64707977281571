import { FC, useMemo } from 'react';
import { Paper, Divider, Stack } from '@mui/material';

import { MenuItem, ProjectMenuItem } from 'components/ProjectPanelMenu';
import { PrimaryMenuTab, SecondaryMenuTab } from 'slices/editorSlice';

type MenuTab = PrimaryMenuTab | SecondaryMenuTab;
type MenuItems = MenuItem<MenuTab>[];

interface Props {
  align?: 'right';
  menuGroups: MenuItems[];
  activeTabs: MenuItems;
  onMenuTabClick: (item: MenuTab) => void;
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectMenu: FC<Props> = ({
  align,
  menuGroups,
  activeTabs,
  onMenuTabClick,
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const disabledTabs = useMemo((): MenuTab[] => {
    if (
      activeTabs.every(
        ({ name }) => !Object.keys(PrimaryMenuTab).includes(name),
      )
    )
      return [SecondaryMenuTab.selection, SecondaryMenuTab.energy];
    if (activeTabs.some(({ name }) => name === PrimaryMenuTab.project))
      return [SecondaryMenuTab.selection, SecondaryMenuTab.energy];
    if (activeTabs.some(({ name }) => name === PrimaryMenuTab.start_heat))
      return [SecondaryMenuTab.selection, SecondaryMenuTab.energy];
    return [];
  }, [activeTabs]);

  return (
    <Stack
      width={1}
      height={1}
      position="absolute"
      direction={align === 'right' ? 'row-reverse' : 'row'}
      right={align === 'right' ? 0 : undefined}
      alignItems="center"
    >
      <Stack>
        {menuGroups.map((tabs, groupIndex) => (
          <Stack
            component={Paper}
            key={groupIndex}
            sx={{
              zIndex: ({ zIndex }) => zIndex.drawer - 1,
              width: 85,
              borderTopLeftRadius: groupIndex > 0 ? 0 : undefined,
              borderTopRightRadius: groupIndex > 0 ? 0 : undefined,
              borderBottomLeftRadius:
                menuGroups.length !== groupIndex + 1 ? 0 : undefined,
              borderBottomRightRadius:
                menuGroups.length !== groupIndex + 1 ? 0 : undefined,
            }}
          >
            {tabs.map((props, key) => (
              <ProjectMenuItem
                {...props}
                key={key}
                active={activeTabs.some(
                  (activeItem) => activeItem.name === props.name,
                )}
                onClick={() => {
                  onMenuTabClick(props.name);
                }}
                disabled={disabledTabs.includes(props.name)}
              />
            ))}
            {menuGroups.length > groupIndex + 1 && <Divider />}
          </Stack>
        ))}
      </Stack>
      <Stack
        height={1}
        width={{ xs: 1, sm: 350, lg: 400 }}
        sx={{ transition: 'all 0.5s' }}
      >
        {activeTabs
          .filter((tab) =>
            menuGroups.some(
              (group) =>
                group.includes(tab) && !disabledTabs.includes(tab.name),
            ),
          )
          .map(({ Panel }, key) => (
            <Panel
              key={key}
              userHasWritePermission={userHasWritePermission}
              generateHasInitiated={generateHasInitiated}
            />
          ))}
      </Stack>
    </Stack>
  );
};
