import axios from 'axios';

import {
  ProcessStepsResponseWithEtag,
  ProcessStepsResponse,
  UpdateProcessSteps,
} from 'api';

export const getProjectProcessSteps = async (
  id: string,
): Promise<ProcessStepsResponseWithEtag> => {
  const { headers, data } = await axios.get<ProcessStepsResponse>(
    `projects/${id}/process-steps`,
  );
  return { etag: String(headers.etag), data };
};

export const updateProjectProcessSteps = async (
  id: string,
  body: UpdateProcessSteps,
  etag: string,
): Promise<ProcessStepsResponseWithEtag> => {
  const { headers, data } = await axios.put<ProcessStepsResponse>(
    `projects/${id}/process-steps`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return { etag: String(headers.etag), data };
};
