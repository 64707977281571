import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { AppDeletionDialog } from 'components/AppDialog';
import { deleteProject, ErrorResponse, getProjectPreview } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

interface Props {
  open: boolean;
  projectId: string;
  onClose: () => void;
}

export const DeleteProjectDialog: FC<Props> = ({
  open,
  projectId,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();

  const {
    data: preview,
    isLoading: isPreviewLoading,
    isError: isPreviewError,
    isRefetching: isPreviewRefetching,
    isRefetchError: isPreviewRefetchError,
  } = useQuery({
    queryKey: ['project', { projectId }],
    queryFn: () => (projectId ? getProjectPreview(projectId) : undefined),
  });

  useEffect(() => {
    if (isPreviewError || isPreviewRefetchError) {
      enqueueSnackbar({
        key: `get_project_preview_fail_${Date.now()}`,
        message: t('get_project_preview_fail'),
        variant: 'error',
      });
      deleteSearchParam('rowId');
    }
  }, [
    deleteSearchParam,
    enqueueSnackbar,
    isPreviewError,
    isPreviewRefetchError,
    t,
  ]);

  const { mutate: onDeleteProject, isPending: isDeleting } = useMutation({
    mutationFn: () => {
      if (!preview) throw new Error('project preview does not exist');
      return deleteProject(preview.data.id, preview.etag);
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `delete_project_success_${Date.now()}`,
        message: t('delete_project_success', { name: preview?.data.name }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({ queryKey: ['projects'] });
      queryClient.removeQueries({ queryKey: ['project', { projectId }] });
      queryClient.removeQueries({
        queryKey: ['projects-settings', { projectId }],
      });
      deleteSearchParam('rowId');
      onClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'delete_project_decrepit',
          message: t('delete_project_decrepit', {
            name: preview?.data.name,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({ queryKey: ['project', { projectId }] })
                  .then(() => {
                    closeSnackbar('delete_project_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `delete_project_fail_${Date.now()}`,
          message: t('delete_project_fail', { name: preview?.data.name }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  return (
    <AppDeletionDialog
      open={open}
      onClose={onClose}
      title={t('delete_project')}
      text={t('delete_project_confirmation', { name: preview?.data.name })}
      onConfirm={onDeleteProject}
      confirmDisabled={isDeleting || isPreviewLoading || isPreviewRefetching}
    />
  );
};
