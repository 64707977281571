import { FC, ReactNode } from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

interface Props extends IconButtonProps {
  icon: ReactNode;
  helperText?: string;
}

export const AppIconButton: FC<Props> = ({
  icon,
  helperText,
  ...componentProps
}) => {
  return (
    <Tooltip title={helperText} placement="top" arrow>
      <span>
        <IconButton {...componentProps}>{icon}</IconButton>
      </span>
    </Tooltip>
  );
};
