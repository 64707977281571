import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'utils';

export interface User {
  id: string;
  email: string;
  verified: boolean;
}

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState): User | null => state.user.user;

export default userSlice.reducer;
