const mimeTypes = {
  '3mf': 'application/octet-stream',
  zip: 'application/zip',
} as const;

/**
 * Convert base64 to Blob
 * @param data
 * @param [mediaType='']
 * @param [sliceSize=512]
 * @returns
 */
export const base64TOBlob = (
  data: string,
  mediaType = '',
  sliceSize = 512,
): Blob => {
  const byteCharacters: string = atob(data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice: string = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array<number>(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type: mediaType });
};

/**
 * Convert base64 to dataUrl
 * @param data
 * @param extension
 * @returns
 */
export const base64ToDataUrl = (
  data: string,
  extension: keyof typeof mimeTypes,
): string => `data:${mimeTypes[extension]};base64,${data}`;

/**
 * Convert dataUrl to base64
 * @param data
 * @returns
 */
export const dataUrlToBase64 = (data: string): string => {
  const base64 = data.split(',').at(1);
  if (!base64) throw new Error('Invalid data url.');
  return base64;
};
