import axios from 'axios';

import {
  Algorithms,
  PartitionEnergyRequest,
  PartitionEnergyPerAreaResponse,
} from 'api';

export const getAlgorithms = async (): Promise<Algorithms> => {
  const { data } = await axios.get<Algorithms>('operations/algorithms');
  return data;
};

export const getEnergiesPerAreas = async (
  body: PartitionEnergyRequest,
): Promise<PartitionEnergyPerAreaResponse> => {
  const { data } = await axios.post<PartitionEnergyPerAreaResponse>(
    'operations/energy-per-area',
    body,
  );
  return data;
};
