import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'utils';
import {
  StartHeat,
  PartitionSettings,
  Selection,
  AreaShape,
  TemperatureSensor,
  Partition,
} from 'api/types';

export const defaultStartHeat: StartHeat = {
  targetTemperatureDegreeCelsius: 800,
  temperatureSensor: TemperatureSensor.Sensor1,
  area: {
    origin: { x: 0, y: 0 },
    shape: AreaShape.Circle,
    distanceMillimeter: 40,
    rotationDegree: 0,
  },
  spotSizeMicrometer: 2000,
  beamPowerWatt: 600,
  gridSizeMillimeter: 0.1,
  gridOffsetPercent: 0,
  seeds: [],
  pointSpreadAlgName: '',
  dwellTimeAlgName: '',
  pointSpreadSettings: [],
  dwellTimeSettings: [],
};

export const initialState: PartitionSettings<StartHeat> = {
  selection: Selection.model,
  partitions: [],
  selectedPartitionIds: [],
};

const startHeatSlice = createSlice({
  name: 'startHeat',
  initialState,
  reducers: {
    loadStartHeat: (
      state,
      { payload }: PayloadAction<PartitionSettings<StartHeat>>,
    ) => {
      state.selection = payload.selection;
      state.partitions = payload.partitions;
      state.selectedPartitionIds = payload.selectedPartitionIds;
    },
    resetStartHeatPartitions: (state) => {
      state.partitions = initialState.partitions;
    },
    setDraft: (state, { payload }: PayloadAction<StartHeat>) => {
      state.partitions = state.partitions.map((partition) => ({
        ...partition,
        parameters: { ...partition.parameters, draft: payload },
      }));
    },
    saveDraft: (state) => {
      state.partitions = state.partitions.map((partition) => ({
        ...partition,
        parameters: {
          ...partition.parameters,
          draft: null,
          saved: partition.parameters.draft,
        },
      }));
    },
    discardDraft: (state) => {
      state.partitions = state.partitions.map((partition) => ({
        ...partition,
        parameters: { ...partition.parameters, draft: null },
      }));
    },
  },
});

export const {
  loadStartHeat,
  resetStartHeatPartitions,
  setDraft,
  saveDraft,
  discardDraft,
} = startHeatSlice.actions;

const selectStartHeatPartitions = (state: RootState): Partition<StartHeat>[] =>
  state.startHeat.partitions;

export const selectStartHeatDraftValue = createSelector(
  selectStartHeatPartitions,
  (partitions): StartHeat | null => partitions.at(0)?.parameters.draft ?? null,
);
export const selectStartHeatSavedValue = createSelector(
  selectStartHeatPartitions,
  (partitions): StartHeat | null => partitions.at(0)?.parameters.saved ?? null,
);

export default startHeatSlice.reducer;
