import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import { ProjectPanel } from 'components/ProjectPanel';
import { ProjectMenuPanelGroup } from 'components/ProjectMenuPanelGroup';
import {
  AppCheckboxForm,
  AppNumberForm,
  AppSelectForm,
  AppTextForm,
} from 'components/AppFormControl';
import { ProjectExposureOrder } from 'components/ProjectExposureOrder';
import {
  ModelPreview,
  ProjectSettingsResponseWithEtag,
  projectSettingsRequirements,
} from 'api';
import { useSelector, useDispatch, useSnackbar } from 'hooks';
import {
  selectProjectSettings,
  setDescription,
  setLayerFeed,
  setMaterial,
  setName,
  setProHeat,
  setRotationDegree,
  setShiftExposureOrder,
  setSimultaneousMelting,
  setTransformMillimeter,
} from 'slices/projectSettingsSlice';

const rotationOptions = [
  { label: '0°', value: 0 },
  { label: '90°', value: 90 },
];

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectSettingsPanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const {
    name,
    description,
    material,
    shiftExposureOrder,
    simultaneousMelting,
    transformMillimeter,
    rotationDegree,
    layerFeed,
    proHeat,
  } = useSelector(selectProjectSettings);
  const dispatch = useDispatch();

  const disabled = useMemo<boolean>(
    () => !userHasWritePermission || generateHasInitiated,
    [generateHasInitiated, userHasWritePermission],
  );

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>({
    queryKey: ['project-settings', { projectId }],
  });

  const model = useMemo<ModelPreview | undefined>(
    () => projectSettings?.data.model,
    [projectSettings?.data.model],
  );

  return (
    <ProjectPanel
      title={t('project')}
      rootProps={{
        onClick: () => {
          if (!userHasWritePermission) {
            enqueueSnackbar({
              key: 'no_write_permission',
              message: t('no_write_permission'),
              variant: 'info',
            });
          } else if (generateHasInitiated) {
            enqueueSnackbar({
              key: 'project_settings_blocked',
              message: t('project_settings_blocked'),
              variant: 'info',
            });
          }
        },
      }}
    >
      <Stack spacing={2} overflow="hidden">
        <ProjectMenuPanelGroup title={t('general')}>
          <AppTextForm
            label={t('name')}
            value={name}
            onChange={(value) => dispatch(setName(value))}
            helperText={t('project_name_helper')}
            componentProps={{ disabled }}
          />
          <AppTextForm
            label={t('description')}
            value={description}
            onChange={(value) => dispatch(setDescription(value))}
            helperText={t('project_description_helper')}
            componentProps={{ multiline: true, disabled }}
          />
          <AppTextForm
            label={t('material')}
            value={material}
            onChange={(value) => dispatch(setMaterial(value))}
            helperText={t('project_material_helper')}
            componentProps={{ disabled }}
          />
        </ProjectMenuPanelGroup>
        <ProjectMenuPanelGroup title={t('model')}>
          <Typography fontWeight="bold">{t('transform')}</Typography>
          <Stack spacing={2} direction="row">
            <AppNumberForm
              label={t('x')}
              helperText={t('transform_x_helper')}
              value={transformMillimeter.x}
              onChange={(value) =>
                dispatch(setTransformMillimeter({ key: 'x', value }))
              }
              unit={t('unit:millimeter')}
              disabled={disabled}
            />
            <AppNumberForm
              label={t('y')}
              helperText={t('transform_y_helper')}
              value={transformMillimeter.y}
              onChange={(value) =>
                dispatch(setTransformMillimeter({ key: 'y', value }))
              }
              unit={t('unit:millimeter')}
              disabled={disabled}
            />
          </Stack>
        </ProjectMenuPanelGroup>
        <ProjectMenuPanelGroup title={t('exposure')}>
          <AppCheckboxForm
            label={t('simultaneous_exposure')}
            helperText={t('simultaneous_exposure_helper')}
            checked={simultaneousMelting}
            onChange={(value) => dispatch(setSimultaneousMelting(value))}
            componentProps={{ disabled }}
          />
          <ProjectExposureOrder
            modelInfo={model?.sliceInfo}
            disabled={disabled}
          />
          <AppCheckboxForm
            label={t('shift_exposure_order')}
            helperText={t('shift_exposure_order_helper')}
            checked={shiftExposureOrder}
            onChange={(value) => dispatch(setShiftExposureOrder(value))}
            componentProps={{ disabled }}
          />
          <AppSelectForm
            label={t('rotation')}
            helperText={t('rotation_helper')}
            value={rotationDegree}
            onChange={(value) => {
              dispatch(setRotationDegree(Number(value)));
            }}
            options={rotationOptions}
            disabled={disabled}
          />
        </ProjectMenuPanelGroup>
        <ProjectMenuPanelGroup title={t('layer_feed')}>
          <AppNumberForm
            label={t('build_piston_distance')}
            helperText={t('build_piston_distance_helper')}
            value={layerFeed.buildPistonDistanceMillimeter}
            onChange={(value) =>
              dispatch(
                setLayerFeed({ key: 'buildPistonDistanceMillimeter', value }),
              )
            }
            errorText={t(
              'validations:layer_feed.buildPistonDistanceMillimeter',
              projectSettingsRequirements.layerFeed
                .buildPistonDistanceMillimeter,
            )}
            {...projectSettingsRequirements.layerFeed
              .buildPistonDistanceMillimeter}
            unit={t('unit:millimeter')}
            disabled={disabled}
          />
          <AppNumberForm
            label={t('powder_piston_distance')}
            helperText={t('powder_piston_distance_helper')}
            value={layerFeed.powderPistonDistanceMillimeter}
            onChange={(value) =>
              dispatch(
                setLayerFeed({
                  key: 'powderPistonDistanceMillimeter',
                  value,
                }),
              )
            }
            errorText={t(
              'validations:layer_feed.powderPistonDistanceMillimeter',
              projectSettingsRequirements.layerFeed
                .powderPistonDistanceMillimeter,
            )}
            {...projectSettingsRequirements.layerFeed
              .powderPistonDistanceMillimeter}
            unit={t('unit:millimeter')}
            disabled={disabled}
          />
          <AppNumberForm
            label={t('recoater_speed')}
            helperText={t('recoater_speed_helper')}
            value={layerFeed.recoaterSpeedMillimeterPerSecond}
            onChange={(value) =>
              dispatch(
                setLayerFeed({
                  key: 'recoaterSpeedMillimeterPerSecond',
                  value,
                }),
              )
            }
            errorText={t(
              'validations:layer_feed.recoaterSpeedMillimeterPerSecond',
              projectSettingsRequirements.layerFeed
                .recoaterSpeedMillimeterPerSecond,
            )}
            {...projectSettingsRequirements.layerFeed
              .recoaterSpeedMillimeterPerSecond}
            unit={t('unit:millimeter_per_second')}
            disabled={disabled}
          />
        </ProjectMenuPanelGroup>
        <ProjectMenuPanelGroup title={t('proheat')}>
          <AppCheckboxForm
            label={t('start_heat')}
            helperText={t('proheat_helper', { step: t('start_heat') })}
            checked={proHeat.startHeat}
            onChange={(value) =>
              dispatch(setProHeat({ key: 'startHeat', value }))
            }
            componentProps={{ disabled }}
          />
          <AppCheckboxForm
            label={t('jump_safe')}
            helperText={t('proheat_helper', { step: t('jump_safe') })}
            checked={proHeat.jumpSafe}
            onChange={(value) =>
              dispatch(setProHeat({ key: 'jumpSafe', value }))
            }
            componentProps={{ disabled }}
          />
          <AppCheckboxForm
            label={t('spatter_safe')}
            helperText={t('proheat_helper', { step: t('spatter_safe') })}
            checked={proHeat.spatterSafe}
            onChange={(value) =>
              dispatch(setProHeat({ key: 'spatterSafe', value }))
            }
            componentProps={{ disabled }}
          />
        </ProjectMenuPanelGroup>
      </Stack>
    </ProjectPanel>
  );
};
