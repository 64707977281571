import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Stack, useMediaQuery, Theme, Button } from '@mui/material';

import { ReactComponent as FreemeltPixelmeltLogo } from 'assets/logo/freemelt-pixelmelt.svg';
import { AppTextForm } from 'components/AppFormControl';
import { useAuthentication, useSearchParams, useSnackbar } from 'hooks';

export const Login: FC = () => {
  const { t } = useTranslation();
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'), {
    noSsr: true,
  });
  const navigate = useNavigate();
  const [{ email: initEmail }, { deleteSearchParam }] = useSearchParams();
  const [email, setEmail] = useState<string>(initEmail ?? '');
  const [password, setPassword] = useState<string>('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const { signIn } = useAuthentication();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSignIn = useCallback<() => void>(() => {
    setIsSigningIn(true);
    signIn({ email, password })
      .then(() => {
        closeSnackbar('reset_password_success');
      })
      .catch(({ message }: Error) => {
        if (message === 'new-password-required') {
          navigate(`/create-password?user_name=${email}`);
        } else {
          enqueueSnackbar({
            key: `login_fail_${Date.now()}`,
            message: t('login_fail'),
            variant: 'error',
            persist: true,
          });
        }
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  }, [signIn, email, password, closeSnackbar, navigate, enqueueSnackbar, t]);

  useEffect(() => {
    deleteSearchParam('email');
  }, [deleteSearchParam]);

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={xs}>
      <Stack
        height={1}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        py={10}
      >
        <Stack />
        <Stack width={0.6} spacing={2}>
          <AppTextForm
            label={t('email')}
            value={email}
            onChange={setEmail}
            componentProps={{ type: 'email' }}
          />
          <AppTextForm
            label={t('password')}
            value={password}
            onChange={setPassword}
            componentProps={{ sx: { mb: 4 }, type: 'password' }}
          />
          <Button
            onClick={handleSignIn}
            variant="contained"
            disabled={isSigningIn}
            fullWidth
          >
            {t('sign_in')}
          </Button>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate('/verify-account')}
              sx={{ flex: 1 }}
            >
              {t('register_account')}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate('/forgot-password')}
              sx={{ flex: 1 }}
            >
              {t('forgot_password')}
            </Button>
          </Stack>
        </Stack>
        <FreemeltPixelmeltLogo
          id="freemelt-pixelmelt-logo"
          style={{ width: '50%', minHeight: 65, alignSelf: 'center' }}
        />
      </Stack>
    </Dialog>
  );
};
