import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PerspectiveCamera } from 'three';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OrbitControls as OrbitControlsProps } from 'three-stdlib'; // https://github.com/pmndrs/drei/issues/730
import useMeasure from 'react-use-measure';

import { ProjectBuildTank } from 'components/ProjectBuildTank';
import { ProjectStartPlate } from 'components/ProjectStartPlate';
import { App3DObject } from 'components/App3DView';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { SlicedObject } from 'api';
import { ModelValidationCallbacks } from 'hooks';
import { ModelPreviewSettings } from 'slices/editorSlice';

interface Props {
  data: string | undefined;
  objects: SlicedObject[] | undefined;
  modelValidationCallbacks: ModelValidationCallbacks;
  modelPreviewSettings: ModelPreviewSettings;
  transformValue: [number, number];
}
interface Ref {
  resetCamera: () => void;
}

export const App3DCanvas = forwardRef<Ref, Props>(
  (
    {
      data,
      objects,
      modelValidationCallbacks,
      modelPreviewSettings,
      transformValue,
    },
    ref,
  ) => {
    const controlRef = useRef<OrbitControlsProps>(null);
    const [canvasRef, bounds] = useMeasure();
    const [aspect, setAspect] = useState<number>(1);

    const {
      axesHelper,
      buildTank,
      highlightSelected,
      highlightUnsliced,
      startPlate,
    } = useMemo<ModelPreviewSettings>(
      () => modelPreviewSettings,
      [modelPreviewSettings],
    );

    const camera = useMemo<PerspectiveCamera>(() => {
      const result = new PerspectiveCamera(75, aspect, 0.1, 1000);
      result.position.set(0, -200, 50);
      result.up.set(0, 0, 1);
      return result;
    }, [aspect]);

    useEffect(() => {
      setAspect(bounds.width / bounds.height);
    }, [bounds.height, bounds.width, camera]);

    useImperativeHandle<Ref, Ref>(
      ref,
      () => ({
        resetCamera: () => {
          controlRef.current?.reset();
        },
      }),
      [],
    );

    return (
      <Canvas
        flat
        dpr={[1, 2]}
        camera={{ ...camera, aspect } as PerspectiveCamera}
        ref={canvasRef}
      >
        <ambientLight intensity={1.2} />
        <directionalLight position={[-50, 50, 200]} intensity={0.4} />
        <directionalLight position={[50, -50, 100]} intensity={2} />
        {axesHelper && <axesHelper args={[250]} />}
        <OrbitControls
          enableDamping={false}
          maxPolarAngle={Math.PI / 2}
          minAzimuthAngle={Math.PI / 2}
          ref={controlRef}
        />
        {buildTank && <ProjectBuildTank />}
        {startPlate && <ProjectStartPlate />}
        <ErrorBoundary>
          {!!data && (
            <App3DObject
              data={data}
              transformValue={transformValue}
              objects={objects}
              highlightSelected={highlightSelected}
              highlightUnsliced={highlightUnsliced}
              modelValidationCallbacks={modelValidationCallbacks}
            />
          )}
        </ErrorBoundary>
      </Canvas>
    );
  },
);
App3DCanvas.displayName = 'App3DCanvas';
