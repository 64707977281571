import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Paper, Skeleton, Stack, Typography } from '@mui/material';

import { ProjectPanel } from 'components/ProjectPanel';
import { useSelector, useSnackbar } from 'hooks';
import {
  getEnergiesPerAreas,
  isValidPartitionEnergyRequest,
  PartitionEnergyRequest,
  ProjectSettingsResponseWithEtag,
} from 'api';
import { selectSavedValues, selectSelectedPartitions, subtract } from 'utils';

export const EnergyPanel: FC = () => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const filteredSavedValues = useSelector(selectSavedValues);
  const selectedPartitions = useSelector(selectSelectedPartitions);

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>({
    queryKey: ['project-settings', { projectId }],
  });

  const requestBody = useMemo((): PartitionEnergyRequest | null => {
    const result = Object.entries(filteredSavedValues)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) =>
        typeof value === 'object' ? { partitionId: key, ...value } : null,
      );
    return isValidPartitionEnergyRequest(result) ? result : null;
  }, [filteredSavedValues]);

  const {
    data: energies,
    isLoading: isEnergiesLoading,
    isError: isEnergiesError,
  } = useQuery({
    queryKey: ['energies-per-areas', requestBody],
    queryFn: () => getEnergiesPerAreas(requestBody!),
    enabled: !!requestBody,
  });

  useEffect(() => {
    if (isEnergiesError)
      enqueueSnackbar({
        key: `energy_per_area_fail_${Date.now()}`,
        message: t('energy_per_area_fail'),
        variant: 'error',
        persist: true,
      });
  }, [enqueueSnackbar, isEnergiesError, t]);

  const energyOfSelected = useMemo(
    (): {
      id: string;
      startZMillimeter: number;
      endZMillimeter: number;
      objectName: string | number | null;
      energyPerArea: number | string;
    }[] =>
      selectedPartitions.map(
        ({ id, endZMillimeter, startZMillimeter, objectId }) => {
          const layerThicknessMillimeter =
            (objectId !== null
              ? projectSettings?.data.model.sliceInfo.objects.find(
                  (o) => o.id === objectId,
                )?.layerThicknessMillimeter
              : projectSettings?.data.model.sliceInfo
                  .layerThicknessMillimeter) ?? 0;
          return {
            id,
            startZMillimeter: subtract(
              startZMillimeter,
              layerThicknessMillimeter,
            ),
            endZMillimeter,
            objectName:
              projectSettings?.data.model.sliceInfo.objects.find(
                ({ id }) => id === objectId,
              )?.name ?? objectId,
            energyPerArea:
              energies?.find(({ partitionId }) => partitionId === id)
                ?.energyPerAreaJoulePerSquareMillimeter ?? '-',
          };
        },
      ),
    [energies, projectSettings?.data.model.sliceInfo, selectedPartitions],
  );

  return (
    <ProjectPanel scale={2} title={t('energy')}>
      <Paper sx={{ height: 1, px: 2, overflow: 'hidden auto' }}>
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{t('energy_per_area')}</Typography>
            <Typography variant="h6">
              {t('unit:joule_per_square_millimeter')}
            </Typography>
          </Stack>
          {energyOfSelected.map(
            ({
              id,
              startZMillimeter,
              endZMillimeter,
              objectName,
              energyPerArea,
            }) =>
              isEnergiesLoading ? (
                <Skeleton key={id} variant="text" />
              ) : (
                <Stack
                  key={id}
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  {objectName !== null && (
                    <Typography variant="body2">[{objectName}]</Typography>
                  )}
                  <Typography variant="body2">{startZMillimeter}</Typography>
                  <Typography variant="body2">-</Typography>
                  <Typography variant="body2">{endZMillimeter}</Typography>
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ flex: 1 }}
                  >
                    {t('unit:millimeter')}
                  </Typography>
                  <Typography variant="body2">
                    {typeof energyPerArea === 'number'
                      ? Math.floor(energyPerArea * 10e2) / 10e2
                      : energyPerArea}
                  </Typography>
                </Stack>
              ),
          )}
        </>
      </Paper>
    </ProjectPanel>
  );
};
