import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CreateProjectDialog } from 'pages/Projects';
import { AppTable, TableColumn, TableData } from 'components/AppTable';
import { AppProjectPreview } from 'components/AppProjectPreview';
import { getProjects, getModels, getUsers, ProjectState } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

const tableColumns: TableColumn[] = [
  { name: 'name', label: 'name' },
  { name: 'createdBy/email', label: 'creator' },
  { name: 'createdDate', label: 'created' },
  { name: 'updatedDate', label: 'last_modified' },
  { name: 'model/name', label: 'model' },
  { name: 'state', label: 'build_files' },
];

const isColumnName = (sortBy: string | undefined): sortBy is string =>
  tableColumns.some(({ name }) => name === sortBy);
const isDirection = (sortDir: string | undefined): sortDir is 'asc' | 'desc' =>
  sortDir === 'asc' || sortDir === 'desc';

export const Projects: FC = () => {
  const { t } = useTranslation();
  const [
    { rowId: projectId, pageSize, currentPage, sortBy, sortDir, search },
    { updateSearchParam, deleteSearchParam, deleteSearchParams },
  ] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [createProjectDialog, setCreateProjectDialog] = useState(false);

  const [activeFilters, setActiveFilters] = useState<
    Partial<Record<'model/name' | 'createdBy/email' | 'state', string[]>>
  >({});

  const params = useMemo(
    () => ({
      size: pageSize ? +pageSize : 25,
      page: currentPage ? +currentPage : 1,
      sort:
        isColumnName(sortBy) && isDirection(sortDir)
          ? { columnName: sortBy, direction: sortDir }
          : null,
      search: search?.length ? search : undefined,
      filters: activeFilters,
    }),
    [activeFilters, currentPage, pageSize, search, sortBy, sortDir],
  );

  const {
    data: projects,
    refetch,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    isError,
    isRefetchError,
  } = useQuery({
    queryKey: ['projects', params],
    queryFn: () =>
      getProjects({
        top: params.size,
        skip: (params.page - 1) * params.size,
        orderBy: params.sort
          ? `${params.sort.columnName} ${params.sort.direction}`
          : 'createdDate desc',
        filter: {
          'tolower(name)': params.search
            ? { contains: params.search.toLowerCase() }
            : undefined,
          ...Object.entries(params.filters).reduce(
            (prevValue, [key, value]) =>
              value.length
                ? Object.assign(prevValue, { [key]: { in: value } })
                : prevValue,
            {},
          ),
        },
      }),
    placeholderData: keepPreviousData,
    staleTime: 0,
  });

  useEffect(() => {
    if (isError || isRefetchError)
      enqueueSnackbar({
        key: `get_projects_fail_${Date.now()}`,
        message: t('get_projects_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isError, isRefetchError, t]);

  const { data: models, isError: isModelError } = useQuery({
    queryKey: ['models'],
    queryFn: () => getModels(),
  });

  useEffect(() => {
    if (isModelError)
      enqueueSnackbar({
        key: `get_models_fail_${Date.now()}`,
        message: t('get_models_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isModelError, t]);

  const { data: users, isError: isUserError } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });

  useEffect(() => {
    if (isUserError)
      enqueueSnackbar({
        key: `get_users_fail_${Date.now()}`,
        message: t('get_users_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isUserError, t]);

  const modelsFilterOptions = useMemo<string[]>(() => {
    const modelNames = models?.values.map(({ name }) => name) ?? [];
    return modelNames.filter(
      (model, index) => modelNames.indexOf(model) === index,
    );
  }, [models?.values]);

  const usersFilterOptions = useMemo<string[]>(
    () => users?.values.map(({ email }) => email) ?? [],
    [users?.values],
  );

  const tableData = useMemo<TableData>(
    () => ({
      rows: (projects?.values ?? []).map(
        ({ id, name, createdBy, createdDate, updatedDate, model, state }) => ({
          id: id,
          hover: true,
          selected: projectId === id,
          onClick: () => updateSearchParam(['rowId', id]),
          sx: { cursor: 'pointer' },
          values: [
            { value: name },
            { value: createdBy.email },
            { value: t('dateTime', { value: new Date(createdDate) }) },
            { value: t('dateTime', { value: new Date(updatedDate) }) },
            { value: model.name },
            { value: t(`projectState:${state}`) },
          ],
        }),
      ),
    }),
    [projects, projectId, t, updateSearchParam],
  );

  useEffect(() => {
    if (
      (!!sortBy || !!sortDir) &&
      (!isColumnName(sortBy) || !isDirection(sortDir))
    )
      deleteSearchParams(['sortBy', 'sortDir']);
  }, [deleteSearchParams, sortBy, sortDir]);

  return (
    <>
      <AppTable
        columns={tableColumns}
        data={tableData}
        name={t('projects')}
        noDataText={
          isError || isRefetchError
            ? t('projects_table_no_data_error')
            : t('projects_table_no_data')
        }
        sx={{ height: 1, m: 2 }}
        tableProps={{ stickyHeader: true }}
        headerProps={{
          add: {
            tooltip: t('new_project_helper'),
            onClick: () => setCreateProjectDialog(true),
          },
          filter: {
            filters: [
              {
                title: 'createdBy/email',
                label: 'creator',
                options: usersFilterOptions.map((value) => ({ value })),
              },
              {
                title: 'model/name',
                label: 'model',
                options: modelsFilterOptions.map((value) => ({ value })),
              },
              {
                title: 'state',
                label: 'build_files',
                options: Object.values(ProjectState).map((value) => ({
                  value,
                  label: t(`projectState:${value}`),
                })),
              },
            ],
            activeFilters,
            onFilter: setActiveFilters,
          },
          search: {
            searchPhrase: params.search ?? '',
            onSearch: (value) =>
              value
                ? updateSearchParam(['search', value])
                : deleteSearchParam('search'),
          },
        }}
        pagination={{
          pageSize: params.size,
          currentPage: params.page,
          totalCount: projects?.count,
        }}
        sort={params.sort}
        fetchDate={new Date(dataUpdatedAt)}
        isError={isError}
        isRefetchError={isRefetchError}
        isLoading={isLoading}
        isRefetching={isRefetching}
        refetchData={() => void refetch()}
      />
      <AppProjectPreview />
      <CreateProjectDialog
        open={createProjectDialog}
        onClose={() => setCreateProjectDialog(false)}
      />
    </>
  );
};
