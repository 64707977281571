import { FC, useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button, Typography, IconButton } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { deleteUser, ErrorResponse, getUserPreview } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  userId: string | undefined;
  onClose: () => void;
}

export const DeleteUserDialog: FC<Props> = ({ userId, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const emailRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>('');
  const [incorrectEmail, setIncorrectEmail] = useState<boolean>(false);

  const handleClose = useCallback<() => void>(() => {
    setEmail('');
    onClose();
  }, [onClose]);

  const {
    data: preview,
    isLoading: isPreviewLoading,
    isRefetching: isPreviewRefetching,
    isError: isPreviewError,
    isRefetchError: isPreviewRefetchError,
  } = useQuery({
    queryKey: ['user', { userId }],
    queryFn: () => getUserPreview(userId!),
    enabled: !!userId,
  });

  useEffect(() => {
    if (isPreviewError || isPreviewRefetchError)
      enqueueSnackbar({
        key: `get_user_preview_fail_${Date.now()}`,
        message: t('get_user_preview_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isPreviewError, isPreviewRefetchError, t]);

  const { mutate: onDeleteUser, isPending: isDeleting } = useMutation({
    mutationKey: ['user', { userId }],
    mutationFn: () => {
      if (!preview) throw new Error('user preview does not exist');
      return deleteUser(preview.data.id, preview.etag);
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `delete_user_success_${Date.now()}`,
        message: t('delete_user_success', { email: preview?.data.email }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      queryClient.removeQueries({
        queryKey: ['user', { userId }],
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'delete_user_decrepit',
          message: t('delete_user_decrepit', {
            email: preview?.data.email,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({ queryKey: ['user', { userId }] })
                  .then(() => {
                    closeSnackbar('delete_user_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `delete_user_fail_${Date.now()}`,
          message: t('delete_user_fail', { email: preview?.data.email }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  const handleSubmit = useCallback<() => void>(() => {
    if (email !== preview?.data.email) {
      setIncorrectEmail(true);
    } else {
      onDeleteUser();
    }
  }, [preview?.data.email, email, onDeleteUser]);

  return (
    <AppDialog
      open={!!userId}
      onClose={handleClose}
      title={t('delete_user')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          disabled={isDeleting || isPreviewLoading || isPreviewRefetching}
          fullWidth
        >
          {t('delete')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <Typography>
          {t('delete_user_confirmation', { email: preview?.data.email })}
        </Typography>
        <AppTextForm
          ref={emailRef}
          label={t('confirm_email')}
          value={email}
          onChange={setEmail}
          helperText={t('delete_user_email_helper')}
          error={incorrectEmail}
          errorText={t('delete_user_email_not_match')}
        />
      </Stack>
    </AppDialog>
  );
};
