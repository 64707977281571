import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Tooltip,
  IconButton,
  TableRow,
  TableCell,
  Stack,
  Divider,
} from '@mui/material';
import {
  ArrowCircleUpRounded as MoveUpIcon,
  ArrowCircleDownRounded as MoveDownIcon,
} from '@mui/icons-material';

import { ProjectAddPartitionForm } from 'components/ProjectAddPartitionForm';
import { add } from 'utils';
import { SlicedObject, Partition } from 'api';
import { useProcessStepActions } from 'hooks';

const ROW_HEIGHT = 52;

interface Props extends SlicedObject {
  partitions: Partition<unknown>[];
  objectExposureOrder: number[];
  selected?: boolean;
  onAddPartition?: (
    breakpoint: [number, number],
    objectId: number | null,
  ) => void;
  onChangeObjectOrder?: (id: number, steps: number) => void;
  selectable?: boolean;
  children: ReactNode;
}

export const TreeItemObject: FC<Props> = ({
  id,
  name,
  startZMillimeter,
  endZMillimeter,
  layerThicknessMillimeter,
  partitions,
  objectExposureOrder,
  selected,
  onAddPartition,
  onChangeObjectOrder,
  selectable,
  children,
}) => {
  const { t } = useTranslation();
  const { setSelectedPartitions } = useProcessStepActions();

  const options = useMemo<number[]>(() => {
    const result = [];
    let i = startZMillimeter;
    while (i < endZMillimeter) {
      result.push(i);
      i = add(i, layerThicknessMillimeter);
    }
    return result;
  }, [startZMillimeter, endZMillimeter, layerThicknessMillimeter]);

  const handleAddPartition = useCallback<(breakpoint: number) => void>(
    (breakpoint: number) => {
      if (onAddPartition) {
        onAddPartition(
          [breakpoint, add(breakpoint, layerThicknessMillimeter)],
          id,
        );
      }
    },
    [onAddPartition, layerThicknessMillimeter, id],
  );

  const handleSelectPartition = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      if (selectable)
        setSelectedPartitions({
          target: id,
          event: { ctrlKey: event.ctrlKey, metaKey: event.metaKey },
        });
    },
    [selectable, setSelectedPartitions, id],
  );

  const handleChangeOrder = useCallback<(steps: number) => () => void>(
    (steps) => () => {
      if (onChangeObjectOrder) onChangeObjectOrder(id, steps);
    },
    [onChangeObjectOrder, id],
  );

  return (
    <>
      <TableRow
        hover={selectable}
        sx={{
          cursor: selectable ? 'pointer' : 'default',
          border: 'none',
          height: ROW_HEIGHT,
        }}
        onClick={handleSelectPartition}
        selected={selectable && selected}
      >
        <TableCell colSpan={2} sx={{ py: 0 }}>
          <Stack direction="row" alignItems="center" spacing={2} pl={2}>
            <Divider orientation="vertical" sx={{ height: ROW_HEIGHT }} />
            <Tooltip
              title={name ? t('object_{{id}}', { id }) : ''}
              placement="left"
              arrow
            >
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {name ?? t('object_{{id}}', { id })}
              </Typography>
            </Tooltip>
            {onChangeObjectOrder && (
              <Stack direction="row" spacing={1}>
                <Tooltip title={t('change_object_exposure_order_up')}>
                  <span>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={handleChangeOrder(-1)}
                      disabled={objectExposureOrder.indexOf(id) < 1}
                    >
                      <MoveUpIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('change_object_exposure_order_down')}>
                  <span>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={handleChangeOrder(+1)}
                      disabled={
                        objectExposureOrder.indexOf(id) >=
                        objectExposureOrder.length - 1
                      }
                    >
                      <MoveDownIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </TableCell>
        <TableCell align="right">
          {onAddPartition && (
            <ProjectAddPartitionForm
              iconButtonProps={{ disabled: !onAddPartition }}
              onAddPartition={handleAddPartition}
              options={options}
              getOptionDisabled={(option) =>
                partitions
                  .map(({ endZMillimeter }) => endZMillimeter)
                  .includes(option)
              }
              getOptionLabel={(option) => String(option)}
            />
          )}
        </TableCell>
      </TableRow>
      {children}
    </>
  );
};
