import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { AppTable, TableColumn, TableData } from 'components/AppTable';
import { AppModelPreview } from 'components/AppModelPreview';
import { getModels } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

const tableColumns: TableColumn[] = [{ name: 'name', label: 'name' }];

const isColumnName = (sortBy: string | undefined): sortBy is string =>
  tableColumns.some(({ name }) => name === sortBy);
const isDirection = (sortDir: string | undefined): sortDir is 'asc' | 'desc' =>
  sortDir === 'asc' || sortDir === 'desc';

export const Models: FC = () => {
  const { t } = useTranslation();
  const [
    { rowId: modelId, pageSize, currentPage, sortBy, sortDir, search },
    { updateSearchParam, deleteSearchParam, deleteSearchParams },
  ] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const params = useMemo(
    () => ({
      size: pageSize ? +pageSize : 25,
      page: currentPage ? +currentPage : 1,
      sort:
        isColumnName(sortBy) && isDirection(sortDir)
          ? { columnName: sortBy, direction: sortDir }
          : null,
      search: search?.length ? search : undefined,
    }),
    [currentPage, pageSize, search, sortBy, sortDir],
  );

  const {
    data: models,
    refetch,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    isError,
    isRefetchError,
  } = useQuery({
    queryKey: ['models', params],
    queryFn: () =>
      getModels({
        top: params.size,
        skip: (params.page - 1) * params.size,
        orderBy: params.sort
          ? `${params.sort.columnName} ${params.sort.direction}`
          : undefined,
        filter: {
          'tolower(name)': params.search
            ? { contains: params.search.toLowerCase() }
            : undefined,
        },
      }),
    placeholderData: keepPreviousData,
    staleTime: 0,
  });

  useEffect(() => {
    if (isError || isRefetchError)
      enqueueSnackbar({
        key: `get_models_fail_${Date.now()}`,
        message: t('get_models_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isError, isRefetchError, t]);

  const tableData = useMemo<TableData>(
    () => ({
      rows:
        (models?.values ?? []).map(({ id, name }) => ({
          id: id,
          hover: true,
          selected: modelId === id,
          onClick: () => updateSearchParam(['rowId', id]),
          sx: { cursor: 'pointer' },
          values: [{ value: name }],
        })) ?? [],
    }),
    [models, modelId, updateSearchParam],
  );

  useEffect(() => {
    if (
      (!!sortBy || !!sortDir) &&
      (!isColumnName(sortBy) || !isDirection(sortDir))
    )
      deleteSearchParams(['sortBy', 'sortDir']);
  }, [deleteSearchParams, sortBy, sortDir]);

  return (
    <>
      <AppTable
        columns={tableColumns}
        data={tableData}
        name={t('models')}
        noDataText={
          isError || isRefetchError
            ? t('models_table_no_data_error')
            : t('models_table_no_data')
        }
        sx={{ height: 1, m: 2 }}
        tableProps={{ stickyHeader: true }}
        headerProps={{
          search: {
            searchPhrase: params.search ?? '',
            onSearch: (value) =>
              value
                ? updateSearchParam(['search', value])
                : deleteSearchParam('search'),
          },
        }}
        pagination={{
          pageSize: params.size,
          currentPage: params.page,
          totalCount: models?.count,
        }}
        sort={params.sort}
        fetchDate={new Date(dataUpdatedAt)}
        isError={isError}
        isRefetchError={isRefetchError}
        isLoading={isLoading}
        isRefetching={isRefetching}
        refetchData={() => void refetch()}
      />
      <AppModelPreview />
    </>
  );
};
