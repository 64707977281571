import {
  useEffect,
  useMemo,
  useCallback,
  FC,
  useState,
  createRef,
  RefObject,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Dialog,
  Grid,
  Stack,
  Typography,
  Tooltip,
  Button,
  IconButton,
  useMediaQuery,
  Theme,
} from '@mui/material';
import {
  CloseRounded as CloseIcon,
  CheckRounded as SubmitIcon,
} from '@mui/icons-material';

import { AppConfirmationDialog } from 'components/AppDialog';
import { App3DView } from 'components/App3DView';
import {
  AppCheckboxForm,
  AppNumberForm,
  AppSelectForm,
  AppTextForm,
} from 'components/AppFormControl';
import { AppModelForm } from 'components/AppModelForm';
import { ProjectExposureOrder } from 'components/ProjectExposureOrder';
import { ProjectMenuPanelGroup } from 'components/ProjectMenuPanelGroup';
import {
  createProject,
  CreateProject,
  ErrorResponse,
  getModelPreview,
  projectSettingsRequirements,
} from 'api';
import { getProjectReduxState } from 'utils';
import { useSelector, useDispatch, useSearchParams, useSnackbar } from 'hooks';
import {
  resetProjectSettings,
  selectProjectSettings,
  setDescription,
  setLayerFeed,
  setMaterial,
  setName,
  setObjectExposureOrder,
  setProHeat,
  setRotationDegree,
  setShiftExposureOrder,
  setSimultaneousMelting,
  setTransformMillimeter,
} from 'slices/projectSettingsSlice';
import {
  selectModelPreviewSettings,
  setModelHighlights,
} from 'slices/editorSlice';

const rotationOptions = [
  { label: '0°', value: 0 },
  { label: '90°', value: 90 },
];

type CreateProjectFields = Extract<
  keyof CreateProject,
  'name' | 'description' | 'material' | 'modelId'
>;
interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateProjectDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const smallScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.only('xs'),
  );
  const [, { updateSearchParam }] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [closeDialog, setCloseDialog] = useState(false);
  const dispatch = useDispatch();
  const {
    name,
    description,
    material,
    transformMillimeter,
    simultaneousMelting,
    shiftExposureOrder,
    rotationDegree,
    layerFeed,
    proHeat,
  } = useSelector(selectProjectSettings);
  const modelPreviewSettings = useSelector(selectModelPreviewSettings);
  const [modelId, setModelId] = useState<string>();

  const [validationError, setValidationError] = useState<
    (keyof CreateProject)[]
  >([]);
  const refs = useMemo<
    Record<CreateProjectFields, RefObject<HTMLInputElement | HTMLButtonElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      description: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
      modelId: createRef<HTMLButtonElement>(),
    }),
    [],
  );

  const handleResetForm = useCallback<() => void>(() => {
    setModelId(undefined);
    dispatch(setModelHighlights([]));
  }, [dispatch]);

  const handleClose = useCallback<() => void>(() => {
    handleResetForm();
    dispatch(resetProjectSettings());
    setValidationError([]);
    onClose();
  }, [dispatch, handleResetForm, onClose]);

  const {
    data: model,
    isLoading: isLoadingModel,
    isError: isModelError,
  } = useQuery({
    queryKey: ['model', { modelId }],
    queryFn: () => getModelPreview(modelId!),
    enabled: !!modelId,
  });

  useEffect(() => {
    if (isModelError) {
      enqueueSnackbar({
        key: `get_model_fail_${Date.now()}`,
        message: t('get_model_fail'),
        variant: 'error',
      });
      onClose();
    }
  }, [enqueueSnackbar, isModelError, onClose, t]);

  const { mutate: onCreateProject, isPending: isLoadingCreate } = useMutation({
    mutationFn: () => {
      const { projectSettings } = getProjectReduxState();
      return createProject({
        ...projectSettings,
        modelId: model?.data.id ?? '',
      });
    },
    onSuccess: ({ data }) => {
      enqueueSnackbar({
        key: `create_project_success_${Date.now()}`,
        message: t('create_project_success'),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
      updateSearchParam(['rowId', data.id]);
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      enqueueSnackbar({
        key: `create_project_fail_${Date.now()}`,
        message: t('create_project_fail'),
        variant: 'error',
        persist: true,
      });
      if (response?.status === 400) {
        if (response.data.errors) {
          const errorKeys = Object.keys(response.data.errors)
            .map<CreateProjectFields | undefined>((key) => {
              if (key === 'Name') return 'name';
              if (key === 'Description') return 'description';
              if (key === 'Material') return 'material';
              if (key === '$.modelId' || key === 'ModelId') return 'modelId';
              return undefined;
            })
            .filter((key): key is CreateProjectFields => !!key);
          setValidationError(errorKeys);
          refs[errorKeys[0]]?.current?.focus();
        }
      }
    },
  });

  useEffect(() => {
    setValidationError([]);
  }, [name, material, description, modelId]);

  useEffect(() => {
    dispatch(setTransformMillimeter({ key: 'x', value: 0 }));
    dispatch(setTransformMillimeter({ key: 'y', value: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (model) {
      dispatch(
        setObjectExposureOrder(
          model.data.sliceInfo.objects.map(({ id }) => id),
        ),
      );
      dispatch(
        setLayerFeed({
          key: 'buildPistonDistanceMillimeter',
          value: model.data.sliceInfo.layerThicknessMillimeter,
        }),
      );
      dispatch(
        setLayerFeed({
          key: 'powderPistonDistanceMillimeter',
          value: model.data.sliceInfo.layerThicknessMillimeter * 2,
        }),
      );
    }
  }, [dispatch, model]);

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: { height: 1, minHeight: '80vh', overflow: 'hidden' },
        }}
        fullScreen={smallScreenSize}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          onClick={() => setCloseDialog(true)}
          sx={{
            zIndex: 1,
            position: 'absolute',
            right: (theme) => theme.spacing(2),
            top: (theme) => theme.spacing(2),
          }}
          disabled={isLoadingCreate || isLoadingModel}
        >
          <CloseIcon />
        </IconButton>
        <Grid container height={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            height={1}
            bgcolor="background.paper"
            p={4}
          >
            <Stack height={1} spacing={2}>
              <Typography variant="h4" px={2}>
                {t('new_project')}
              </Typography>
              <Stack flex={1} spacing={2} overflow="hidden auto">
                <ProjectMenuPanelGroup title={t('general')}>
                  <AppTextForm
                    label={t('project_name')}
                    value={name}
                    onChange={(value) => dispatch(setName(value))}
                    componentProps={{
                      placeholder: model?.data.name,
                      inputRef: refs.name,
                    }}
                    helperText={t('project_name_helper')}
                    error={validationError.includes('name')}
                    errorText={t('validations:create_project.name')}
                  />
                  <AppTextForm
                    label={t('description')}
                    value={description}
                    onChange={(value) => dispatch(setDescription(value))}
                    componentProps={{
                      multiline: true,
                      inputRef: refs.description,
                    }}
                    helperText={t('project_description_helper')}
                    error={validationError.includes('description')}
                    errorText={t('validations:create_project.description')}
                  />
                  <AppTextForm
                    label={t('material')}
                    value={material}
                    onChange={(value) => dispatch(setMaterial(value))}
                    componentProps={{ inputRef: refs.material }}
                    helperText={t('project_material_helper')}
                    error={validationError.includes('material')}
                    errorText={t('validations:create_project.material')}
                  />
                </ProjectMenuPanelGroup>
                <ProjectMenuPanelGroup title={t('model')}>
                  <AppModelForm
                    ref={refs.modelId as RefObject<HTMLButtonElement>}
                    data={model?.data}
                    onChangeModelId={setModelId}
                    error={validationError.includes('modelId')}
                    errorText={t('validations:create_project.modelId')}
                  />
                  <Typography fontWeight="bold">{t('transform')}</Typography>
                  <Stack direction="row" spacing={2}>
                    <AppNumberForm
                      label={t('x')}
                      helperText={t('transform_x_helper')}
                      error={validationError.includes('transformMillimeter')}
                      errorText={t(
                        'validations:create_project.transformMillimeter',
                      )}
                      value={transformMillimeter.x}
                      onChange={(value) => {
                        dispatch(setTransformMillimeter({ key: 'x', value }));
                      }}
                      unit={t('unit:millimeter')}
                    />
                    <AppNumberForm
                      label={t('y')}
                      helperText={t('transform_y_helper')}
                      error={validationError.includes('transformMillimeter')}
                      errorText={t(
                        'validations:create_project.transformMillimeter',
                      )}
                      value={transformMillimeter.y}
                      onChange={(value) => {
                        dispatch(setTransformMillimeter({ key: 'y', value }));
                      }}
                      unit={t('unit:millimeter')}
                    />
                  </Stack>
                </ProjectMenuPanelGroup>
                <ProjectMenuPanelGroup title={t('exposure')}>
                  <AppCheckboxForm
                    label={t('simultaneous_exposure')}
                    helperText={t('simultaneous_exposure_helper')}
                    checked={simultaneousMelting}
                    onChange={(value) => {
                      dispatch(setSimultaneousMelting(value));
                    }}
                  />
                  <ProjectExposureOrder modelInfo={model?.data.sliceInfo} />
                  <AppCheckboxForm
                    label={t('shift_exposure_order')}
                    helperText={t('shift_exposure_order_helper')}
                    checked={shiftExposureOrder}
                    onChange={(value) => dispatch(setShiftExposureOrder(value))}
                  />
                  <AppSelectForm
                    label={t('rotation')}
                    helperText={t('rotation_helper')}
                    value={rotationDegree}
                    onChange={(value) => {
                      dispatch(setRotationDegree(Number(value)));
                    }}
                    options={rotationOptions}
                  />
                </ProjectMenuPanelGroup>
                <ProjectMenuPanelGroup title={t('layer_feed')}>
                  <AppNumberForm
                    label={t('build_piston_distance')}
                    helperText={t('build_piston_distance_helper')}
                    value={layerFeed.buildPistonDistanceMillimeter}
                    onChange={(value) =>
                      dispatch(
                        setLayerFeed({
                          key: 'buildPistonDistanceMillimeter',
                          value,
                        }),
                      )
                    }
                    errorText={t(
                      'validations:layer_feed.buildPistonDistanceMillimeter',
                      projectSettingsRequirements.layerFeed
                        .buildPistonDistanceMillimeter,
                    )}
                    {...projectSettingsRequirements.layerFeed
                      .buildPistonDistanceMillimeter}
                    unit={t('unit:millimeter')}
                  />
                  <AppNumberForm
                    label={t('powder_piston_distance')}
                    helperText={t('powder_piston_distance_helper')}
                    value={layerFeed.powderPistonDistanceMillimeter}
                    onChange={(value) =>
                      dispatch(
                        setLayerFeed({
                          key: 'powderPistonDistanceMillimeter',
                          value,
                        }),
                      )
                    }
                    errorText={t(
                      'validations:layer_feed.powderPistonDistanceMillimeter',
                      projectSettingsRequirements.layerFeed
                        .powderPistonDistanceMillimeter,
                    )}
                    {...projectSettingsRequirements.layerFeed
                      .powderPistonDistanceMillimeter}
                    unit={t('unit:millimeter')}
                  />
                  <AppNumberForm
                    label={t('recoater_speed')}
                    helperText={t('recoater_speed_helper')}
                    value={layerFeed.recoaterSpeedMillimeterPerSecond}
                    onChange={(value) =>
                      dispatch(
                        setLayerFeed({
                          key: 'recoaterSpeedMillimeterPerSecond',
                          value,
                        }),
                      )
                    }
                    errorText={t(
                      'validations:layer_feed.recoaterSpeedMillimeterPerSecond',
                      projectSettingsRequirements.layerFeed
                        .recoaterSpeedMillimeterPerSecond,
                    )}
                    {...projectSettingsRequirements.layerFeed
                      .recoaterSpeedMillimeterPerSecond}
                    unit={t('unit:millimeter_per_second')}
                  />
                </ProjectMenuPanelGroup>
                <ProjectMenuPanelGroup title={t('proheat')}>
                  <AppCheckboxForm
                    label={t('start_heat')}
                    helperText={t('proheat_helper', { step: t('start_heat') })}
                    checked={proHeat.startHeat}
                    onChange={(value) =>
                      dispatch(setProHeat({ key: 'startHeat', value }))
                    }
                  />
                  <AppCheckboxForm
                    label={t('jump_safe')}
                    helperText={t('proheat_helper', { step: t('jump_safe') })}
                    checked={proHeat.jumpSafe}
                    onChange={(value) =>
                      dispatch(setProHeat({ key: 'jumpSafe', value }))
                    }
                  />
                  <AppCheckboxForm
                    label={t('spatter_safe')}
                    helperText={t('proheat_helper', {
                      step: t('spatter_safe'),
                    })}
                    checked={proHeat.spatterSafe}
                    onChange={(value) =>
                      dispatch(setProHeat({ key: 'spatterSafe', value }))
                    }
                  />
                </ProjectMenuPanelGroup>
              </Stack>
              <Stack direction="row" spacing={2} px={2}>
                <Tooltip title={t('create_project_dialog_cancel')}>
                  <span style={{ flex: 1 }}>
                    <Button
                      onClick={() => setCloseDialog(true)}
                      variant="outlined"
                      color="inherit"
                      disabled={isLoadingCreate || isLoadingModel}
                      startIcon={<CloseIcon />}
                      sx={{ height: 1 }}
                      fullWidth
                    >
                      {t('cancel')}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={t('create_project_helper')}>
                  <span style={{ flex: 2 }}>
                    <Button
                      onClick={() => onCreateProject()}
                      variant="contained"
                      startIcon={<SubmitIcon />}
                      disabled={isLoadingCreate || isLoadingModel}
                      fullWidth
                    >
                      {t('create_project')}
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={6} md={8}>
            <App3DView
              model={model?.data}
              settings={
                smallScreenSize
                  ? undefined
                  : {
                      settingsAnchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      settingsTransformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      settingsFabStyle: {
                        right: (theme) => theme.spacing(2),
                      },
                    }
              }
              modelTransform={transformMillimeter}
              modelPreviewSettings={modelPreviewSettings}
            />
          </Grid>
        </Grid>
      </Dialog>
      <AppConfirmationDialog
        open={closeDialog}
        onClose={() => setCloseDialog(false)}
        title={t('cancel')}
        text={t('create_project_dialog_cancel_confirmation')}
        onConfirm={() => {
          handleClose();
          setCloseDialog(false);
        }}
      />
    </>
  );
};
