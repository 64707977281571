import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TableRow, TableCell } from '@mui/material';

import { ProjectAddPartitionForm } from 'components/ProjectAddPartitionForm';
import { add } from 'utils';
import { SlicedModel, Partition } from 'api';
import { useProcessStepActions } from 'hooks';

const ROW_HEIGHT = 52;

interface Props extends SlicedModel {
  modelName: string | undefined;
  partitions: Partition<unknown>[];
  selected?: boolean;
  onAddPartition?: (breakpoint: [number, number]) => void;
  selectable?: boolean;
  children: ReactNode;
}

export const TreeItemModel: FC<Props> = ({
  modelName,
  startZMillimeter,
  endZMillimeter,
  layerThicknessMillimeter,
  partitions,
  selected,
  onAddPartition,
  selectable,
  children,
}) => {
  const { t } = useTranslation();
  const { setSelectedPartitions } = useProcessStepActions();

  const options = useMemo<number[]>(() => {
    const result = [];
    let i = startZMillimeter;
    while (i < endZMillimeter) {
      result.push(i);
      i = add(i, layerThicknessMillimeter);
    }
    return result;
  }, [startZMillimeter, endZMillimeter, layerThicknessMillimeter]);

  const handleAddPartition = useCallback<(breakpoint: number) => void>(
    (breakpoint: number) => {
      if (onAddPartition) {
        onAddPartition([breakpoint, add(breakpoint, layerThicknessMillimeter)]);
      }
    },
    [onAddPartition, layerThicknessMillimeter],
  );

  const handleSelectPartition = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      if (selectable)
        setSelectedPartitions({
          target: undefined,
          event: { ctrlKey: event.ctrlKey, metaKey: event.metaKey },
        });
    },
    [selectable, setSelectedPartitions],
  );

  return (
    <>
      <TableRow
        hover={selectable}
        sx={{
          cursor: selectable ? 'pointer' : 'default',
          border: 'none',
          height: ROW_HEIGHT,
        }}
        onClick={handleSelectPartition}
        selected={selectable && selected}
      >
        <TableCell colSpan={2}>
          <Typography variant="body2">{modelName ?? t('model')}</Typography>
        </TableCell>
        <TableCell align="right">
          {onAddPartition && (
            <ProjectAddPartitionForm
              iconButtonProps={{ disabled: !onAddPartition }}
              onAddPartition={handleAddPartition}
              options={options}
              getOptionDisabled={(option) =>
                partitions
                  .map(({ endZMillimeter }) => endZMillimeter)
                  .includes(option)
              }
              getOptionLabel={(option) => String(option)}
            />
          )}
        </TableCell>
      </TableRow>
      {children}
    </>
  );
};
