import { FC, useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button, Typography, IconButton } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { deleteUser, ErrorResponse, UserPreviewWithEtag } from 'api';
import { useAuthentication, useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DeleteAccountDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const passwordRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<boolean>(false);
  const { authenticateWithPassword } = useAuthentication();

  const handleClose = useCallback<() => void>(() => {
    setPassword('');
    onClose();
  }, [onClose]);

  const { data: currentUser } = useQuery<UserPreviewWithEtag>({
    queryKey: ['current-user'],
  });

  const { mutate: onDeleteAccount, isPending: isDeleting } = useMutation({
    mutationKey: ['current-user'],
    mutationFn: () => {
      if (!currentUser) throw new Error('current user does not exist');
      return deleteUser(currentUser.data.id, currentUser.etag);
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `delete_account_success_${Date.now()}`,
        message: t('delete_account_success'),
        variant: 'success',
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'delete_account_decrepit',
          message: t('delete_account_decrepit', {
            email: currentUser?.data.email,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({ queryKey: ['current-user'] })
                  .then(() => {
                    closeSnackbar('delete_user_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `delete_account_fail_${Date.now()}`,
          message: t('delete_account_fail', {
            email: currentUser?.data.email,
          }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  const handleSubmit = useCallback<() => void>(() => {
    if (!currentUser) return;
    authenticateWithPassword({ email: currentUser.data.email, password })
      .then(() => onDeleteAccount())
      .catch(() => {
        setValidationError(true);
        enqueueSnackbar({
          key: `delete_account_fail_${Date.now()}`,
          message: t('delete_account_fail', {
            email: currentUser.data.email,
          }),
          variant: 'error',
          persist: true,
        });
      });
  }, [
    authenticateWithPassword,
    password,
    onDeleteAccount,
    enqueueSnackbar,
    t,
    currentUser,
  ]);

  useEffect(() => {
    setValidationError(false);
  }, [password]);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('delete_account')}
      actions={
        <Button
          onClick={handleSubmit}
          disabled={isDeleting}
          color="error"
          variant="contained"
          startIcon={<DeleteIcon />}
          fullWidth
        >
          {t('delete_account')}
        </Button>
      }
    >
      <Stack spacing={2}>
        <Typography>{t('delete_account_confirmation')}</Typography>
        <AppTextForm
          label={t('password')}
          value={password}
          onChange={setPassword}
          componentProps={{
            type: 'password',
            autoComplete: 'current-password',
            inputRef: passwordRef,
          }}
          error={validationError}
          errorText={t('validations:delete_account.password')}
        />
      </Stack>
    </AppDialog>
  );
};
