import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import {
  RadioButtonUncheckedRounded as UnsavedIcon,
  CheckCircleRounded as SavedIcon,
  BlockRounded as SkipIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  draft: boolean;
  saved: boolean;
  skip: boolean;
  sx?: SxProps<Theme>;
}

export const ProjectPartitionStatus: FC<Props> = ({
  sx,
  draft,
  saved,
  skip,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(
        skip
          ? 'partition_status_skip'
          : saved
            ? draft
              ? 'partition_status_saved_draft'
              : 'partition_status_saved_nodraft'
            : draft
              ? 'partition_status_unsaved_draft'
              : 'partition_status_unsaved_nodraft',
      )}
    >
      {skip ? (
        <SkipIcon sx={sx} color="disabled" />
      ) : saved ? (
        <SavedIcon sx={sx} color={draft ? 'warning' : 'primary'} />
      ) : (
        <UnsavedIcon sx={sx} color={draft ? 'warning' : 'inherit'} />
      )}
    </Tooltip>
  );
};
