import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import {
  ViewDayRounded as PartitionIcon,
  CheckRounded as SubmitIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';
import { v4 as uuid } from 'uuid';

import { AppDialog, AppConfirmationDialog } from 'components/AppDialog';
import { AppToggleButtonForm } from 'components/AppFormControl';
import { ProjectTreeView } from 'components/ProjectTreeView';
import { AppIconButton } from 'components/AppIconButton';
import { SlicedModel, Selection } from 'api';
import { useSelector, useDispatch, useProcessStepActions } from 'hooks';
import { moveArray, selectPartitionSelection, selectPartitions } from 'utils';
import {
  selectProjectSettings,
  setObjectExposureOrder,
} from 'slices/projectSettingsSlice';
import { PrimaryMenuTab, selectPrimaryMenu } from 'slices/editorSlice';

interface Props {
  modelInfo: SlicedModel | undefined;
  modelName: string | undefined;
  disabled?: boolean;
}

export const ProjectPartitionForm: FC<Props> = ({
  modelInfo,
  modelName,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setSelection, setPartitions } = useProcessStepActions();
  const primaryMenu = useSelector(selectPrimaryMenu);
  const partitionSelection = useSelector(selectPartitionSelection);
  const partitions = useSelector(selectPartitions);
  const { objectExposureOrder } = useSelector(selectProjectSettings);

  const [dialog, setDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [changeSelection, setChangeSelection] = useState<Selection | null>(
    null,
  );
  const [partitionsDraft, setPartitionsDraft] = useState(partitions);
  const [selectionDraft, setSelectionDraft] =
    useState<Selection>(partitionSelection);
  const [objectExposureOrderDraft, setObjectExposureOrderDraft] =
    useState<number[]>(objectExposureOrder);
  const [isNewSelection, setIsNewSelection] = useState(false);

  const handleChangeSelection = useCallback((): void => {
    if (!changeSelection) {
      setPartitionsDraft(partitions);
      setSelectionDraft(partitionSelection);
      return;
    }
    setSelectionDraft(changeSelection);
    if (changeSelection === Selection.model && modelInfo) {
      setPartitionsDraft([
        {
          id: uuid(),
          startZMillimeter: modelInfo.startZMillimeter,
          endZMillimeter: modelInfo.endZMillimeter,
          objectId: null,
          parameters: { draft: null, saved: null, skip: false },
        },
      ]);
    }
    if (changeSelection === Selection.objects && modelInfo) {
      setPartitionsDraft(
        modelInfo.objects.map(
          ({ id: objectId, startZMillimeter, endZMillimeter }) => ({
            id: uuid(),
            startZMillimeter,
            endZMillimeter,
            objectId,
            parameters: { draft: null, saved: null, skip: false },
          }),
        ),
      );
    }
    setIsNewSelection(true);
    setChangeSelection(null);
  }, [changeSelection, modelInfo, partitions, partitionSelection]);

  const handleAddPartition = useCallback(
    (breakpoint: [number, number], objectId: number | null): void => {
      const index = partitionsDraft.findIndex(
        ({ startZMillimeter, endZMillimeter, objectId: parentId }) =>
          (objectId === null || parentId === objectId) &&
          startZMillimeter <= breakpoint[0] &&
          endZMillimeter >= breakpoint[1],
      );
      if (index >= 0) {
        const result = [...partitionsDraft];
        result.splice(
          index,
          1,
          { ...partitionsDraft[index], endZMillimeter: breakpoint[0] },
          {
            id: uuid(),
            startZMillimeter: breakpoint[1],
            endZMillimeter: partitionsDraft[index].endZMillimeter,
            objectId,
            parameters: partitionsDraft[index].parameters,
          },
        );
        setPartitionsDraft(result);
      }
    },
    [partitionsDraft, setPartitionsDraft],
  );

  const handleRemovePartition = useCallback(
    (id: string, effect: 'before' | 'after'): void => {
      if (partitionsDraft.length > 1) {
        const index = partitionsDraft.findIndex(
          ({ id: partitionId }) => partitionId === id,
        );
        if (index >= 0) {
          if (
            effect === 'before' &&
            index > 0 &&
            partitionsDraft[index].objectId ===
              partitionsDraft[index - 1].objectId
          ) {
            setPartitionsDraft((prevState) =>
              prevState
                .map((p, i) =>
                  i === index - 1
                    ? {
                        ...p,
                        endZMillimeter: partitionsDraft[index].endZMillimeter,
                      }
                    : p,
                )
                .filter((_p, i) => i !== index),
            );
          }
          if (
            effect === 'after' &&
            index + 1 < partitionsDraft.length &&
            partitionsDraft[index].objectId ===
              partitionsDraft[index + 1].objectId
          ) {
            setPartitionsDraft((prevState) =>
              prevState
                .map((p, i) =>
                  i === index + 1
                    ? {
                        ...p,
                        startZMillimeter: prevState[index].startZMillimeter,
                      }
                    : p,
                )
                .filter((_p, i) => i !== index),
            );
          }
        }
      }
    },
    [partitionsDraft, setPartitionsDraft],
  );

  const handleUpdatePartition = useCallback(
    (id: string, breakpoint: [number, number]): void => {
      const index = partitionsDraft.findIndex(
        ({ id: partitionId }) => partitionId === id,
      );
      if (index >= 0) {
        setPartitionsDraft((prevState) =>
          prevState.map((p, i) =>
            i === index ? { ...p, startZMillimeter: breakpoint[1] } : p,
          ),
        );
        if (
          partitionsDraft[index - 1]?.objectId ===
          partitionsDraft[index].objectId
        ) {
          setPartitionsDraft((prevState) =>
            prevState.map((p, i) =>
              i === index - 1
                ? {
                    ...p,
                    endZMillimeter: breakpoint[0],
                  }
                : p,
            ),
          );
        }
      }
    },
    [partitionsDraft, setPartitionsDraft],
  );

  const handleChangeObjectOrder = useCallback((id: number, steps: number) => {
    setObjectExposureOrderDraft((prevState) => moveArray(prevState, id, steps));
  }, []);

  const handleSubmit = useCallback<() => void>(() => {
    if (setPartitions) setPartitions(partitionsDraft);
    dispatch(setObjectExposureOrder(objectExposureOrderDraft));
    if (isNewSelection && setSelection) setSelection(selectionDraft);
    setDialog(false);
  }, [
    dispatch,
    isNewSelection,
    objectExposureOrderDraft,
    partitionsDraft,
    selectionDraft,
    setPartitions,
    setSelection,
  ]);

  const handleCancel = useCallback<() => void>(() => {
    handleChangeSelection();
    setCancelDialog(false);
    setDialog(false);
  }, [handleChangeSelection]);

  useEffect(() => {
    setPartitionsDraft(partitions);
  }, [partitions]);

  useEffect(() => {
    setSelectionDraft(partitionSelection);
  }, [partitionSelection]);

  useEffect(() => {
    setObjectExposureOrderDraft(objectExposureOrder);
  }, [objectExposureOrder]);

  return (
    <>
      <AppIconButton
        onClick={() => setDialog(true)}
        icon={<PartitionIcon />}
        helperText={t('partition_helper')}
        disabled={!!disabled || !modelInfo}
      />
      <AppDialog
        open={dialog}
        onClose={() => setCancelDialog(true)}
        title={t('partition')}
        actions={
          <Stack direction="row" spacing={2} width={1}>
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<CloseIcon />}
              onClick={() => setCancelDialog(true)}
              fullWidth
              sx={{ flex: 1 }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SubmitIcon />}
              onClick={handleSubmit}
              disabled={
                partitionsDraft.every((v, i) => partitions[i] === v) &&
                objectExposureOrderDraft.every(
                  (v, i) => objectExposureOrder[i] === v,
                )
              }
              fullWidth
              sx={{ flex: 2 }}
            >
              {t('apply')}
            </Button>
          </Stack>
        }
        dialogProps={{
          PaperProps: {
            sx: { height: 1, minHeight: '80vh', overflow: 'hidden' },
          },
          maxWidth: 'lg',
        }}
      >
        <Stack>
          <AppToggleButtonForm
            label={t('selection_type')}
            value={selectionDraft}
            onChange={(value) => {
              setChangeSelection(value as Selection);
            }}
            buttons={[
              { value: 'model', text: t('model') },
              { value: 'objects', text: t('objects') },
            ]}
            helperText={t('selection_type_helper')}
            disabled={primaryMenu.window === PrimaryMenuTab.jump_safe}
          />
          <ProjectTreeView
            modelInfo={modelInfo}
            modelName={modelName}
            partitions={partitionsDraft}
            selection={selectionDraft}
            objectExposureOrder={objectExposureOrderDraft}
            onAddPartition={handleAddPartition}
            onRemovePartition={handleRemovePartition}
            onUpdatePartition={handleUpdatePartition}
            onChangeObjectOrder={handleChangeObjectOrder}
          />
        </Stack>
      </AppDialog>
      <AppConfirmationDialog
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        title={t('partition_cancel')}
        text={t('partition_cancel_helper')}
        onConfirm={handleCancel}
      />
      <AppConfirmationDialog
        open={!!changeSelection}
        title={t('change_selection', {
          selection: changeSelection ? t(changeSelection) : '',
        })}
        text={t('change_selection_helper')}
        onClose={() => {
          setChangeSelection(null);
        }}
        onConfirm={handleChangeSelection}
      />
    </>
  );
};
