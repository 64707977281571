import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button, IconButton } from '@mui/material';
import {
  CheckRounded as SubmitIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { updateOrganization, ErrorResponse, OrganizationWithEtag } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  organization: OrganizationWithEtag | undefined;
  onClose: () => void;
}

export const UpdateOrganizationDialog: FC<Props> = ({
  organization,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [name, setName] = useState<string>();

  const handleClose = useCallback<() => void>(() => {
    setName(undefined);
    onClose();
  }, [onClose]);

  const { mutate: onUpdateOrganization, isPending: isUpdating } = useMutation({
    mutationKey: ['organization', { organizationId: organization?.data.id }],
    mutationFn: () => {
      if (!organization)
        throw new Error('organization organization does not exist');
      if (!name) throw new Error('role cannot be undefined');
      return updateOrganization(
        organization.data.id,
        { name },
        organization.etag,
      );
    },

    onSuccess: () => {
      enqueueSnackbar({
        key: `update_organization_success_${Date.now()}`,
        message: t('update_organization_success', {
          oldName: organization?.data.name,
          newName: name,
        }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['organizations'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['organization', { organizationId: organization?.data.id }],
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'update_organization_decrepit',
          message: t('update_organization_decrepit', {
            name: organization?.data.name,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({
                    queryKey: [
                      'organization',
                      { organizationId: organization?.data.id },
                    ],
                  })
                  .then(() => {
                    closeSnackbar('update_organization_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `update_organization_fail_${Date.now()}`,
          message: t('update_organization_fail', {
            name: organization?.data.name,
          }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  const handleSubmit = useCallback<() => void>(() => {
    if (name !== organization?.data.name) {
      onUpdateOrganization();
    } else {
      handleClose();
    }
  }, [name, organization?.data.name, onUpdateOrganization, handleClose]);

  useEffect(() => {
    if (organization?.data.name) setName(organization?.data.name);
  }, [organization?.data.name]);

  return (
    <AppDialog
      open={!!organization}
      onClose={handleClose}
      title={t('update_organization')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={<SubmitIcon />}
          disabled={isUpdating}
          fullWidth
        >
          {t('update')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <AppTextForm
          label={t('name')}
          value={name ?? ''}
          onChange={setName}
          componentProps={{ disabled: name === undefined }}
          helperText={t('update_organization_name_helper')}
          error={name?.length === 0}
          errorText={t('validations:update_organization.name')}
        />
      </Stack>
    </AppDialog>
  );
};
