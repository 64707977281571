import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { AppTable, TableColumn, TableData } from 'components/AppTable';
import { AppPresetPreview } from 'components/AppPresetPreview';
import {
  CreateHeatBalancePresetDialog,
  CreateJumpSafePresetDialog,
  CreateMeltPresetDialog,
  CreateSpatterSafePresetDialog,
  CreateStartHeatPresetDialog,
} from 'components/CreatePresetDialog';
import {
  ProcessSteps,
  getHeatBalancePresets,
  getJumpSafePresets,
  getMeltPresets,
  getSpatterSafePresets,
  getStartHeatPresets,
  getUsers,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

const mapProcessStepQueryKey: Record<keyof ProcessSteps, string> = {
  startHeat: 'start-heat-presets',
  spatterSafe: 'spatter-safe-presets',
  jumpSafe: 'jump-safe-presets',
  melt: 'melt-presets',
  heatBalance: 'heat-balance-presets',
};

const processStepsSpecifics = {
  startHeat: {
    getPresets: getStartHeatPresets,
    CreatePresetDialog: CreateStartHeatPresetDialog,
  },
  jumpSafe: {
    getPresets: getJumpSafePresets,
    CreatePresetDialog: CreateJumpSafePresetDialog,
  },
  spatterSafe: {
    getPresets: getSpatterSafePresets,
    CreatePresetDialog: CreateSpatterSafePresetDialog,
  },
  melt: {
    getPresets: getMeltPresets,
    CreatePresetDialog: CreateMeltPresetDialog,
  },
  heatBalance: {
    getPresets: getHeatBalancePresets,
    CreatePresetDialog: CreateHeatBalancePresetDialog,
  },
};

const tableColumns: TableColumn[] = [
  { name: 'name', label: 'name' },
  { name: 'material', label: 'material' },
  { name: 'createdBy/email', label: 'creator' },
  { name: 'createdDate', label: 'created' },
  { name: 'updatedDate', label: 'last_modified' },
];

const isColumnName = (sortBy: string | undefined): sortBy is string =>
  tableColumns.some(({ name }) => name === sortBy);
const isDirection = (sortDir: string | undefined): sortDir is 'asc' | 'desc' =>
  sortDir === 'asc' || sortDir === 'desc';

interface Props {
  processStep: keyof ProcessSteps;
}

export const Presets: FC<Props> = ({ processStep }) => {
  const { t } = useTranslation();
  const [
    { rowId: presetId, pageSize, currentPage, sortBy, sortDir, search },
    { updateSearchParam, deleteSearchParam, deleteSearchParams },
  ] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [createDialog, setCreateDialog] = useState(false);

  const [activeFilters, setActiveFilters] = useState<
    Partial<Record<'createdBy/email', string[]>>
  >({});

  const { getPresets, CreatePresetDialog } = useMemo(
    () => processStepsSpecifics[processStep],
    [processStep],
  );

  const params = useMemo(
    () => ({
      size: pageSize ? +pageSize : 25,
      page: currentPage ? +currentPage : 1,
      sort:
        isColumnName(sortBy) && isDirection(sortDir)
          ? { columnName: sortBy, direction: sortDir }
          : null,
      search: search?.length ? search : undefined,
      filters: activeFilters,
    }),
    [activeFilters, currentPage, pageSize, search, sortBy, sortDir],
  );

  const {
    data: presets,
    refetch,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    isError,
    isRefetchError,
  } = useQuery({
    queryKey: [mapProcessStepQueryKey[processStep], params],
    queryFn: () =>
      getPresets({
        top: params.size,
        skip: (params.page - 1) * params.size,
        orderBy: params.sort
          ? `${params.sort.columnName} ${params.sort.direction}`
          : 'createdDate desc',
        filter: {
          'tolower(name)': params.search
            ? { contains: params.search.toLowerCase() }
            : undefined,
          ...Object.entries(params.filters).reduce(
            (prevValue, [key, value]) =>
              value.length
                ? Object.assign(prevValue, { [key]: { in: value } })
                : prevValue,
            {},
          ),
        },
      }),
    placeholderData: keepPreviousData,
    staleTime: 0,
  });

  useEffect(() => {
    if (isError || isRefetchError)
      enqueueSnackbar({
        key: `get_presets_fail_${Date.now()}`,
        message: t('get_presets_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isError, isRefetchError, t]);

  const { data: users, isError: isUserError } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });

  useEffect(() => {
    if (isUserError)
      enqueueSnackbar({
        key: `get_users_fail_${Date.now()}`,
        message: t('get_users_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isUserError, t]);

  const usersFilterOptions = useMemo<string[]>(
    () => users?.values.map(({ email }) => email) ?? [],
    [users?.values],
  );

  const tableData = useMemo<TableData>(
    () => ({
      rows:
        (presets?.values ?? []).map(
          ({ id, name, material, createdBy, createdDate, updatedDate }) => ({
            id: id,
            hover: true,
            selected: presetId === id,
            onClick: () => updateSearchParam(['rowId', id]),
            sx: { cursor: 'pointer' },
            values: [
              { value: name },
              { value: material },
              { value: createdBy.email },
              { value: t('dateTime', { value: new Date(createdDate) }) },
              { value: t('dateTime', { value: new Date(updatedDate) }) },
            ],
          }),
        ) ?? [],
    }),
    [presets, presetId, t, updateSearchParam],
  );

  useEffect(() => {
    if (
      (!!sortBy || !!sortDir) &&
      (!isColumnName(sortBy) || !isDirection(sortDir))
    )
      deleteSearchParams(['sortBy', 'sortDir']);
  }, [deleteSearchParams, sortBy, sortDir]);

  return (
    <>
      <AppTable
        columns={tableColumns}
        data={tableData}
        name={t('presets')}
        noDataText={
          isError || isRefetchError
            ? t('presets_table_no_data_error')
            : t('presets_table_no_data')
        }
        sx={{ height: 1, m: 2 }}
        tableProps={{ stickyHeader: true }}
        headerProps={{
          add: {
            tooltip: t('create_preset_helper'),
            onClick: () => setCreateDialog(true),
          },
          filter: {
            filters: [
              {
                title: 'createdBy/email',
                label: 'creator',
                options: usersFilterOptions.map((value) => ({ value })),
              },
            ],
            activeFilters,
            onFilter: setActiveFilters,
          },
          search: {
            searchPhrase: params.search ?? '',
            onSearch: (value) =>
              value
                ? updateSearchParam(['search', value])
                : deleteSearchParam('search'),
          },
        }}
        pagination={{
          pageSize: params.size,
          currentPage: params.page,
          totalCount: presets?.count,
        }}
        sort={params.sort}
        fetchDate={new Date(dataUpdatedAt)}
        isError={isError}
        isRefetchError={isRefetchError}
        isLoading={isLoading}
        isRefetching={isRefetching}
        refetchData={() => void refetch()}
      />
      <AppPresetPreview processStep={processStep} />
      <CreatePresetDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
      />
    </>
  );
};
