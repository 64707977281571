import { FC, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useProgress } from '@react-three/drei';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';

import {
  App3DCanvas,
  App3DViewSettings,
  App3DViewSettingsProps,
} from 'components/App3DView';
import { Coordinates, ModelPreview, getModelFile } from 'api';
import { useSnackbar } from 'hooks';
import { ModelPreviewSettings } from 'slices/editorSlice';

interface Props {
  model: ModelPreview | undefined;
  settings?: Omit<App3DViewSettingsProps, 'onResetCamera'>;
  modelTransform?: Coordinates;
  modelPreviewSettings: ModelPreviewSettings;
}

export const App3DView: FC<Props> = ({
  model,
  settings,
  modelTransform,
  modelPreviewSettings,
}) => {
  const { progress, loaded, total } = useProgress();
  const canvasRef = useRef<{ resetCamera: () => void }>(null);
  const [cursor, setCursor] = useState<'grab' | 'grabbing'>('grab');
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onModelValidationFail = useCallback(
    () =>
      enqueueSnackbar({
        key: 'model_loaded_with_warnings',
        message: t('model_loaded_with_warnings'),
        variant: 'warning',
        persist: true,
      }),
    [enqueueSnackbar, t],
  );

  const onModelValidationFailCleanup = useCallback(
    () => closeSnackbar('model_loaded_with_warnings'),
    [closeSnackbar],
  );

  const { data, isError } = useQuery({
    queryKey: ['model-file', model?.id],
    queryFn: () => getModelFile(model?.id ?? ''),
    enabled: !!model,
  });

  useEffect(() => {
    if (isError)
      enqueueSnackbar({
        key: 'get_model_file_fail',
        message: t('get_model_file_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isError, t]);

  return (
    <Box
      component="div"
      sx={{ height: 1, width: 1, cursor }}
      onMouseEnter={() => setCursor('grab')}
      onMouseUp={() => setCursor('grab')}
      onMouseDown={() => setCursor('grabbing')}
    >
      <Suspense
        fallback={
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            width={1}
            height={1}
          >
            <Box component="div" sx={{ width: 3 / 4, position: 'relative' }}>
              <LinearProgress value={progress} variant="determinate" />
              <Typography
                sx={{
                  width: 1,
                  position: 'absolute',
                  top: 10,
                  fontWeight: 100,
                }}
                align="center"
                variant="h5"
                color="textSecondary"
              >
                {t('3d_model_rendering_progress', { progress, loaded, total })}
              </Typography>
            </Box>
          </Stack>
        }
      >
        <>
          <App3DCanvas
            data={data}
            objects={model?.sliceInfo.objects}
            ref={canvasRef}
            modelValidationCallbacks={{
              onFail: onModelValidationFail,
              onFailCleanup: onModelValidationFailCleanup,
            }}
            modelPreviewSettings={modelPreviewSettings}
            transformValue={
              modelTransform ? [modelTransform.x, modelTransform.y] : [0, 0]
            }
          />
          {!!settings && (
            <App3DViewSettings
              onResetCamera={() => {
                if (canvasRef.current) {
                  canvasRef.current.resetCamera();
                }
              }}
              {...settings}
            />
          )}
        </>
      </Suspense>
    </Box>
  );
};
