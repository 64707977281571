import {
  FC,
  useMemo,
  useState,
  createRef,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm, AppToggleButtonForm } from 'components/AppFormControl';
import { createUser, CreateUser, ErrorResponse, UserRole } from 'api';
import { useSnackbar } from 'hooks';
import { validateNewPassword } from 'utils';

const SELECTABLE_ROLES: string[] = [UserRole.OrganizationAdmin, UserRole.User];

const isUserRole = (arg: unknown): arg is UserRole =>
  typeof arg === 'string' && SELECTABLE_ROLES.includes(arg);

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateUserDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [role, setRole] = useState<UserRole>(UserRole.User);

  const [validationError, setValidationError] = useState<(keyof CreateUser)[]>(
    [],
  );
  const refs = useMemo<Record<keyof CreateUser, RefObject<HTMLInputElement>>>(
    () => ({
      email: createRef<HTMLInputElement>(),
      password: createRef<HTMLInputElement>(),
      type: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const handleResetForm = useCallback<() => void>(() => {
    setEmail('');
    setPassword('');
    setRole(UserRole.User);
  }, []);

  const handleClose = useCallback<() => void>(() => {
    handleResetForm();
    setValidationError([]);
    onClose();
  }, [handleResetForm, onClose]);

  const { mutate: onCreateUser, isPending: isCreating } = useMutation({
    mutationFn: () => createUser({ email, password, type: role }),

    onSuccess: ({ data }) => {
      enqueueSnackbar({
        key: `create_user_success_${Date.now()}`,
        message: t('create_user_success', { email: data.email }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      enqueueSnackbar({
        key: `create_user_fail_${Date.now()}`,
        message: t('create_user_fail'),
        variant: 'error',
        persist: true,
      });
      if (response?.status === 400) {
        if (response.data.errors) {
          const errorKeys = Object.keys(response.data.errors)
            .map<keyof CreateUser | undefined>((key) => {
              if (key === 'Email') return 'email';
              if (key === 'Password') return 'password';
              if (key === 'Type') return 'type';
              return undefined;
            })
            .filter((key): key is keyof CreateUser => !!key);
          setValidationError(errorKeys);
          refs[errorKeys[0]]?.current?.focus();
        }
      }
    },
  });

  const handleCreateUser = useCallback<() => void>(() => {
    if (!validateNewPassword(password)) setValidationError(['password']);
    else onCreateUser();
  }, [onCreateUser, password]);

  useEffect(() => {
    setValidationError([]);
  }, [email, password, role]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('new_user')}
      actions={
        <Button
          onClick={handleCreateUser}
          variant="contained"
          disabled={isCreating}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {t('create')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <AppToggleButtonForm
          ref={refs.type}
          label={t('role')}
          value={role}
          onChange={(value) => {
            if (isUserRole(value)) setRole(value);
          }}
          buttons={SELECTABLE_ROLES.map((value) => ({
            value,
            text: t(`userRole:${value}`),
          }))}
          helperText={t('create_user_role_helper')}
          error={validationError.includes('type')}
          errorText={t('validations:create_user.role')}
        />
        <AppTextForm
          ref={refs.email}
          label={t('email')}
          value={email}
          onChange={setEmail}
          helperText={t('create_user_email_helper')}
          error={validationError.includes('email')}
          errorText={t('validations:create_user.email')}
          componentProps={{ inputRef: refs.password }}
        />
        <AppTextForm
          label={t('password')}
          value={password}
          onChange={setPassword}
          helperText={t('create_user_password_helper')}
          error={validationError.includes('password')}
          errorText={t('validations:create_user.password')}
          componentProps={{
            type: 'password',
            autoComplete: 'new-password',
            inputRef: refs.password,
          }}
        />
      </Stack>
    </AppDialog>
  );
};
