import { useRef, FC, ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, AppBar, Toolbar } from '@mui/material';
import {
  Menu as MenuIcon,
  MenuOpen as CollapseIcon,
} from '@mui/icons-material';

import { AppUserQuickSettings } from 'components/AppUserQuickSettings';
import { AppButton } from 'components/AppButton';
import { ReactComponent as PixelmeltLogo } from 'assets/logo/pixelmelt.svg';
import { ReactComponent as PixelmeltAltLogo } from 'assets/logo/pixelmelt-alt.svg';

interface Props {
  altLogo?: boolean;
  sidebarExpanded?: boolean;
  onMenuClick?: () => void;
  customHeaderActions?: (anchor: RefObject<HTMLDivElement>) => ReactNode;
  children?: ReactNode;
}

export const AppHeader: FC<Props> = ({
  altLogo,
  sidebarExpanded,
  onMenuClick,
  customHeaderActions = () => undefined,
  children,
}) => {
  const { t } = useTranslation();
  const headerEl = useRef<HTMLDivElement>(null);

  return (
    <>
      <AppBar
        ref={headerEl}
        position="static"
        sx={{ zIndex: ({ zIndex }) => zIndex.drawer + 1 }}
      >
        <Stack component={Toolbar} alignItems="center" direction="row">
          {onMenuClick && (
            <AppButton
              title={t('toggle_menu')}
              buttonProps={{
                onClick: onMenuClick,
              }}
            >
              {sidebarExpanded ? <CollapseIcon /> : <MenuIcon />}
            </AppButton>
          )}
          <Stack
            justifyContent="center"
            pl={2}
            width={175}
            sx={{ transition: 'width 0.5s' }}
          >
            {altLogo ? (
              <PixelmeltAltLogo style={{ width: '100%', height: 'auto' }} />
            ) : (
              <PixelmeltLogo style={{ width: '100%', height: 'auto' }} />
            )}
          </Stack>
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            mx={2}
          >
            {customHeaderActions(headerEl)}
            <AppUserQuickSettings anchor={headerEl} />
          </Stack>
        </Stack>
      </AppBar>
      {children}
    </>
  );
};
