import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { AppDeletionDialog } from 'components/AppDialog';
import {
  deleteHeatBalancePreset,
  deleteJumpSafePreset,
  deleteMeltPreset,
  deleteSpatterSafePreset,
  deleteStartHeatPreset,
  ErrorResponse,
  getHeatBalancePreset,
  getJumpSafePreset,
  getMeltPreset,
  getSpatterSafePreset,
  getStartHeatPreset,
  ProcessSteps,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

const mapProcessStepQueryKey: Record<keyof ProcessSteps, string> = {
  startHeat: 'start-heat-preset',
  spatterSafe: 'spatter-safe-preset',
  jumpSafe: 'jump-safe-preset',
  melt: 'melt-preset',
  heatBalance: 'heat-balance-preset',
};

const processStepsActions = {
  startHeat: {
    deletePreset: deleteStartHeatPreset,
    getPreset: getStartHeatPreset,
  },
  jumpSafe: {
    deletePreset: deleteJumpSafePreset,
    getPreset: getJumpSafePreset,
  },
  spatterSafe: {
    deletePreset: deleteSpatterSafePreset,
    getPreset: getSpatterSafePreset,
  },
  melt: {
    deletePreset: deleteMeltPreset,
    getPreset: getMeltPreset,
  },
  heatBalance: {
    deletePreset: deleteHeatBalancePreset,
    getPreset: getHeatBalancePreset,
  },
};

interface Props {
  open: boolean;
  presetId: string;
  processStep: keyof ProcessSteps;
  onClose: () => void;
}

export const DeletePresetDialog: FC<Props> = ({
  open,
  presetId,
  processStep,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();

  const { deletePreset, getPreset } = useMemo(
    () => processStepsActions[processStep],
    [processStep],
  );

  const {
    data: preview,
    isLoading: isPreviewLoading,
    isRefetching: isPreviewRefetching,
    isError: isFetchPreviewError,
    isRefetchError: isRefetchPreviewError,
  } = useQuery({
    queryKey: [mapProcessStepQueryKey[processStep], { presetId }],
    queryFn: () => (presetId ? getPreset(presetId) : undefined),
  });

  useEffect(() => {
    if (isFetchPreviewError || isRefetchPreviewError) {
      enqueueSnackbar({
        key: `get_preset_fail_${Date.now()}`,
        message: t('get_preset_fail'),
        variant: 'error',
      });
      deleteSearchParam('rowId');
    }
  }, [
    deleteSearchParam,
    enqueueSnackbar,
    isFetchPreviewError,
    isRefetchPreviewError,
    t,
  ]);

  const { mutate: onDeletePreset, isPending: isDeleting } = useMutation({
    mutationFn: () => {
      if (!preview) throw new Error('preset does not exist');
      return deletePreset(preview.data.id, preview.etag);
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `delete_preset_success_${Date.now()}`,
        message: t('delete_preset_success', { name: preview?.data.name }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: [`${mapProcessStepQueryKey[processStep]}s`],
      });
      queryClient.removeQueries({
        queryKey: [mapProcessStepQueryKey[processStep], { presetId }],
      });
      deleteSearchParam('rowId');
      onClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'delete_preset_decrepit',
          message: t('delete_preset_decrepit', {
            name: preview?.data.name,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({ queryKey: ['preset', { presetId }] })
                  .then(() => {
                    closeSnackbar('delete_preset_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `delete_preset_fail_${Date.now()}`,
          message: t('delete_preset_fail', { name: preview?.data.name }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  return (
    <AppDeletionDialog
      open={open}
      onClose={onClose}
      title={t('delete_preset')}
      text={t('delete_preset_confirmation', { name: preview?.data.name })}
      onConfirm={onDeletePreset}
      confirmDisabled={isDeleting || isPreviewLoading || isPreviewRefetching}
    />
  );
};
