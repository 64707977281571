import { ComponentProps, FC, ReactNode, useRef } from 'react';
import { Paper, Box, Typography, Stack } from '@mui/material';

import {
  ProcessStepPanelActions,
  ProcessStepPanelActionsProps,
} from 'components/ProjectPanel';

interface Props {
  title: string;
  children: ReactNode;
  actions?: ProcessStepPanelActionsProps;
  options?: ReactNode;
  scale?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  rootProps?: ComponentProps<typeof Paper>;
}

export const ProjectPanel: FC<Props> = ({
  title,
  children,
  actions,
  options,
  scale = 1,
  rootProps = {},
}) => {
  const contentEl = useRef<HTMLDivElement | null>(null);

  return (
    <Stack
      component={Paper}
      flex={scale}
      minHeight={0}
      m={1}
      zIndex={({ zIndex }) => zIndex.drawer}
      bgcolor="background.default"
    >
      <Paper sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
        <Stack direction="row" alignItems="center" p={2}>
          <Typography variant="h3" pl={2} flex={1}>
            {title}
          </Typography>
          {options}
        </Stack>
      </Paper>
      <Box
        component="div"
        ref={contentEl}
        flex={1}
        p={2}
        overflow="hidden auto"
        {...rootProps}
      >
        {children}
      </Box>
      {actions && <ProcessStepPanelActions {...actions} />}
    </Stack>
  );
};
