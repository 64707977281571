import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState, PartitionQuery, extractPartitionIds } from 'utils';
import { Melt, Partition, PartitionSettings, Selection } from 'api/types';

export const defaultMelt: Melt = {
  spotSizeMicrometer: 250,
  beamPowerWatt: 600,
  gridSizeMillimeter: 0.1,
  gridOffsetPercent: 0,
  seeds: [],
  pointSpreadAlgName: '',
  dwellTimeAlgName: '',
  pointSpreadSettings: [],
  dwellTimeSettings: [],
};

export const initialState: PartitionSettings<Melt> = {
  selection: Selection.model,
  partitions: [],
  selectedPartitionIds: [],
};

const meltSlice = createSlice({
  name: 'melt',
  initialState,
  reducers: {
    loadMelt: (state, { payload }: PayloadAction<PartitionSettings<Melt>>) => {
      state.selection = payload.selection;
      state.partitions = payload.partitions;
      state.selectedPartitionIds = payload.selectedPartitionIds;
    },
    resetMeltPartitions: (state) => {
      state.partitions = initialState.partitions;
    },
    setMeltPartitionSelection: (
      state,
      { payload }: PayloadAction<Selection>,
    ) => {
      state.selection = payload;
    },
    setMeltPartitions: (
      state,
      { payload }: PayloadAction<Partition<Melt>[]>,
    ) => {
      state.partitions = payload;
      state.selectedPartitionIds = [];
    },
    setSelectedMeltPartitionIds: (
      state,
      { payload }: PayloadAction<PartitionQuery>,
    ) => {
      state.selectedPartitionIds = extractPartitionIds({
        query: payload,
        partitions: state.partitions,
        selectedPartitionIds: state.selectedPartitionIds,
      });
    },
    setDraft: (state, { payload }: PayloadAction<Melt>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: payload,
              },
            }
          : partition,
      );
    },
    saveDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
                saved: partition.parameters.draft,
              },
            }
          : partition,
      );
    },
    discardDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
              },
            }
          : partition,
      );
    },
  },
});

export const {
  loadMelt,
  resetMeltPartitions,
  setMeltPartitionSelection,
  setMeltPartitions,
  setSelectedMeltPartitionIds,
  setDraft,
  saveDraft,
  discardDraft,
} = meltSlice.actions;

export const selectMeltPartitionSelection = (state: RootState): Selection =>
  state.melt.selection;
export const selectMeltPartitions = (state: RootState): Partition<Melt>[] =>
  state.melt.partitions;
export const selectSelectedMeltPartitionIds = (state: RootState): string[] =>
  state.melt.selectedPartitionIds;

export const selectSelectedMeltPartitions = createSelector(
  selectMeltPartitions,
  selectSelectedMeltPartitionIds,
  (partitions, selectedPartitionIds): Partition<Melt>[] =>
    partitions.filter(({ id }) => selectedPartitionIds.includes(id)),
);
export const selectAllMeltPartitionsAreSaved = createSelector(
  selectMeltPartitions,
  (partitions): boolean =>
    partitions.every(({ parameters }) => !!parameters.saved || parameters.skip),
);
export const selectMeltDraftValues = createSelector(
  selectSelectedMeltPartitions,
  (selectedPartitions): Record<string, Melt | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.draft]),
    ),
);
export const selectMeltSavedValues = createSelector(
  selectSelectedMeltPartitions,
  (selectedPartitions): Record<string, Melt | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.saved]),
    ),
);

export default meltSlice.reducer;
