import { FC, useCallback, useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import {
  TouchAppRounded as SelectionIcon,
  BoltRounded as EnergyIcon,
} from '@mui/icons-material';

import { ReactComponent as ProjectIcon } from 'assets/icons/project.svg';
import { ReactComponent as StartHeatIcon } from 'assets/icons/start_heat.svg';
import { ReactComponent as JumpSafeIcon } from 'assets/icons/jump_safe.svg';
import { ReactComponent as SpatterSafeIcon } from 'assets/icons/spatter_safe.svg';
import { ReactComponent as MeltIcon } from 'assets/icons/melt.svg';
import { ReactComponent as HeatBalanceIcon } from 'assets/icons/heat_balance.svg';
import { ProjectMenu } from 'components/ProjectPanelMenu';
import {
  ProjectSettingsPanel,
  EnergyPanel,
  MeltPanel,
  SelectionPanel,
  StartHeatPanel,
  JumpSafePanel,
  SpatterSafePanel,
  HeatBalancePanel,
} from 'components/ProjectPanel';
import { useSelector, useDispatch, useKeyboardShortcut } from 'hooks';
import {
  PrimaryMenuTab,
  SecondaryMenuTab,
  selectPrimaryMenu,
  selectSecondaryMenu,
  setPrimaryMenu,
  setSecondaryMenu,
} from 'slices/editorSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

type Panel = FC<Props>;

export interface MenuItem<T> {
  name: T;
  Icon: FC;
  Panel: Panel;
}

type Menu =
  | { name: 'primary'; items: MenuItem<PrimaryMenuTab>[] }
  | { name: 'secondary'; items: MenuItem<SecondaryMenuTab>[] };

export const primaryMenu: Menu = {
  name: 'primary',
  items: [
    {
      name: PrimaryMenuTab.project,
      Icon: ProjectIcon,
      Panel: ProjectSettingsPanel,
    },
    {
      name: PrimaryMenuTab.start_heat,
      Icon: StartHeatIcon,
      Panel: StartHeatPanel,
    },
    {
      name: PrimaryMenuTab.jump_safe,
      Icon: JumpSafeIcon,
      Panel: JumpSafePanel,
    },
    {
      name: PrimaryMenuTab.spatter_safe,
      Icon: SpatterSafeIcon,
      Panel: SpatterSafePanel,
    },
    {
      name: PrimaryMenuTab.melt,
      Icon: MeltIcon,
      Panel: MeltPanel,
    },
    {
      name: PrimaryMenuTab.heat_balance,
      Icon: HeatBalanceIcon,
      Panel: HeatBalancePanel,
    },
  ],
};

export const secondaryMenu: Menu = {
  name: 'secondary',
  items: [
    {
      name: SecondaryMenuTab.selection,
      Icon: SelectionIcon,
      Panel: SelectionPanel,
    },
    {
      name: SecondaryMenuTab.energy,
      Icon: EnergyIcon,
      Panel: EnergyPanel,
    },
  ],
};

const isPrimaryMenuTab = (arg: string): arg is PrimaryMenuTab =>
  Object.keys(PrimaryMenuTab).includes(arg);
const isSecondaryMenuTab = (arg: string): arg is SecondaryMenuTab =>
  Object.keys(SecondaryMenuTab).includes(arg);

export const ProjectPanelMenu: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const mediumScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('md'),
  );
  const dispatch = useDispatch();
  const activePrimaryMenu = useSelector(selectPrimaryMenu);
  const activeSecondaryMenu = useSelector(selectSecondaryMenu);

  const activeTabs = useMemo(
    () => [
      ...secondaryMenu.items.filter(
        ({ name }) =>
          activeSecondaryMenu.open &&
          activeSecondaryMenu.windows.includes(name),
      ),
      ...primaryMenu.items.filter(
        ({ name }) =>
          activePrimaryMenu.open && activePrimaryMenu.window === name,
      ),
    ],
    [activePrimaryMenu, activeSecondaryMenu],
  );

  const handleMenuTabClick = useCallback(
    (tab: PrimaryMenuTab | SecondaryMenuTab) => {
      if (isPrimaryMenuTab(tab)) dispatch(setPrimaryMenu({ tab }));
      if (isSecondaryMenuTab(tab)) dispatch(setSecondaryMenu({ tab }));
    },
    [dispatch],
  );

  useKeyboardShortcut({ key: '3', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: PrimaryMenuTab.project }));
  });
  useKeyboardShortcut({ key: '4', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: PrimaryMenuTab.start_heat }));
  });
  useKeyboardShortcut({ key: '5', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: PrimaryMenuTab.jump_safe }));
  });
  useKeyboardShortcut({ key: '6', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: PrimaryMenuTab.spatter_safe }));
  });
  useKeyboardShortcut({ key: '7', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: PrimaryMenuTab.melt }));
  });
  useKeyboardShortcut({ key: '8', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: PrimaryMenuTab.heat_balance }));
  });
  useKeyboardShortcut({ key: '9', ctrl: true }, () => {
    dispatch(setSecondaryMenu({ tab: SecondaryMenuTab.selection }));
  });
  useKeyboardShortcut({ key: '0', ctrl: true }, () => {
    dispatch(setSecondaryMenu({ tab: SecondaryMenuTab.energy }));
  });
  useKeyboardShortcut({ key: 't', ctrl: true }, () => {
    dispatch(
      setPrimaryMenu({
        open: !activePrimaryMenu.open && !activeSecondaryMenu.open,
      }),
    );
    dispatch(
      setSecondaryMenu({
        open: !activePrimaryMenu.open && !activeSecondaryMenu.open,
      }),
    );
  });

  return mediumScreenSize ? (
    <ProjectMenu
      menuGroups={[secondaryMenu.items, primaryMenu.items]}
      activeTabs={activeTabs}
      onMenuTabClick={handleMenuTabClick}
      userHasWritePermission={userHasWritePermission}
      generateHasInitiated={generateHasInitiated}
    />
  ) : (
    <>
      <ProjectMenu
        menuGroups={[primaryMenu.items]}
        activeTabs={activeTabs}
        onMenuTabClick={handleMenuTabClick}
        userHasWritePermission={userHasWritePermission}
        generateHasInitiated={generateHasInitiated}
      />
      <ProjectMenu
        menuGroups={[secondaryMenu.items]}
        activeTabs={activeTabs}
        onMenuTabClick={handleMenuTabClick}
        align="right"
        userHasWritePermission={userHasWritePermission}
        generateHasInitiated={generateHasInitiated}
      />
    </>
  );
};
