import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { useSnackbar } from 'hooks';
import {
  ErrorResponse,
  ProcessStepsResponseWithEtag,
  ProjectSettingsResponseWithEtag,
  updateProjectProcessSteps,
  updateProjectSettings,
} from 'api';
import { getProjectReduxState } from 'utils';

type OnSaveProjectCallback = () => Promise<{
  settings: ProjectSettingsResponseWithEtag;
  steps: ProcessStepsResponseWithEtag;
}>;

interface UseSaveProject {
  onSaveProject: OnSaveProjectCallback;
  isLoading: boolean;
}

export const useSaveProject = (): UseSaveProject => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { projectId } = useParams<'projectId'>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: projectSettings, isFetching: isFetchingProjectSettings } =
    useQuery<ProjectSettingsResponseWithEtag>({
      queryKey: ['project-settings', { projectId }],
      enabled: !!projectId,
    });

  const {
    mutateAsync: mutateProjectSettings,
    isPending: isMutatingProjectSettings,
  } = useMutation({
    mutationKey: ['project-settings', { projectId }],
    mutationFn: () => {
      if (!projectSettings) throw new Error('project settings does not exist');
      const reduxProject = getProjectReduxState();
      return updateProjectSettings(
        projectSettings.data.id,
        reduxProject.projectSettings,
        projectSettings.etag,
      );
    },
    onSuccess: ({ data, etag }) => {
      void queryClient.invalidateQueries({
        queryKey: ['project', { projectId: data.id }],
      });
      queryClient.setQueryData<ProjectSettingsResponseWithEtag>(
        ['project-settings', { projectId: data.id }],
        { data, etag },
      );
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'save_project_decrepit',
          message: t('save_project_decrepit'),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({
                    queryKey: ['project-settings', { projectId }],
                  })
                  .then(() => {
                    closeSnackbar('save_project_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `save_project_fail_${Date.now()}`,
          message: t('save_project_fail'),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  const { data: processSteps, isFetching: isFetchingProcessSteps } =
    useQuery<ProcessStepsResponseWithEtag>({
      queryKey: ['process-steps', { projectId }],
      enabled: !!projectId,
    });

  const { mutateAsync: mutateProcessSteps, isPending: isMutatingProcessSteps } =
    useMutation({
      mutationKey: ['process-steeps', { projectId }],
      mutationFn: () => {
        if (!processSteps || !projectId)
          throw new Error('project settings or process steps do not exist');
        const reduxProject = getProjectReduxState();
        return updateProjectProcessSteps(
          projectId,
          reduxProject.processSteps,
          processSteps.etag,
        );
      },
      onSuccess: ({ data, etag }) => {
        enqueueSnackbar({
          key: `save_project_success_${Date.now()}`,
          message: t('save_project_success'),
          variant: 'success',
        });
        void queryClient.invalidateQueries({
          queryKey: ['process-steps', { projectId }],
        });
        queryClient.setQueryData<ProcessStepsResponseWithEtag>(
          ['process-step', { projectId }],
          { data, etag },
        );
      },
      onError: ({ response }: AxiosError<ErrorResponse>) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'save_process_steps_decrepit',
            message: t('save_process_steps_decrepit'),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  void queryClient
                    .refetchQueries({
                      queryKey: ['process_steps', { projectId }],
                    })
                    .then(() => {
                      closeSnackbar('save_process_steps_decrepit');
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `save_process_steps_fail_${Date.now()}`,
            message: t('save_process_steps_fail'),
            variant: 'error',
            persist: true,
          });
        }
      },
    });

  const onSaveProject = useCallback<OnSaveProjectCallback>(
    () =>
      new Promise((resolve, reject) => {
        if (isMutatingProjectSettings || isFetchingProjectSettings) {
          enqueueSnackbar({
            key: 'save_project_pending',
            message: t('save_project_pending'),
            variant: 'info',
          });
          reject();
        } else if (isMutatingProcessSteps || isFetchingProcessSteps) {
          enqueueSnackbar({
            key: 'save_process_steps_pending',
            message: t('save_process_steps_pending'),
            variant: 'info',
          });
          reject();
        } else {
          mutateProjectSettings()
            .then((settings) => {
              mutateProcessSteps()
                .then((steps) => resolve({ settings, steps }))
                .catch(reject);
            })
            .catch(reject);
        }
      }),
    [
      enqueueSnackbar,
      isFetchingProcessSteps,
      isFetchingProjectSettings,
      isMutatingProcessSteps,
      isMutatingProjectSettings,
      mutateProcessSteps,
      mutateProjectSettings,
      t,
    ],
  );

  return useMemo<UseSaveProject>(
    () => ({
      onSaveProject,
      isLoading: isMutatingProjectSettings || isMutatingProcessSteps,
    }),
    [isMutatingProcessSteps, isMutatingProjectSettings, onSaveProject],
  );
};
