import { useMemo, useCallback, ReactNode, FC } from 'react';
import { Object3D, Vector3 } from 'three';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader';
import { useLoader } from '@react-three/fiber';

import { ProjectMesh } from 'components/ProjectMesh';
import { base64ToDataUrl, subtract } from 'utils';
import {
  ModelValidationCallbacks,
  useSelector,
  useValidate3DModel,
} from 'hooks';
import { SlicedObject } from 'api';
import { selectModelHighlights } from 'slices/editorSlice';

interface Props {
  data: string;
  transformValue: [number, number];
  objects: SlicedObject[] | undefined;
  highlightSelected: boolean;
  highlightUnsliced: boolean;
  modelValidationCallbacks: ModelValidationCallbacks;
}

export const App3DObject: FC<Props> = ({
  data,
  transformValue,
  objects,
  highlightSelected,
  highlightUnsliced,
  modelValidationCallbacks,
}) => {
  const model = useLoader(ThreeMFLoader, base64ToDataUrl(data, '3mf'));
  const modelHighlights = useSelector(selectModelHighlights);

  useValidate3DModel({ model, ...modelValidationCallbacks });

  const objectIds = useMemo<number[]>(
    () => objects?.map(({ id }) => id) ?? [],
    [objects],
  );

  const meshIds = useMemo<number[]>(() => {
    const result: number[] = [];
    const extractMeshIds = (target: Object3D): void => {
      const { children, type, id } = target;
      if (type === 'Mesh') {
        result.push(id);
      }
      if (type === 'Group') {
        children.length ? children.forEach(extractMeshIds) : result.push(id);
      }
    };
    extractMeshIds(model);
    return result.sort((a, b) => a - b);
  }, [model]);

  const getMeshHeight = useCallback<
    (object: SlicedObject | undefined) => {
      startZMillimeter: number;
      endZMillimeter: number;
    }
  >(
    (object) =>
      highlightUnsliced && object
        ? {
            startZMillimeter: subtract(
              object.startZMillimeter,
              object.layerThicknessMillimeter,
            ),
            endZMillimeter: object.endZMillimeter,
          }
        : { startZMillimeter: -Infinity, endZMillimeter: Infinity },
    [highlightUnsliced],
  );

  const getMeshHighlights = useCallback<
    (meshId: number | undefined) => {
      startZMillimeter: number[];
      endZMillimeter: number[];
      size: number;
    }
  >(
    (meshId) => {
      if (highlightSelected && meshId && objectIds.length === meshIds.length) {
        const meshHighlights = modelHighlights.filter(
          ({ objectId }) => objectId === null || objectId === meshId,
        );
        return {
          startZMillimeter: meshHighlights.map(
            ({ startZMillimeter }) => startZMillimeter,
          ),
          endZMillimeter: meshHighlights.map(
            ({ endZMillimeter }) => endZMillimeter,
          ),
          size: meshHighlights.length,
        };
      }
      return { startZMillimeter: [], endZMillimeter: [], size: 0 };
    },
    [highlightSelected, objectIds.length, meshIds.length, modelHighlights],
  );

  const getPosition = useCallback<(target: Object3D) => Vector3>(
    (target) =>
      !target.parent
        ? new Vector3(
            ...Object.values(target.position).map((point: number, index) =>
              index !== 2 ? point + transformValue[index] - 50 : point,
            ),
          )
        : target.position,
    [transformValue],
  );

  const meshObjects = useMemo<ReactNode>(() => {
    const extractObjects = (target: Object3D): ReactNode => {
      const { children, type, ...props } = target;
      if (type === 'Mesh') {
        const index = meshIds.indexOf(target.id);
        return (
          <ProjectMesh
            key={target.uuid}
            mesh={target}
            height={getMeshHeight(objects?.at(index))}
            highlights={getMeshHighlights(objectIds.at(index))}
          />
        );
      }
      if (type === 'Group') {
        return (
          <group key={target.uuid} {...props} position={getPosition(target)}>
            {children.map((child: Object3D) => extractObjects(child))}
          </group>
        );
      }
    };
    return extractObjects(model);
  }, [
    getMeshHeight,
    getMeshHighlights,
    getPosition,
    meshIds,
    model,
    objectIds,
    objects,
  ]);

  return <>{meshObjects}</>;
};
