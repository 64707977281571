import {
  FC,
  useMemo,
  useState,
  createRef,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button } from '@mui/material';
import { CheckRounded as SubmitIcon } from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { useAuthentication, useSelector, useSnackbar } from 'hooks';
import { validateNewPassword } from 'utils';
import { selectUser } from 'slices/userSlice';

type ChangePasswordKeys = 'oldPassword' | 'newPassword' | 'confirmPassword';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ChangePasswordDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { changePassword } = useAuthentication();
  const user = useSelector(selectUser);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [validationError, setValidationError] = useState<ChangePasswordKeys[]>(
    [],
  );
  const refs = useMemo<Record<ChangePasswordKeys, RefObject<HTMLInputElement>>>(
    () => ({
      oldPassword: createRef<HTMLInputElement>(),
      newPassword: createRef<HTMLInputElement>(),
      confirmPassword: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const handleResetForm = useCallback<() => void>(() => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }, []);

  const handleClose = useCallback<() => void>(() => {
    handleResetForm();
    setValidationError([]);
    onClose();
  }, [handleResetForm, onClose]);

  const handleSubmit = useCallback<() => void>(() => {
    setValidationError([]);
    if (!user) return;
    if (!oldPassword.length) {
      setValidationError((prevState) => [...prevState, 'oldPassword']);
      refs.oldPassword.current?.focus();
    } else if (
      !validateNewPassword(newPassword) ||
      oldPassword === newPassword
    ) {
      setValidationError((prevState) => [...prevState, 'newPassword']);
      refs.newPassword.current?.focus();
    } else if (confirmPassword !== newPassword) {
      setValidationError((prevState) => [...prevState, 'confirmPassword']);
      refs.confirmPassword.current?.focus();
    } else {
      changePassword({ email: user.email, oldPassword, newPassword })
        .then(() => {
          enqueueSnackbar({
            key: `change_password_success_${Date.now()}`,
            message: t('change_password_success'),
            variant: 'success',
          });
          handleClose();
        })
        .catch(() => {
          enqueueSnackbar({
            key: `change_password_fail_${Date.now()}`,
            message: t('change_password_fail'),
            variant: 'error',
          });
        });
    }
  }, [
    oldPassword,
    newPassword,
    confirmPassword,
    refs,
    changePassword,
    user,
    enqueueSnackbar,
    t,
    handleClose,
  ]);

  useEffect(() => {
    setValidationError([]);
  }, [oldPassword, newPassword, confirmPassword]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('change_password')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          startIcon={<SubmitIcon />}
          fullWidth
        >
          {t('save_password')}
        </Button>
      }
    >
      <Stack spacing={2}>
        <AppTextForm
          label={t('current_password')}
          helperText={t('current_password_helper')}
          value={oldPassword}
          onChange={setOldPassword}
          componentProps={{
            type: 'password',
            autoComplete: 'current-password',
            inputRef: refs.oldPassword,
          }}
          error={validationError.includes('oldPassword')}
          errorText={t('validations:change_password.oldPassword')}
        />
        <AppTextForm
          label={t('new_password')}
          helperText={t('new_password_helper')}
          value={newPassword}
          onChange={setNewPassword}
          componentProps={{
            type: 'password',
            autoComplete: 'new-password',
            inputRef: refs.newPassword,
          }}
          error={validationError.includes('newPassword')}
          errorText={t('validations:change_password.newPassword')}
        />
        <AppTextForm
          label={t('confirm_password')}
          helperText={t('confirm_password_helper')}
          value={confirmPassword}
          onChange={setConfirmPassword}
          componentProps={{
            type: 'password',
            autoComplete: 'new-password',
            inputRef: refs.confirmPassword,
          }}
          error={validationError.includes('confirmPassword')}
          errorText={t('validations:change_password.confirmPassword')}
        />
      </Stack>
    </AppDialog>
  );
};
