import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TuneRounded as LoadIcon,
  SaveRounded as SaveIcon,
} from '@mui/icons-material';

import { ProjectPanel } from 'components/ProjectPanel';
import { SpatterSafeForm } from 'components/AppProcessStepForms';
import { LoadSpatterSafePresetDialog } from 'components/AppLoadPresetDialogs';
import { AppIconButton } from 'components/AppIconButton';
import { AppConfirmationDialog } from 'components/AppDialog';
import { CreateSpatterSafePresetDialog as CreatePresetDialog } from 'components/CreatePresetDialog';
import { SpatterSafe } from 'api';
import {
  useSelector,
  useDispatch,
  useKeyboardShortcut,
  useSnackbar,
} from 'hooks';
import {
  setDraft,
  saveDraft,
  discardDraft,
  defaultSpatterSafe,
  selectSpatterSafeDraftValues,
  selectSpatterSafeSavedValues,
  selectSelectedSpatterSafePartitionIds,
  selectSkipSpatterSafe,
  setSkipSpatterSafe,
} from 'slices/spatterSafeSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const SpatterSafePanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selectPartitionIds = useSelector(selectSelectedSpatterSafePartitionIds);
  const draftValues = useSelector(selectSpatterSafeDraftValues);
  const savedValues = useSelector(selectSpatterSafeSavedValues);
  const skip = useSelector(selectSkipSpatterSafe);

  const [loadPresetDialog, setLoadPresetDialog] = useState(false);
  const [savePresetDialog, setSavePresetDialog] = useState(false);
  const [discardDraftDialog, setDiscardDraftDialog] = useState(false);

  const values = useMemo(
    (): SpatterSafe =>
      Object.values(draftValues)
        .filter((v) => v !== undefined)
        .at(0) ??
      Object.values(savedValues)
        .filter((v) => v !== undefined)
        .at(0) ??
      defaultSpatterSafe,
    [draftValues, savedValues],
  );

  const noPartitionSelected = useMemo(
    (): boolean => !selectPartitionIds.length,
    [selectPartitionIds.length],
  );

  const disabled = useMemo(
    (): boolean =>
      Object.values(skip).some((v) => v) ||
      noPartitionSelected ||
      !userHasWritePermission ||
      generateHasInitiated,
    [skip, noPartitionSelected, userHasWritePermission, generateHasInitiated],
  );

  const saveDraftDisabled = useMemo(
    (): boolean =>
      noPartitionSelected ||
      Object.values(draftValues).some(
        (v) => !v?.pointSpreadAlgName || !v.dwellTimeAlgName,
      ),
    [draftValues, noPartitionSelected],
  );

  const discardDraftDisabled = useMemo(
    (): boolean =>
      noPartitionSelected || Object.values(draftValues).some((v) => !v),
    [draftValues, noPartitionSelected],
  );

  const isVarying = useCallback(
    (key: keyof SpatterSafe, position?: number): boolean =>
      !Object.entries(draftValues)
        .map(([partitionId, value]) =>
          value ? value[key] : savedValues[partitionId]?.[key],
        )
        .filter(
          (value, index, array) =>
            array.findIndex((v) => v === value) === index,
        )
        .every((value, _, [first]) =>
          Array.isArray(first) && Array.isArray(value)
            ? position
              ? first.at(position) === value.at(position)
              : value.every(
                  (v, i) =>
                    first[i] === v ||
                    (Number.isNaN(first[i]) && Number.isNaN(v)),
                )
            : first === value,
        ),
    [draftValues, savedValues],
  );

  const handleUpdateDraft = useCallback(
    (parameters: SpatterSafe): void => {
      dispatch(setDraft(parameters));
    },
    [dispatch],
  );

  const handleSaveDraft = useCallback((): void => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else if (saveDraftDisabled) {
      enqueueSnackbar({
        key: 'save_settings_disabled',
        message: t(
          noPartitionSelected
            ? 'no_partition_selected'
            : 'apply_settings_failed',
        ),
        variant: 'info',
      });
    } else {
      dispatch(saveDraft());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    generateHasInitiated,
    noPartitionSelected,
    saveDraftDisabled,
    t,
    userHasWritePermission,
  ]);

  const handleDiscardDraft = useCallback((): void => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else if (discardDraftDisabled) {
      enqueueSnackbar({
        key: 'discard_settings_disabled',
        message: 'discard_settings_disabled',
        variant: 'info',
      });
    } else {
      dispatch(discardDraft());
    }
  }, [
    discardDraftDisabled,
    dispatch,
    enqueueSnackbar,
    generateHasInitiated,
    t,
    userHasWritePermission,
  ]);

  useKeyboardShortcut({ key: 'm', ctrl: true }, handleSaveDraft);
  useKeyboardShortcut({ key: 'z', ctrl: true }, () =>
    setDiscardDraftDialog(true),
  );

  return (
    <>
      <ProjectPanel
        title={t('spatter_safe')}
        scale={7}
        actions={{
          onApply: handleSaveDraft,
          onDiscard: () => setDiscardDraftDialog(true),
          disabled:
            noPartitionSelected ||
            !userHasWritePermission ||
            generateHasInitiated,
          applyDisabled: saveDraftDisabled,
          discardDisabled: discardDraftDisabled,
          skip: {
            values: skip,
            onChange: (v) => dispatch(setSkipSpatterSafe(v)),
          },
        }}
        options={
          <>
            <AppIconButton
              onClick={() => setLoadPresetDialog(true)}
              icon={<LoadIcon />}
              helperText={t('load_preset')}
              disabled={disabled}
            />
            <AppIconButton
              onClick={() => setSavePresetDialog(true)}
              icon={<SaveIcon />}
              helperText={t('save_preset')}
              disabled={disabled}
            />
          </>
        }
        rootProps={{
          onClick: () => {
            if (!userHasWritePermission) {
              enqueueSnackbar({
                key: 'no_write_permission',
                message: t('no_write_permission'),
                variant: 'info',
              });
            } else if (generateHasInitiated) {
              enqueueSnackbar({
                key: 'project_settings_blocked',
                message: t('project_settings_blocked'),
                variant: 'info',
              });
            } else if (noPartitionSelected) {
              enqueueSnackbar({
                key: 'no_partition_selected',
                message: t('no_partition_selected'),
                variant: 'info',
              });
            }
          },
        }}
      >
        <SpatterSafeForm
          values={values}
          onChangeValues={handleUpdateDraft}
          disabled={disabled}
          isVarying={isVarying}
        />
      </ProjectPanel>
      <LoadSpatterSafePresetDialog
        open={loadPresetDialog}
        onClose={() => setLoadPresetDialog(false)}
        onSubmit={handleUpdateDraft}
      />
      <CreatePresetDialog
        open={savePresetDialog}
        onClose={() => setSavePresetDialog(false)}
        initialSetting={values}
      />
      <AppConfirmationDialog
        open={discardDraftDialog}
        onClose={() => setDiscardDraftDialog(false)}
        onConfirm={() => {
          handleDiscardDraft();
          setDiscardDraftDialog(false);
        }}
        title={t('discard_changes')}
        text={t('discard_changes_confirmation')}
      />
    </>
  );
};
