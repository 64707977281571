import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, InputAdornment, InputProps } from '@mui/material';

import { isValidNumber, ValidateNumberOptions } from 'utils';
import { AppFormControl, AppFormControlProps } from 'components/AppFormControl';

interface Props
  extends Omit<AppFormControlProps, 'children'>,
    ValidateNumberOptions {
  value: number;
  onChange: (v: number) => void;
  unit?: string;
  disabled?: boolean;
  step?: number;
  componentProps?: InputProps;
}

export const AppNumberForm: FC<Props> = ({
  label,
  value,
  onChange,
  unit,
  error,
  disabled,
  min,
  max,
  devisable,
  orEqual,
  step,
  componentProps,
  ...formControlProps
}) => {
  const [draft, setDraft] = useState<string>(String(value));

  useEffect(() => {
    if (!Number.isNaN(value) && value !== Number(draft))
      setDraft(String(value));
  }, [draft, value]);

  const validateValue = useCallback(
    (v: string): boolean =>
      !error && isValidNumber(v, { min, max, devisable, orEqual }),
    [error, max, min, devisable, orEqual],
  );

  const invalidValue = useMemo(
    (): boolean => !validateValue(draft),
    [draft, validateValue],
  );

  return (
    <AppFormControl label={label} error={invalidValue} {...formControlProps}>
      <Input
        inputProps={{
          type: 'number',
          min,
          max,
          step,
        }}
        value={draft}
        onChange={({ currentTarget }) => {
          setDraft(currentTarget.value);
          onChange(
            validateValue(currentTarget.value)
              ? Number(currentTarget.value)
              : NaN,
          );
        }}
        endAdornment={
          <InputAdornment position="end" disableTypography>
            {unit}
          </InputAdornment>
        }
        error={invalidValue}
        disabled={disabled}
        fullWidth
        {...componentProps}
      />
    </AppFormControl>
  );
};
