import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Paper, Stack } from '@mui/material';

import { ReactComponent as ThreeDViewIcon } from 'assets/icons/3d_view.svg';
import { ReactComponent as BuildPreviewIcon } from 'assets/icons/build_preview.svg';
import { ProjectMenuItem } from 'components/ProjectPanelMenu';
import {
  useSelector,
  useDispatch,
  useKeyboardShortcut,
  useSnackbar,
} from 'hooks';
import { GenerateState } from 'api';
import { View, selectView, setView } from 'slices/editorSlice';

export const ProjectViewMenu: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const activeView = useSelector(selectView);

  const { data: generateState } = useQuery<GenerateState>({
    queryKey: ['generate', { projectId }],
  });

  const buildPreviewDisabled = useMemo(
    () => !generateState?.layers.length,
    [generateState?.layers.length],
  );

  const handleChangeView = useCallback<(view: View) => void>(
    (view) => {
      dispatch(setView({ view }));
    },
    [dispatch],
  );

  useKeyboardShortcut({ key: '1', ctrl: true }, () => {
    handleChangeView(View.ModelPreview);
  });
  useKeyboardShortcut({ key: '2', ctrl: true }, () => {
    if (buildPreviewDisabled) {
      enqueueSnackbar({
        key: 'build_preview_disabled',
        message: t('build_preview_disabled'),
        variant: 'info',
      });
    } else {
      handleChangeView(View.BuildPreview);
    }
  });

  return (
    <Stack
      component={Paper}
      direction="row"
      position="absolute"
      height={60}
      width={180}
      zIndex={({ zIndex }) => zIndex.drawer - 1}
      top={0}
      left="50%"
      sx={{ transform: 'translate(-50%)' }}
    >
      <ProjectMenuItem
        name="3d_view"
        Icon={ThreeDViewIcon}
        active={activeView === View.ModelPreview}
        onClick={() => {
          handleChangeView(View.ModelPreview);
        }}
      />
      <ProjectMenuItem
        name="build_preview"
        Icon={BuildPreviewIcon}
        active={activeView === View.BuildPreview}
        onClick={() => {
          handleChangeView(View.BuildPreview);
        }}
        disabled={buildPreviewDisabled}
      />
    </Stack>
  );
};
