import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { deleteOrganization, ErrorResponse, getOrganizationPreview } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

interface Props {
  open: boolean;
  organizationId: string;
  onClose: () => void;
}

export const DeleteOrganizationDialog: FC<Props> = ({
  open,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>('');
  const [incorrectName, setIncorrectName] = useState<boolean>(false);

  const {
    data: preview,
    isLoading: isPreviewLoading,
    isRefetching: isPreviewRefetching,
    isError: isPreviewError,
    isRefetchError: isPreviewRefetchError,
  } = useQuery({
    queryKey: ['organization', { organizationId }],
    queryFn: () =>
      organizationId ? getOrganizationPreview(organizationId) : undefined,
  });

  useEffect(() => {
    if (isPreviewError || isPreviewRefetchError) {
      enqueueSnackbar({
        key: `get_organization_preview_fail_${Date.now()}`,
        message: t('get_organization_preview_fail'),
        variant: 'error',
      });
      deleteSearchParam('rowId');
    }
  }, [
    deleteSearchParam,
    enqueueSnackbar,
    isPreviewError,
    isPreviewRefetchError,
    t,
  ]);

  const { mutate: onDeleteOrganization, isPending: isDeleting } = useMutation({
    mutationFn: () => {
      if (!preview) throw new Error('organization preview does not exist');
      return deleteOrganization(preview.data.id, preview.etag);
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `delete_organization_success_${Date.now()}`,
        message: t('delete_organization_success', {
          name: preview?.data.name,
        }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({ queryKey: ['organizations'] });
      queryClient.removeQueries({
        queryKey: ['organization', { organizationId }],
      });
      deleteSearchParam('rowId');
      onClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'delete_organization_decrepit',
          message: t('delete_organization_decrepit', {
            name: preview?.data.name,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({
                    queryKey: ['organization', { organizationId }],
                  })
                  .then(() => {
                    closeSnackbar('delete_organization_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `delete_organization_fail_${Date.now()}`,
          message: t('delete_organization_fail', {
            name: preview?.data.name,
          }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  const handleSubmit = useCallback<() => void>(() => {
    if (name !== preview?.data.name) {
      setIncorrectName(true);
    } else {
      onDeleteOrganization();
    }
  }, [preview?.data.name, name, onDeleteOrganization]);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('delete_organization')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          disabled={isDeleting || isPreviewLoading || isPreviewRefetching}
          fullWidth
        >
          {t('delete')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <Typography>
          {t('delete_organization_confirmation', { name: preview?.data.name })}
        </Typography>
        <AppTextForm
          ref={nameRef}
          label={t('confirm_name')}
          value={name}
          onChange={setName}
          helperText={t('delete_organization_name_helper')}
          error={incorrectName}
          errorText={t('delete_organization_name_not_match')}
        />
      </Stack>
    </AppDialog>
  );
};
