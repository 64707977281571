import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState, PartitionQuery, extractPartitionIds } from 'utils';
import {
  HeatBalance,
  HeatBalanceType,
  Partition,
  PartitionSettings,
  Selection,
} from 'api/types';

export const defaultHeatBalance: HeatBalance = {
  type: HeatBalanceType.custom,
  repetitions: 10,
  area: null,
  spotSizeMicrometer: 2000,
  beamPowerWatt: 600,
  gridSizeMillimeter: 0.1,
  gridOffsetPercent: 0,
  seeds: [],
  pointSpreadAlgName: '',
  dwellTimeAlgName: '',
  pointSpreadSettings: [],
  dwellTimeSettings: [],
};

export const initialState: PartitionSettings<HeatBalance> = {
  selection: Selection.model,
  partitions: [],
  selectedPartitionIds: [],
};

const heatBalanceSlice = createSlice({
  name: 'heatBalance',
  initialState,
  reducers: {
    loadHeatBalance: (
      state,
      { payload }: PayloadAction<PartitionSettings<HeatBalance>>,
    ) => {
      state.selection = payload.selection;
      state.partitions = payload.partitions;
      state.selectedPartitionIds = payload.selectedPartitionIds;
    },
    resetHeatBalancePartitions: (state) => {
      state.partitions = initialState.partitions;
    },
    setHeatBalancePartitionSelection: (
      state,
      { payload }: PayloadAction<Selection>,
    ) => {
      state.selection = payload;
    },
    setHeatBalancePartitions: (
      state,
      { payload }: PayloadAction<Partition<HeatBalance>[]>,
    ) => {
      state.partitions = payload;
      state.selectedPartitionIds = [];
    },
    setSelectedHeatBalancePartitionIds: (
      state,
      { payload }: PayloadAction<PartitionQuery>,
    ) => {
      state.selectedPartitionIds = extractPartitionIds({
        query: payload,
        partitions: state.partitions,
        selectedPartitionIds: state.selectedPartitionIds,
      });
    },
    setDraft: (state, { payload }: PayloadAction<HeatBalance>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: payload,
              },
            }
          : partition,
      );
    },
    saveDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
                saved: partition.parameters.draft,
              },
            }
          : partition,
      );
    },
    discardDraft: (state) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: {
                ...partition.parameters,
                draft: null,
              },
            }
          : partition,
      );
    },
    setSkipHeatBalance: (state, { payload }: PayloadAction<boolean>) => {
      state.partitions = state.partitions.map((partition) =>
        state.selectedPartitionIds.includes(partition.id)
          ? {
              ...partition,
              parameters: { ...partition.parameters, skip: payload },
            }
          : partition,
      );
    },
  },
});

export const {
  loadHeatBalance,
  resetHeatBalancePartitions,
  setHeatBalancePartitionSelection,
  setHeatBalancePartitions,
  setSelectedHeatBalancePartitionIds,
  setDraft,
  saveDraft,
  discardDraft,
  setSkipHeatBalance,
} = heatBalanceSlice.actions;

export const selectHeatBalancePartitionSelection = (
  state: RootState,
): Selection => state.heatBalance.selection;
export const selectHeatBalancePartitions = (
  state: RootState,
): Partition<HeatBalance>[] => state.heatBalance.partitions;
export const selectSelectedHeatBalancePartitionIds = (
  state: RootState,
): string[] => state.heatBalance.selectedPartitionIds;

export const selectSelectedHeatBalancePartitions = createSelector(
  selectHeatBalancePartitions,
  selectSelectedHeatBalancePartitionIds,
  (partitions, selectedPartitionIds): Partition<HeatBalance>[] =>
    partitions.filter(({ id }) => selectedPartitionIds.includes(id)),
);
export const selectAllHeatBalancePartitionsAreSaved = createSelector(
  selectHeatBalancePartitions,
  (partitions): boolean =>
    partitions.every(({ parameters }) => !!parameters.saved || parameters.skip),
);
export const selectSkipHeatBalance = createSelector(
  selectSelectedHeatBalancePartitions,
  (selectedPartitions): Record<string, boolean> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.skip]),
    ),
);
export const selectHeatBalanceDraftValues = createSelector(
  selectSelectedHeatBalancePartitions,
  (selectedPartitions): Record<string, HeatBalance | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.draft]),
    ),
);
export const selectHeatBalanceSavedValues = createSelector(
  selectSelectedHeatBalancePartitions,
  (selectedPartitions): Record<string, HeatBalance | null> =>
    Object.fromEntries(
      selectedPartitions.map(({ id, parameters }) => [id, parameters.saved]),
    ),
);

export default heatBalanceSlice.reducer;
