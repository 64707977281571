import { forwardRef, useMemo, useState } from 'react';
import { Input, InputProps } from '@mui/material';

import { AppFormControl, AppFormControlProps } from 'components/AppFormControl';

const parseValue = (value: string): number[] =>
  value
    ? value
        .replaceAll(' ', '')
        .split(',')
        .map((v) => (v ? Number(v) : NaN))
    : [];

interface Props extends Omit<AppFormControlProps, 'children'> {
  value: number[];
  onChange: (v: number[]) => void;
  componentProps?: InputProps;
}

export const AppNumberListForm = forwardRef<HTMLInputElement, Props>(
  (
    { label, value, onChange, componentProps, error, ...formControlProps },
    ref,
  ) => {
    const [draft, setDraft] = useState(value.join(','));

    const isInvalidValue = useMemo(
      () => parseValue(draft).some(isNaN),
      [draft],
    );

    return (
      <AppFormControl
        label={label}
        {...formControlProps}
        error={!!error || isInvalidValue}
      >
        <Input
          ref={ref}
          value={draft}
          onChange={({ target }) => {
            const newValue = target.value.replace(/[^0-9,\s]/g, '');
            setDraft(newValue);
            onChange(parseValue(newValue));
          }}
          fullWidth
          {...componentProps}
        />
      </AppFormControl>
    );
  },
);
AppNumberListForm.displayName = 'AppNumberListForm';
