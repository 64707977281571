import { FC, ReactNode, useMemo } from 'react';
import { SnackbarProvider as Provider, SnackbarProviderProps } from 'notistack';

interface Props {
  children: ReactNode;
}

export const SnackbarProvider: FC<Props> = ({ children }) => {
  const snackbarProviderProps = useMemo<
    Omit<SnackbarProviderProps, 'children'>
  >(
    () => ({
      maxSnack: 4,
      preventDuplicate: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      hideIconVariant: true,
    }),
    [],
  );

  return <Provider {...snackbarProviderProps}>{children}</Provider>;
};
