import { FC } from 'react';
import { Color } from 'three';
import { Edges, Cylinder } from '@react-three/drei';

import { useMaterial } from 'hooks';

export const ProjectBuildTank: FC = () => {
  const material = useMaterial({
    color: new Color(1, 1, 1),
    parameters: { transparent: true, opacity: 0.1 },
  });

  return (
    <Cylinder
      args={[75, 75, 120, 100]} // radiusTop, radiusBottom, height, radialSegments
      position={[0, 0, 50]}
      rotation={[Math.PI / 2, 0, 0]}
      material={material}
    >
      <Edges scale={1} threshold={4} color={new Color(0.1, 0.1, 0.1)} />
    </Cylinder>
  );
};
