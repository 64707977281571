import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  GenerateCompleteDialog,
  GenerateResetDialog,
  GeneratingDialog,
  StartGenerateDialog,
} from 'components/ProjectBuildFilesDialog';
import { GenerateState, ProjectState } from 'api';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ProjectBuildFilesDialog: FC<Props> = (props) => {
  const { projectId } = useParams<'projectId'>();

  const { data: generateState, isLoading } = useQuery<GenerateState>({
    queryKey: ['generate', { projectId }],
  });

  if (!props.open) return null;

  switch (generateState?.projectState) {
    case ProjectState.NotGenerated:
      return <StartGenerateDialog {...props} />;
    case ProjectState.GenerationFailed:
      return <GenerateResetDialog {...props} />;
    case ProjectState.GenerationCanceled:
      return <GenerateResetDialog {...props} />;
    case ProjectState.GenerationQueued:
      return (
        <GeneratingDialog
          {...props}
          generateState={generateState}
          isLoading={isLoading}
        />
      );
    case ProjectState.Generating:
      return (
        <GeneratingDialog
          {...props}
          generateState={generateState}
          isLoading={isLoading}
        />
      );
    case ProjectState.GeneratedSuccessfully:
      return <GenerateCompleteDialog {...props} />;
    default:
      return null;
  }
};
