import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Autocomplete,
  TextField,
  TableRow,
  TableCell,
  Stack,
  Divider,
} from '@mui/material';

import { ProjectRemovePartitionForm } from 'components/ProjectRemovePartitionForm';
import { ProjectPartitionStatus } from 'components/ProjectPartitionStatus';
import { add, subtract } from 'utils';
import { SlicedModel, SlicedObject, Partition } from 'api';
import { useProcessStepActions } from 'hooks';

const ROW_HEIGHT = 52;

interface Props {
  index: number;
  parent: SlicedModel | SlicedObject;
  partitions: Partition<unknown>[];
  selected?: boolean;
  extraIndent?: boolean;
  onRemovePartition?: (id: string, effect: 'before' | 'after') => void;
  onUpdatePartition?: (id: string, breakpoint: [number, number]) => void;
  selectable?: boolean;
}

export const TreeItemPartition: FC<Props> = ({
  index,
  parent,
  partitions,
  selected,
  extraIndent,
  onRemovePartition,
  onUpdatePartition,
  selectable,
}) => {
  const { t } = useTranslation();
  const { setSelectedPartitions } = useProcessStepActions();

  const partition = useMemo(
    (): Partition<unknown> => partitions[index],
    [partitions, index],
  );

  const options = useMemo<number[]>(() => {
    const result = [];
    let i = parent.startZMillimeter;
    while (i <= parent.endZMillimeter) {
      result.push(i);
      i = add(i, parent.layerThicknessMillimeter);
    }
    return result;
  }, [parent]);

  const handleRemovePartition = useCallback<
    (effect: 'before' | 'after') => void
  >(
    (effect) => {
      if (onRemovePartition) {
        onRemovePartition(partition.id, effect);
      }
    },
    [onRemovePartition, partition],
  );

  const handleChangeValue = useCallback<
    (event: SyntheticEvent, value: number) => void
  >(
    (_event, value) => {
      if (onUpdatePartition) {
        onUpdatePartition(partition.id, [
          subtract(value, parent.layerThicknessMillimeter),
          value,
        ]);
      }
    },
    [onUpdatePartition, partition, parent],
  );

  const handleSelectPartition = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      if (selectable)
        setSelectedPartitions({
          target: partition.id,
          event: { ctrlKey: event.ctrlKey, metaKey: event.metaKey },
        });
    },
    [partition.id, selectable, setSelectedPartitions],
  );

  return (
    <TableRow
      hover={selectable}
      selected={selectable && selected}
      sx={{
        cursor: selectable ? 'pointer' : 'default',
        border: 'none',
        height: ROW_HEIGHT,
      }}
      onClick={handleSelectPartition}
    >
      <TableCell colSpan={2} sx={{ py: 0 }}>
        <Stack direction="row" alignItems="center" spacing={2} ml={2}>
          {extraIndent && (
            <Divider
              orientation="vertical"
              sx={{ height: ROW_HEIGHT, mr: 2 }}
            />
          )}
          <Divider orientation="vertical" sx={{ height: ROW_HEIGHT }} />
          {onUpdatePartition ? (
            <Autocomplete
              value={partition.startZMillimeter}
              onChange={handleChangeValue}
              options={options}
              renderInput={(params) => <TextField {...params} />}
              size="small"
              getOptionDisabled={(option) =>
                option > partition.endZMillimeter ||
                option <= partitions[index - 1].startZMillimeter
              }
              getOptionLabel={(option) =>
                String(subtract(option, parent.layerThicknessMillimeter))
              }
              disabled={index === 0}
              disableClearable
              sx={{ minWidth: 100 }}
            />
          ) : (
            <Typography variant="body2">
              {subtract(
                partition.startZMillimeter,
                parent.layerThicknessMillimeter,
              )}
            </Typography>
          )}
          <Typography variant="body2">-</Typography>
          <Typography variant="body2">{partition.endZMillimeter}</Typography>
          <Typography variant="body2" color="text.disabled">
            {t('unit:millimeter')}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {onRemovePartition ? (
          <ProjectRemovePartitionForm
            iconButtonProps={{ disabled: partitions.length < 2 }}
            onRemovePartition={handleRemovePartition}
            disabled={{
              before: partition.startZMillimeter === parent.startZMillimeter,
              after: partition.endZMillimeter === parent.endZMillimeter,
            }}
          />
        ) : (
          <ProjectPartitionStatus
            sx={{ ml: partition.objectId === undefined ? -2 : -4 }}
            draft={!!partition.parameters.draft}
            saved={!!partition.parameters.saved}
            skip={partition.parameters.skip}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
