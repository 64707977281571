import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Box, Stack, Typography } from '@mui/material';
import { DeleteRounded as DeleteIcon } from '@mui/icons-material';

import { DeleteModelDialog } from 'pages/Models';
import { AppPreviewDrawer } from 'components/AppPreviewDrawer';
import { App3DView } from 'components/App3DView';
import { AppButtonForm } from 'components/AppFormControl';
import { getModelPreview } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';
import { subtract } from 'utils';

export const AppModelPreview: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [{ rowId: modelId }, { deleteSearchParam }] = useSearchParams();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const onClose = useCallback<() => void>(() => {
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  const {
    data: preview,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['model', { modelId }],
    queryFn: () => getModelPreview(modelId!),
    enabled: !!modelId,
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        key: `get_model_preview_fail_${Date.now()}`,
        message: t('get_model_preview_fail'),
        variant: 'error',
      });
      onClose();
    }
  }, [enqueueSnackbar, isError, onClose, t]);

  if (isLoading) return <AppPreviewDrawer open onClose={onClose} isLoading />;

  if (preview)
    return (
      <>
        <AppPreviewDrawer
          open
          onClose={onClose}
          title={preview.data.name}
          actions={
            <AppButtonForm
              label={t('delete')}
              helperText={
                !preview.data.isModelUsed
                  ? t('delete_model_helper')
                  : t('model_not_unused')
              }
              componentProps={{
                onClick: () => setDeleteDialog(true),
                color: 'error',
                startIcon: <DeleteIcon />,
                disabled: preview.data.isModelUsed,
              }}
            />
          }
        >
          <Typography variant="h5">{t('info')}</Typography>
          <Stack spacing={1}>
            <Typography>
              {t('preview_layerThickness', {
                value: preview.data.sliceInfo.layerThicknessMillimeter,
              })}
            </Typography>
            <Typography>
              {t('preview_height', {
                value: subtract(
                  preview.data.sliceInfo.endZMillimeter,
                  subtract(
                    preview.data.sliceInfo.startZMillimeter,
                    preview.data.sliceInfo.layerThicknessMillimeter,
                  ),
                ),
              })}
            </Typography>
            <Typography>
              {t('preview_objects', {
                value: preview.data.sliceInfo.objects.length,
              })}
            </Typography>
          </Stack>
          <Typography variant="h5">{t('preview')}</Typography>
          <Box
            component="div"
            flex={1}
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'text.primary',
              borderRadius: ({ shape }) => `${shape.borderRadius}px`,
              boxSizing: 'border-box',
            }}
          >
            <App3DView
              model={preview.data}
              modelPreviewSettings={{
                axesHelper: false,
                buildTank: false,
                highlightSelected: false,
                highlightUnsliced: false,
                startPlate: false,
              }}
            />
          </Box>
        </AppPreviewDrawer>
        {!preview.data.isModelUsed && (
          <DeleteModelDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            modelId={preview.data.id}
          />
        )}
      </>
    );

  return null;
};
