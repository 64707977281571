import { createSelector } from '@reduxjs/toolkit';

import { PrimaryMenuTab, selectPrimaryMenu } from 'slices/editorSlice';
import {
  selectJumpSafePartitionSelection,
  selectJumpSafePartitions,
  selectJumpSafeSavedValues,
  selectSelectedJumpSafePartitions,
} from 'slices/jumpSafeSlice';
import {
  selectSelectedSpatterSafePartitions,
  selectSpatterSafePartitionSelection,
  selectSpatterSafePartitions,
  selectSpatterSafeSavedValues,
} from 'slices/spatterSafeSlice';
import {
  selectMeltPartitionSelection,
  selectMeltPartitions,
  selectMeltSavedValues,
  selectSelectedMeltPartitions,
} from 'slices/meltSlice';
import {
  selectHeatBalancePartitionSelection,
  selectHeatBalancePartitions,
  selectHeatBalanceSavedValues,
  selectSelectedHeatBalancePartitions,
} from 'slices/heatBalanceSlice';
import { Selection } from 'api';

export const selectPartitionSelection = createSelector(
  selectPrimaryMenu,
  selectJumpSafePartitionSelection,
  selectSpatterSafePartitionSelection,
  selectMeltPartitionSelection,
  selectHeatBalancePartitionSelection,
  (
    { window },
    jumpSafePartitionSelection,
    spatterSafePartitionSelection,
    meltPartitionSelection,
    heatBalancePartitionSelection,
  ) => {
    switch (window) {
      case PrimaryMenuTab.jump_safe:
        return jumpSafePartitionSelection;
      case PrimaryMenuTab.spatter_safe:
        return spatterSafePartitionSelection;
      case PrimaryMenuTab.melt:
        return meltPartitionSelection;
      case PrimaryMenuTab.heat_balance:
        return heatBalancePartitionSelection;
      default:
        return Selection.model;
    }
  },
);

export const selectPartitions = createSelector(
  selectPrimaryMenu,
  selectJumpSafePartitions,
  selectSpatterSafePartitions,
  selectMeltPartitions,
  selectHeatBalancePartitions,
  (
    { window },
    jumpSafePartitions,
    spatterSafePartitions,
    meltPartitions,
    heatBalancePartitions,
  ) => {
    switch (window) {
      case PrimaryMenuTab.jump_safe:
        return jumpSafePartitions;
      case PrimaryMenuTab.spatter_safe:
        return spatterSafePartitions;
      case PrimaryMenuTab.melt:
        return meltPartitions;
      case PrimaryMenuTab.heat_balance:
        return heatBalancePartitions;
      default:
        return [];
    }
  },
);

export const selectSelectedPartitions = createSelector(
  selectPrimaryMenu,
  selectSelectedJumpSafePartitions,
  selectSelectedSpatterSafePartitions,
  selectSelectedMeltPartitions,
  selectSelectedHeatBalancePartitions,
  (
    { window },
    selectedJumpSafePartitions,
    selectedSpatterSafePartitions,
    selectedMeltPartitions,
    selectedHeatBalancePartitions,
  ) => {
    switch (window) {
      case PrimaryMenuTab.jump_safe:
        return selectedJumpSafePartitions;
      case PrimaryMenuTab.spatter_safe:
        return selectedSpatterSafePartitions;
      case PrimaryMenuTab.melt:
        return selectedMeltPartitions;
      case PrimaryMenuTab.heat_balance:
        return selectedHeatBalancePartitions;
      default:
        return [];
    }
  },
);

export const selectSavedValues = createSelector(
  selectPrimaryMenu,
  selectJumpSafeSavedValues,
  selectSpatterSafeSavedValues,
  selectMeltSavedValues,
  selectHeatBalanceSavedValues,
  (
    { window },
    jumpSafeSavedValues,
    spatterSafeSavedValues,
    meltSavedValues,
    heatBalanceSavedValues,
  ) => {
    switch (window) {
      case PrimaryMenuTab.jump_safe:
        return jumpSafeSavedValues;
      case PrimaryMenuTab.spatter_safe:
        return spatterSafeSavedValues;
      case PrimaryMenuTab.melt:
        return meltSavedValues;
      case PrimaryMenuTab.heat_balance:
        return heatBalanceSavedValues;
      default:
        return {};
    }
  },
);
