import { createRef, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, CircularProgress, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  ErrorResponse,
  CreateProject,
  ProjectPreviewResponse,
  CopyProject,
  copyProject,
} from 'api';
import { useSearchParams, useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  project: ProjectPreviewResponse;
  onClose: () => void;
}

export const DuplicateProjectDialog: FC<Props> = ({
  open,
  project,
  onClose,
}) => {
  const { t } = useTranslation();
  const [, { updateSearchParam }] = useSearchParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = useMemo(
    () => ({
      name: t('duplicate_of_project', { projectName: project.name }),
      description: project.description,
      material: project.material,
    }),
    [project, t],
  );
  const [overrideValues, setOverrideValues] = useState(initialValues);
  const [validationError, setValidationError] = useState<
    (keyof CreateProject)[]
  >([]);

  const refs = useMemo<Record<keyof CopyProject, RefObject<HTMLInputElement>>>(
    () => ({
      name: createRef<HTMLInputElement>(),
      description: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const { mutateAsync: duplicateProject, isPending: isLoadingDuplicate } =
    useMutation({
      mutationFn: () => copyProject(project.id, overrideValues),
      onSuccess: ({ data }) => {
        enqueueSnackbar({
          key: `duplicate_project_success_${Date.now()}`,
          message: t('duplicate_project_success', {
            projectName: project.name,
          }),
          variant: 'success',
        });
        void queryClient.invalidateQueries({
          queryKey: ['projects'],
        });
        updateSearchParam(['rowId', data.id]);
        onClose();
      },
      onError: ({ response }: AxiosError<ErrorResponse>) => {
        enqueueSnackbar({
          key: `duplicate_project_fail_${Date.now()}`,
          message: t('duplicate_project_fail'),
          variant: 'error',
          persist: true,
        });
        if (response?.status === 400) {
          if (response.data.errors) {
            const errorKeys = Object.keys(response.data.errors)
              .map<keyof CopyProject | undefined>((key) => {
                if (key === 'Name') return 'name';
                if (key === 'Material') return 'material';
                if (key === 'Description') return 'description';
                return undefined;
              })
              .filter((key): key is keyof CopyProject => !!key);
            setValidationError(errorKeys);
            refs[errorKeys[0]]?.current?.focus();
          }
        }
      },
    });

  useEffect(() => {
    setOverrideValues(initialValues);
  }, [initialValues, t]);

  useEffect(() => {
    setValidationError([]);
  }, [overrideValues]);

  useEffect(() => {
    return () => {
      setValidationError([]);
    };
  }, []);

  return (
    <AppDialog
      open={open}
      title={t('duplicate_project')}
      actions={
        <Button
          onClick={() => void duplicateProject()}
          variant="contained"
          disabled={isLoadingDuplicate}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {isLoadingDuplicate ? <CircularProgress /> : t('duplicate')}
        </Button>
      }
      onClose={onClose}
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={overrideValues.name}
          onChange={(name) => {
            setOverrideValues((prevState) => ({ ...prevState, name }));
          }}
          componentProps={{ inputRef: refs.name }}
          helperText={t('project_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:duplicate_project.name')}
        />
        <AppTextForm
          ref={refs.material}
          label={t('material')}
          value={overrideValues.material}
          onChange={(material) => {
            setOverrideValues((prevState) => ({ ...prevState, material }));
          }}
          componentProps={{ inputRef: refs.material }}
          helperText={t('project_material_helper')}
          error={validationError.includes('material')}
          errorText={t('validations:duplicate_project.material')}
        />
        <AppTextForm
          label={t('description')}
          value={overrideValues.description}
          onChange={(description) => {
            setOverrideValues((prevState) => ({
              ...prevState,
              description: description,
            }));
          }}
          componentProps={{ multiline: true, inputRef: refs.description }}
          helperText={t('project_description_helper')}
          error={validationError.includes('description')}
          errorText={t('validations:duplicate_project.description')}
        />
      </Stack>
    </AppDialog>
  );
};
