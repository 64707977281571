import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Checkbox, CheckboxProps, Stack } from '@mui/material';

import { AppFormControl, AppFormControlProps } from 'components/AppFormControl';

interface Props extends Omit<AppFormControlProps, 'children'> {
  checked: boolean;
  onChange: (value: boolean) => void;
  componentProps?: CheckboxProps;
}

export const AppCheckboxForm: FC<Props> = ({
  label,
  checked,
  onChange,
  componentProps,
  ...formControlProps
}) => {
  const { t } = useTranslation();

  return (
    <AppFormControl label={label} {...formControlProps} hideFormLabel>
      <Stack direction="row" alignItems="center">
        <Typography fontWeight="bold">
          {label}
          {formControlProps.varies && (
            <Typography component="span" color="warning.main" fontWeight={100}>
              {` (${t('varies')})`}
            </Typography>
          )}
        </Typography>
        <Box component="div" sx={{ flex: 1 }} />
        <Checkbox
          checked={checked}
          onChange={(_, c) => onChange(c)}
          {...componentProps}
        />
      </Stack>
    </AppFormControl>
  );
};
