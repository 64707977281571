import { forwardRef, MouseEventHandler, ReactNode } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface Props {
  text: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  textSecondary?: string;
  icon?: ReactNode;
  color?: string;
  indent?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

export const AppButtonListItem = forwardRef<HTMLLIElement, Props>(
  (
    { text, onClick, textSecondary, icon, color, selected, disabled, indent },
    ref,
  ) => {
    return (
      <ListItem ref={ref} sx={{ color }} disablePadding>
        <ListItemButton
          onClick={onClick}
          sx={{ width: 1, pl: indent ? 8 : 5 }}
          selected={selected}
          disabled={disabled}
        >
          {icon !== undefined && (
            <ListItemIcon sx={{ color, fill: color }}>{icon}</ListItemIcon>
          )}
          <ListItemText
            primary={text}
            secondary={textSecondary}
            primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
            sx={{ whiteSpace: 'nowrap' }}
          />
        </ListItemButton>
      </ListItem>
    );
  },
);
AppButtonListItem.displayName = 'AppButtonListItem';
