import axios from 'axios';

import { GenerateState } from 'api';

export const getGenerateState = async (
  projectId: string,
): Promise<GenerateState> => {
  const { data } = await axios.get<GenerateState>(`generate/${projectId}`);
  return data;
};

export const startGenerate = async (projectId: string): Promise<void> => {
  await axios.post(`generate/${projectId}/start`);
};

export const stopGenerate = async (projectId: string): Promise<void> => {
  await axios.post(`generate/${projectId}/stop`);
};

export const deleteBuildFiles = async (projectId: string): Promise<void> => {
  await axios.delete(`generate/${projectId}`);
};
