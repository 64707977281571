import { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, CircularProgress, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  createSpatterSafePreset,
  SpatterSafePreset,
  ErrorResponse,
  getSpatterSafePreset,
} from 'api';
import { useSearchParams, useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  presetId: string;
  onClose: () => void;
}

export const DuplicateSpatterSafePresetDialog: FC<Props> = ({
  open,
  presetId,
  onClose,
}) => {
  const { t } = useTranslation();
  const [, { updateSearchParam }] = useSearchParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [material, setMaterial] = useState('');
  const [validationError, setValidationError] = useState<
    (keyof SpatterSafePreset)[]
  >([]);
  const refs = useMemo(
    () => ({
      name: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const {
    data: preset,
    isLoading: isPresetLoading,
    isError: isPresetError,
  } = useQuery({
    queryKey: ['spatter-safe-preset', { presetId }],
    queryFn: () => getSpatterSafePreset(presetId),
    enabled: open,
  });

  useEffect(() => {
    if (isPresetError) {
      enqueueSnackbar({
        key: `get_preset_fail_${Date.now()}`,
        message: t('get_preset_fail'),
        variant: 'error',
      });
      onClose();
    }
  }, [enqueueSnackbar, isPresetError, onClose, t]);

  const { mutateAsync: duplicatePreset, isPending: isDuplicating } =
    useMutation({
      mutationFn: () => {
        if (!preset) throw new Error('preset settings does not exist');
        return createSpatterSafePreset({
          name,
          material,
          area: preset.data.area,
          repetitions: preset.data.repetitions,
          pointSpreadAlgName: preset.data.pointSpreadAlgName,
          pointSpreadSettings: preset.data.pointSpreadSettings,
          dwellTimeAlgName: preset.data.dwellTimeAlgName,
          dwellTimeSettings: preset.data.dwellTimeSettings,
          gridSizeMillimeter: preset.data.gridSizeMillimeter,
          beamPowerWatt: preset.data.beamPowerWatt,
          gridOffsetPercent: preset.data.gridOffsetPercent,
          spotSizeMicrometer: preset.data.spotSizeMicrometer,
          seeds: preset.data.seeds,
        });
      },
      onSuccess: ({ data }) => {
        enqueueSnackbar({
          key: `duplicate_preset_success_${Date.now()}`,
          message: t('duplicate_preset_success', { name: preset?.data.name }),
          variant: 'success',
        });
        void queryClient.invalidateQueries({
          queryKey: ['spatter-safe-presets'],
        });
        updateSearchParam(['rowId', data.id]);
        onClose();
      },
      onError: ({ response }: AxiosError<ErrorResponse>) => {
        enqueueSnackbar({
          key: `duplicate_preset_fail_${Date.now()}`,
          message: t('duplicate_preset_fail'),
          variant: 'error',
          persist: true,
        });
        if (response?.status === 400) {
          if (response.data.errors) {
            const errorKeys = Object.keys(response.data.errors)
              .map((key) => {
                if (key === 'Name') return 'name';
                if (key === 'Material') return 'material';
                return undefined;
              })
              .filter((key): key is 'name' | 'material' => !!key);
            setValidationError(errorKeys);
            refs[errorKeys[0]]?.current?.focus();
          }
        }
      },
    });

  useEffect(() => {
    if (preset) {
      setName(t('duplicate_of_preset', { name: preset.data.name }));
      setMaterial(preset.data.material);
    }
  }, [preset, t]);

  useEffect(() => {
    if (open && isPresetError) {
      onClose();
      enqueueSnackbar({
        key: `duplicate_preset_blocked`,
        message: t('duplicate_preset_blocked'),
        variant: 'info',
      });
    }
  }, [enqueueSnackbar, isPresetError, onClose, open, t]);

  useEffect(() => {
    return () => {
      setName('');
      setMaterial('');
      setValidationError([]);
    };
  }, []);

  return (
    <AppDialog
      open={open}
      title={t('duplicate_preset')}
      actions={
        <Button
          onClick={() => void duplicatePreset()}
          variant="contained"
          disabled={isDuplicating || isPresetLoading}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {isDuplicating || isPresetLoading ? (
            <CircularProgress />
          ) : (
            t('duplicate')
          )}
        </Button>
      }
      onClose={onClose}
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={name}
          onChange={setName}
          componentProps={{ inputRef: refs.name }}
          helperText={t('preset_info_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:duplicate_preset.name')}
        />
        <AppTextForm
          ref={refs.material}
          label={t('material')}
          value={material}
          onChange={setMaterial}
          componentProps={{ inputRef: refs.material }}
          helperText={t('preset_info_material_helper')}
          error={validationError.includes('material')}
          errorText={t('validations:duplicate_preset.material')}
        />
      </Stack>
    </AppDialog>
  );
};
