import { FC, ReactNode, useEffect, useMemo } from 'react';
import {
  Routes as ReactRoutes,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { AppTransition } from 'components/AppTransition';
import { getCurrentUser, UserRole } from 'api';
import { useSnackbar, useAuthentication } from 'hooks';
// Containers
import { Home } from 'pages/Home';
import { Projects } from 'pages/Projects';
import { Presets } from 'pages/Presets';
import { Models } from 'pages/Models';
import { Project } from 'pages/Project';
import { UserGuide } from 'pages/UserGuide';
import { ReleaseNotes } from 'pages/ReleaseNotes';
import { Admin } from 'pages/Admin';
import { Account } from 'pages/Account';
import { Organizations } from 'pages/Organizations';

export interface HomeRoute {
  path: string;
  element: ReactNode;
  title: string;
  claims: UserRole[];
  group: 'management' | 'presets' | 'help' | 'settings';
}

export const homeRoutes: HomeRoute[] = [
  {
    path: '/projects',
    element: <Projects />,
    title: 'projects',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'management',
  },
  {
    path: '/models',
    element: <Models />,
    title: 'models',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'management',
  },
  {
    path: '/presets/start-heat',
    element: <Presets processStep="startHeat" />,
    title: 'start_heat',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'presets',
  },
  {
    path: '/presets/jump-safe',
    element: <Presets processStep="jumpSafe" />,
    title: 'jump_safe',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'presets',
  },
  {
    path: '/presets/spatter-safe',
    element: <Presets processStep="spatterSafe" />,
    title: 'spatter_safe',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'presets',
  },
  {
    path: '/presets/melt',
    element: <Presets processStep="melt" />,
    title: 'melt',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'presets',
  },
  {
    path: '/presets/heat-balance',
    element: <Presets processStep="heatBalance" />,
    title: 'heat_balance',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'presets',
  },
  {
    path: '/user-guide',
    element: <UserGuide />,
    title: 'user-guide',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'help',
  },
  {
    path: '/release-notes',
    element: <ReleaseNotes />,
    title: 'release-notes',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'help',
  },
  {
    path: '/account',
    element: <Account />,
    title: 'account',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'settings',
  },
  {
    path: '/admin',
    element: <Admin />,
    title: 'admin',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin],
    group: 'settings',
  },
  {
    path: '/organizations',
    element: <Organizations />,
    title: 'organizations',
    claims: [UserRole.SuperAdmin],
    group: 'settings',
  },
];

export const Private: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut, startRefreshSessionInterval } = useAuthentication();

  const {
    data: currentUser,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['current-user'],
    queryFn: getCurrentUser,
    staleTime: 0,
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        key: `get_current_user_fail_${Date.now()}`,
        message: t('get_current_user_fail'),
        variant: 'error',
      });
      signOut();
    }
  }, [enqueueSnackbar, isError, signOut, t]);

  const filteredHomeRoutes = useMemo<HomeRoute[]>(
    () =>
      homeRoutes.filter(
        ({ claims }) => currentUser && claims.includes(currentUser.data.type),
      ),
    [currentUser],
  );

  useEffect(() => {
    const interval = startRefreshSessionInterval();
    return () => {
      clearInterval(interval);
    };
  }, [startRefreshSessionInterval]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/projects/:projectId" element={<Project />} />
        <Route
          path="/*"
          element={
            <Home>
              <ReactRoutes>
                {filteredHomeRoutes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                <Route path="*" element={<Navigate to="/projects" replace />} />
              </ReactRoutes>
            </Home>
          }
        />
      </>,
    ),
  );

  if (isLoading) return <AppTransition />;

  return <RouterProvider router={router} />;
};
