import { useState, useEffect, FC } from 'react';

import { AppHeader } from 'components/AppHeader';
import { ProjectBuildFilesDialog } from 'components/ProjectBuildFilesDialog';
import { ProjectBuildFilesButton } from 'components/ProjectBuildFilesButton';
import { ProjectQuickSettings } from 'components/ProjectQuickSettings';
import { ProjectState } from 'api';

interface Props {
  projectState: ProjectState | undefined;
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectHeader: FC<Props> = ({
  projectState,
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const [generateDialog, setGenerateDialog] = useState(false);
  const [altLogo, setAltLogo] = useState<boolean>(false);

  useEffect(() => {
    if (projectState === ProjectState.Generating) {
      const interval = setInterval(() => {
        setAltLogo((prevState) => !prevState);
      }, 500);
      return () => {
        clearInterval(interval);
        setAltLogo(false);
      };
    }
  }, [projectState]);

  return (
    <AppHeader
      customHeaderActions={(anchor) => (
        <>
          <ProjectBuildFilesButton onOpen={() => setGenerateDialog(true)} />
          <ProjectQuickSettings
            anchor={anchor}
            userHasWritePermission={userHasWritePermission}
            generateHasInitiated={generateHasInitiated}
          />
        </>
      )}
      altLogo={altLogo}
    >
      <ProjectBuildFilesDialog
        open={generateDialog}
        onClose={() => setGenerateDialog(false)}
      />
    </AppHeader>
  );
};
