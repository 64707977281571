import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fab, List, Popover, PopoverOrigin } from '@mui/material';
import {
  VisibilityRounded as SettingsIcon,
  CameraswitchRounded as ResetCameraIcon,
} from '@mui/icons-material';
import { SxProps, Theme } from '@mui/system';

import {
  AppButtonListItem,
  AppCheckboxListItem,
  AppDividerListItem,
} from 'components/AppListItem';
import { useSelector, useDispatch, useKeyboardShortcut } from 'hooks';
import {
  ModelPreviewSettings,
  selectModelPreviewSettings,
  setModelPreviewSetting,
} from 'slices/editorSlice';

export interface Props {
  onResetCamera: () => void;
  settingsAnchorOrigin: PopoverOrigin;
  settingsTransformOrigin: PopoverOrigin;
  settingsFabStyle?: SxProps<Theme>;
}

export const App3DViewSettings: FC<Props> = ({
  onResetCamera,
  settingsAnchorOrigin,
  settingsTransformOrigin,
  settingsFabStyle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    startPlate,
    buildTank,
    highlightSelected,
    highlightUnsliced,
    axesHelper,
  } = useSelector(selectModelPreviewSettings);
  const fabRef = useRef<HTMLButtonElement>(null);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const handleToggleOption = useCallback<
    (key: keyof ModelPreviewSettings, value: boolean) => () => void
  >(
    (key, value) => () => {
      dispatch(setModelPreviewSetting({ key, value }));
    },
    [dispatch],
  );

  useKeyboardShortcut(
    { key: 'b', ctrl: true },
    handleToggleOption('buildTank', !buildTank),
  );
  useKeyboardShortcut(
    { key: 'h', ctrl: true },
    handleToggleOption('highlightSelected', !highlightSelected),
  );
  useKeyboardShortcut(
    { key: 'u', ctrl: true },
    handleToggleOption('highlightUnsliced', !highlightUnsliced),
  );
  useKeyboardShortcut({ key: 'r', ctrl: true }, onResetCamera);

  return (
    <>
      <Fab
        size="medium"
        color="primary"
        onClick={() => setSettingsOpen(true)}
        ref={fabRef}
        sx={{
          position: 'absolute',
          bottom: (theme) => theme.spacing(2),
          ...settingsFabStyle,
        }}
      >
        <SettingsIcon />
      </Fab>
      <Popover
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        anchorReference="anchorEl"
        anchorEl={fabRef.current}
        anchorOrigin={settingsAnchorOrigin}
        transformOrigin={settingsTransformOrigin}
        sx={{ mt: -2 }}
      >
        <List
          sx={{
            width: 1,
            maxWidth: 360,
          }}
        >
          <AppCheckboxListItem
            onClick={handleToggleOption('startPlate', !startPlate)}
            checked={startPlate}
            text={t('show_start_plate')}
            helperText={t('show_start_plate_helper', {
              height: 10,
              radius: 50,
            })}
          />
          <AppCheckboxListItem
            onClick={handleToggleOption('buildTank', !buildTank)}
            checked={buildTank}
            text={t('show_build_tank')}
            helperText={t('show_build_tank_helper', {
              height: 120,
              radius: 75,
            })}
          />
          <AppCheckboxListItem
            onClick={handleToggleOption(
              'highlightSelected',
              !highlightSelected,
            )}
            checked={highlightSelected}
            text={t('highlight_selected')}
            helperText={t('highlight_selected_helper')}
          />
          <AppCheckboxListItem
            onClick={handleToggleOption(
              'highlightUnsliced',
              !highlightUnsliced,
            )}
            checked={highlightUnsliced}
            text={t('highlight_unsliced')}
            helperText={t('highlight_unsliced_helper')}
          />
          <AppCheckboxListItem
            onClick={handleToggleOption('axesHelper', !axesHelper)}
            checked={axesHelper}
            text={t('axes')}
            helperText={t('axes_helper')}
          />
          <AppDividerListItem />
          <AppButtonListItem
            onClick={onResetCamera}
            icon={<ResetCameraIcon />}
            text={t('reset_camera')}
          />
        </List>
      </Popover>
    </>
  );
};
