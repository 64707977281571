if (!process.env.REACT_APP_AWS_REGION)
  throw new Error(
    '"REACT_APP_AWS_REGION" is missing from environment variables',
  );
if (!process.env.REACT_APP_COGNITO_CLIENT_ID)
  throw new Error(
    '"REACT_APP_COGNITO_CLIENT_ID" is missing from environment variables',
  );

export const region = process.env.REACT_APP_AWS_REGION;
export const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
