import { FC, useMemo } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';

import {
  TreeItemModel,
  TreeItemObject,
  TreeItemPartition,
} from 'components/ProjectTreeView';
import { Partition, Selection, SlicedModel, SlicedObject } from 'api';
import { useSelector } from 'hooks';
import { selectSelectedPartitions } from 'utils';

interface Props {
  modelInfo: SlicedModel | undefined;
  modelName: string | undefined;
  partitions: Partition<unknown>[];
  selection: Selection;
  objectExposureOrder: number[];
  onAddPartition?: (
    breakpoint: [number, number],
    objectId: number | null,
  ) => void;
  onRemovePartition?: (id: string, effect: 'before' | 'after') => void;
  onUpdatePartition?: (id: string, breakpoint: [number, number]) => void;
  onChangeObjectOrder?: (id: number, steps: number) => void;
  selectable?: boolean;
}

export const ProjectTreeView: FC<Props> = ({
  modelInfo,
  modelName,
  partitions,
  selection,
  objectExposureOrder,
  onAddPartition,
  onRemovePartition,
  onUpdatePartition,
  onChangeObjectOrder,
  selectable,
}) => {
  const selectedPartitions = useSelector(selectSelectedPartitions);

  const sortedObjects = useMemo<SlicedObject[] | undefined>(
    () =>
      modelInfo?.objects.toSorted(
        ({ id: a }, { id: b }) =>
          objectExposureOrder.indexOf(a) - objectExposureOrder.indexOf(b),
      ),
    [modelInfo?.objects, objectExposureOrder],
  );

  const selectedPartitionIds = useMemo(
    () => selectedPartitions.map(({ id }) => id),
    [selectedPartitions],
  );

  if (!modelInfo) return null;

  return (
    <TableContainer sx={{ overflowX: 'hidden' }}>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TreeItemModel
            modelName={modelName}
            {...modelInfo}
            partitions={partitions.filter(({ objectId }) => objectId === null)}
            selected={partitions.every(({ id }) =>
              selectedPartitionIds.includes(id),
            )}
            onAddPartition={
              onAddPartition && selection === Selection.model
                ? (breakpoints) => onAddPartition(breakpoints, null)
                : undefined
            }
            selectable={selectable}
          >
            {selection === Selection.model &&
              partitions
                .filter(({ objectId }) => objectId === null)
                .map((partition, index, list) => (
                  <TreeItemPartition
                    key={partition.id}
                    index={index}
                    parent={modelInfo}
                    partitions={list}
                    selected={selectedPartitionIds.includes(partition.id)}
                    onRemovePartition={onRemovePartition}
                    onUpdatePartition={onUpdatePartition}
                    selectable={selectable}
                  />
                ))}
            {selection === Selection.objects &&
              sortedObjects?.map((parent, key) => {
                const filteredPartitions = partitions.filter(
                  (partition) => partition.objectId === parent.id,
                );
                return (
                  <TreeItemObject
                    key={key}
                    {...parent}
                    partitions={filteredPartitions}
                    selected={filteredPartitions.every(({ id }) =>
                      selectedPartitionIds.includes(id),
                    )}
                    objectExposureOrder={objectExposureOrder}
                    onAddPartition={onAddPartition}
                    selectable={selectable}
                    onChangeObjectOrder={onChangeObjectOrder}
                  >
                    {partitions
                      .filter((partition) => partition.objectId === parent.id)
                      .map((partition, index) => (
                        <TreeItemPartition
                          key={partition.id}
                          index={index}
                          parent={parent}
                          partitions={partitions.filter(
                            (partition) => partition.objectId === parent.id,
                          )}
                          selected={selectedPartitionIds.includes(partition.id)}
                          onRemovePartition={onRemovePartition}
                          onUpdatePartition={onUpdatePartition}
                          selectable={selectable}
                          extraIndent
                        />
                      ))}
                  </TreeItemObject>
                );
              })}
          </TreeItemModel>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
