import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  Stack,
  Drawer,
  Toolbar,
  Button,
} from '@mui/material';
import {
  KeyboardArrowLeftRounded as ExpandIcon,
  ArrowUpwardRounded as HeightUpIcon,
  ArrowDownwardRounded as HeightDownIcon,
  ZoomInRounded as ZoomInIcon,
  ZoomOutRounded as ZoomOutIcon,
} from '@mui/icons-material';

import { AppVerticalSlider } from 'components/AppVerticalSlider';
import { ProjectLayerImageMap } from 'components/ProjectLayerImageMap';
import { AppCheckboxForm } from 'components/AppFormControl';
import { LayerInfo } from 'api';

const DRAWER_EDGE_WIDTH = 130;

interface Props extends DrawerEdgeProps {
  drawerEdgeRef: ForwardedRef<HTMLDivElement>;
  gridLines: boolean;
  onToggleGridLines: () => void;
}

interface DrawerEdgeProps {
  open: boolean;
  onToggle: () => void;
  layer: LayerInfo | undefined;
  totalLayers: number | undefined;
  layerIndex: number;
  onLayerIndexChange: (v: number) => void;
  zoom: number;
  onZoomChange: (v: number) => void;
}

const DrawerEdge = forwardRef<HTMLDivElement, DrawerEdgeProps>(
  (
    {
      open,
      onToggle,
      layer,
      totalLayers,
      layerIndex,
      onLayerIndexChange,
      zoom,
      onZoomChange,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const valueLabelFormat = useCallback<
      (value: number) => number | string | undefined
    >(
      (value) =>
        layer ? `${layer.zTopMillimeter} ${t('unit:millimeter')}` : value,
      [layer, t],
    );

    return (
      <Stack
        ref={ref}
        direction="row"
        sx={{
          position: 'absolute',
          width: DRAWER_EDGE_WIDTH,
          left: -DRAWER_EDGE_WIDTH,
          visibility: 'visible',
          backgroundColor: 'background.paper',
          top: 0,
          bottom: 0,
          pr: 2,
        }}
      >
        <Button onClick={onToggle} color="inherit" sx={{ minWidth: 24 }}>
          <ExpandIcon
            sx={{
              transform: `rotate(${open ? 180 : 0}deg)`,
              transition: ({ transitions }) =>
                transitions.create('transform', {
                  easing: transitions.easing.easeIn,
                  duration: transitions.duration.short,
                }),
            }}
          />
        </Button>
        <AppVerticalSlider
          value={layerIndex}
          onChange={onLayerIndexChange}
          icons={{ increment: <HeightUpIcon />, decrement: <HeightDownIcon /> }}
          keyboardShortcuts={{
            increment: { key: 'w' },
            decrement: { key: 's' },
          }}
          min={1}
          max={totalLayers}
          valueLabelFormat={valueLabelFormat}
        />
        <AppVerticalSlider
          value={zoom}
          onChange={onZoomChange}
          icons={{ increment: <ZoomInIcon />, decrement: <ZoomOutIcon /> }}
          keyboardShortcuts={{
            increment: { key: 'i' },
            decrement: { key: 'o' },
          }}
          min={1}
          max={10}
          step={0.5}
          marks
        />
      </Stack>
    );
  },
);
DrawerEdge.displayName = 'DrawerEdge';

export const BuildPreviewDrawer = forwardRef<HTMLDivElement, Props>(
  ({ drawerEdgeRef, gridLines, onToggleGridLines, ...props }, ref) => {
    const { t } = useTranslation();

    const layerInfo = useMemo<
      { name: string; value: number | string | undefined }[]
    >(() => {
      return props.layer
        ? [
            {
              name: 'layer_number',
              value: props.totalLayers
                ? `${props.layerIndex}/${props.totalLayers}`
                : props.layerIndex,
            },
            { name: 'build_height', value: props.layer.zTopMillimeter },
            {
              name: 'energy_of_layer',
              value: props.layer.energyJoule
                ? Math.floor(props.layer.energyJoule * 10e2) / 10e2
                : undefined,
            },
            { name: 'melt_time', value: props.layer.timeSecond },
            {
              name: 'melt_time_total',
              value: props.layer.timeAccumulatedSecond,
            },
          ]
        : [];
    }, [props.layer, props.layerIndex, props.totalLayers]);

    return (
      <Drawer
        ref={ref}
        open={props.open}
        anchor="right"
        variant="persistent"
        sx={{
          width: { xs: `calc(100vw - ${DRAWER_EDGE_WIDTH}px)`, sm: '300px' },
          flexShrink: 0,
        }}
        PaperProps={{
          sx: {
            width: { xs: `calc(100vw - ${DRAWER_EDGE_WIDTH}px)`, sm: '300px' },
            boxSizing: 'border-box',
            overflow: 'visible',
          },
        }}
      >
        <Toolbar />
        <Stack
          flex={1}
          position="relative"
          p={2}
          pt={({ spacing }) => ({
            xs: `calc(${spacing(2)} + 55px)`,
            sm: 2,
          })}
        >
          <DrawerEdge ref={drawerEdgeRef} {...props} />
          <Stack component={Paper} flex={1} p={2}>
            <Typography variant="h6">{t('layer_info')}</Typography>
            {layerInfo.map(({ name, value }) => (
              <Typography key={name}>
                {t(name, { arg: value ?? '-' })}
              </Typography>
            ))}
          </Stack>
          <Stack component={Paper} p={2} mt={2}>
            <AppCheckboxForm
              label={t('grid_lines')}
              checked={gridLines}
              onChange={onToggleGridLines}
            />
          </Stack>
          <Stack component={Paper} p={2} mt={2}>
            <ProjectLayerImageMap />
          </Stack>
        </Stack>
      </Drawer>
    );
  },
);
BuildPreviewDrawer.displayName = 'BuildPreviewDrawer';
