import { store } from 'utils';
import { UpdateProcessSteps, UpdateProject } from 'api';

interface ProjectReduxState {
  projectSettings: UpdateProject;
  processSteps: UpdateProcessSteps;
}

export const getProjectReduxState = (): ProjectReduxState => {
  const {
    projectSettings,
    startHeat,
    jumpSafe,
    spatterSafe,
    melt,
    heatBalance,
  } = store.getState();
  return {
    projectSettings,
    processSteps: { startHeat, jumpSafe, spatterSafe, melt, heatBalance },
  };
};
