import { useEffect, useMemo } from 'react';
import { useBlocker as useRouterBlocker } from 'react-router-dom';

type UseBlocker = (active?: boolean) => {
  isBlocked: boolean;
  onLeave: (() => void) | undefined;
  onStay: (() => void) | undefined;
};

export const useBlocker: UseBlocker = (active = true) => {
  useEffect(() => {
    if (active) {
      const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
        event.preventDefault();
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [active]);

  const {
    state,
    proceed: onLeave,
    reset: onStay,
  } = useRouterBlocker(() => active);

  return useMemo(
    () => ({ isBlocked: state === 'blocked', onLeave, onStay }),
    [onLeave, onStay, state],
  );
};
