import { FC, useEffect } from 'react';
import { Box } from '@mui/material';

import { ProjectPanelMenu } from 'components/ProjectPanelMenu';
import { App3DView } from 'components/App3DView';
import { ModelPreview } from 'api';
import { selectSelectedPartitions, subtract } from 'utils';
import { useDispatch, useSelector } from 'hooks';
import {
  selectModelPreviewSettings,
  setModelHighlights,
} from 'slices/editorSlice';
import { selectProjectSettings } from 'slices/projectSettingsSlice';

interface Props {
  model: ModelPreview;
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectModelPreview: FC<Props> = ({
  model,
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const selectedPartitions = useSelector(selectSelectedPartitions);
  const { transformMillimeter } = useSelector(selectProjectSettings);
  const modelPreviewSettings = useSelector(selectModelPreviewSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setModelHighlights(
        selectedPartitions.map(
          ({ id, startZMillimeter, endZMillimeter, objectId }) => ({
            id,
            startZMillimeter: subtract(
              startZMillimeter,
              model.sliceInfo.layerThicknessMillimeter,
            ),
            endZMillimeter,
            objectId,
          }),
        ),
      ),
    );
  }, [dispatch, model.sliceInfo.layerThicknessMillimeter, selectedPartitions]);

  return (
    <Box component="div" height={1} width={1}>
      <ProjectPanelMenu
        userHasWritePermission={userHasWritePermission}
        generateHasInitiated={generateHasInitiated}
      />
      <App3DView
        model={model}
        settings={{
          settingsAnchorOrigin: { vertical: 'top', horizontal: 'center' },
          settingsTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
          settingsFabStyle: { right: '50%' },
        }}
        modelTransform={transformMillimeter}
        modelPreviewSettings={modelPreviewSettings}
      />
    </Box>
  );
};
