import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { PlayArrowRounded as StartIcon } from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import {
  useSelector,
  usePartitionStates,
  useSaveProject,
  useSnackbar,
} from 'hooks';
import { isValidUpdateProject, startGenerate } from 'api';
import { selectProjectSettings } from 'slices/projectSettingsSlice';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const StartGenerateDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { allPartitionsAreSaved } = usePartitionStates();
  const projectSettingsValues = useSelector(selectProjectSettings);
  const { onSaveProject, isLoading: isSavingProject } = useSaveProject();

  const validationMessage = useMemo<
    { info: string; severity: 'warning' | 'error' } | undefined
  >(() => {
    if (!isValidUpdateProject(projectSettingsValues)) {
      return {
        info: 'project_generate_validation_warning_invalid_values',
        severity: 'error',
      };
    }
    return;
  }, [projectSettingsValues]);

  const { mutate: onStartGenerate, isPending: isStartingGenerate } =
    useMutation({
      mutationFn: () => {
        if (!allPartitionsAreSaved)
          throw new Error('some partitions are not saved');
        return startGenerate(projectId);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['project', { projectId }],
        });
        void queryClient.invalidateQueries({
          queryKey: ['project-settings', { projectId }],
        });
        void queryClient.invalidateQueries({
          queryKey: ['generate', { projectId }],
        });
        enqueueSnackbar({
          key: `start_generate_success_${Date.now()}`,
          message: t('start_generate_success'),
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar({
          key: `start_generate_fail_${Date.now()}`,
          message: t('start_generate_fail'),
          variant: 'error',
          persist: true,
        });
      },
    });

  const handleStartGenerate = useCallback<() => void>(() => {
    onSaveProject()
      .then(() => onStartGenerate())
      .catch(console.error);
  }, [onSaveProject, onStartGenerate]);

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={t('generating_build_files')}
        actions={
          <Button
            variant="contained"
            startIcon={<StartIcon />}
            onClick={handleStartGenerate}
            fullWidth
            disabled={isStartingGenerate || isSavingProject}
          >
            {t('start')}
          </Button>
        }
        dismissIcon="minimize"
        dialogProps={{ maxWidth: 'md' }}
      >
        <Typography paragraph>{t('start_generate_confirmation')}</Typography>
        {validationMessage && (
          <Typography color={`${validationMessage.severity}.main`}>
            {t(validationMessage.severity)}: {t(validationMessage.info)}
          </Typography>
        )}
      </AppDialog>
    </>
  );
};
