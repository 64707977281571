import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Box, Popover, SvgIconProps, useTheme } from '@mui/material';

import { HomeRoute } from 'layouts/Private';
import { AppButtonListItem } from 'components/AppListItem';
import { UserPreviewWithEtag } from 'api';

interface Props {
  groupTitle: HomeRoute['title'];
  Icon: FC<SvgIconProps>;
  items: HomeRoute[];
  expanded: boolean;
}

export const AppSidebarMenuGroup: FC<Props> = ({
  groupTitle,
  Icon,
  items,
  expanded,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [expandedGroup, setExpandedGroup] = useState(true);

  const { data: currentUser } = useQuery<UserPreviewWithEtag>({
    queryKey: ['current-user'],
  });

  const filteredItems = useMemo<HomeRoute[]>(
    () =>
      items.filter(
        ({ claims }) => currentUser && claims.includes(currentUser.data.type),
      ),
    [currentUser, items],
  );

  const handleClick = useCallback<(event: MouseEvent<HTMLDivElement>) => void>(
    (event) => {
      if (expanded) {
        setExpandedGroup((prevState) => !prevState);
      } else {
        setAnchorEl((prevState) => (!prevState ? event.currentTarget : null));
      }
    },
    [expanded],
  );

  const isSelected = useCallback<(path: string) => boolean>(
    (path) => location.pathname === path || location.pathname === `${path}/`,
    [location.pathname],
  );
  return (
    <Box
      component="div"
      sx={{ pt: expanded ? 4 : 0, transition: 'padding 0.5s' }}
    >
      <AppButtonListItem
        onClick={handleClick}
        text={t(groupTitle).toUpperCase()}
        icon={<Icon />}
        color={
          filteredItems.some(({ path }) => isSelected(path))
            ? palette.primary.main
            : palette.text.primary
        }
      />
      {filteredItems.map(({ title, path }) => (
        <Box
          component="div"
          key={title}
          sx={{
            overflow: 'hidden',
            maxHeight: expanded && expandedGroup ? items.length * 48 : 0,
            transition: 'max-height 0.5s',
          }}
        >
          <AppButtonListItem
            onClick={() => navigate(path)}
            text={t(title)}
            selected={isSelected(path)}
            icon={null}
          />
        </Box>
      ))}
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        slotProps={{ paper: { sx: { minWidth: 100 } } }}
        sx={{ ml: 2 }}
      >
        {filteredItems.map(({ title, path }) => (
          <AppButtonListItem
            key={path}
            onClick={() => {
              navigate(path);
              setAnchorEl(null);
            }}
            text={t(title)}
            selected={location.pathname === path}
          />
        ))}
      </Popover>
    </Box>
  );
};
