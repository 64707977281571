import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import {
  SaveRounded as SaveIcon,
  DoNotDisturbRounded as DoNotSaveIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppButtonForm } from 'components/AppFormControl';
import { GenerateState, ProjectState } from 'api';
import { useDispatch, useKeyboardShortcut, useSaveProject } from 'hooks';
import { resetEditor } from 'slices/editorSlice';
import { resetProjectSettings } from 'slices/projectSettingsSlice';
import { resetHeatBalancePartitions } from 'slices/heatBalanceSlice';
import { resetJumpSafePartitions } from 'slices/jumpSafeSlice';
import { resetMeltPartitions } from 'slices/meltSlice';
import { resetSpatterSafePartitions } from 'slices/spatterSafeSlice';
import { resetStartHeatPartitions } from 'slices/startHeatSlice';

interface Props {
  open: boolean;
  onStay: (() => void) | undefined;
  onLeave: (() => void) | undefined;
}

export const CloseProjectDialog: FC<Props> = ({ open, onStay, onLeave }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const dispatch = useDispatch();
  const { onSaveProject, isLoading: isSavingProject } = useSaveProject();
  const queryClient = useQueryClient();

  const { data: generateState } = useQuery<GenerateState>({
    queryKey: ['generate', { projectId }],
  });

  const notGenerated = useMemo<boolean>(
    () => generateState?.projectState === ProjectState.NotGenerated,
    [generateState?.projectState],
  );

  const keyboardShortcutsDisabled = useMemo<boolean>(
    () => !open || !notGenerated,
    [open, notGenerated],
  );

  const handleCloseProject = useCallback((): void => {
    dispatch(resetStartHeatPartitions());
    dispatch(resetJumpSafePartitions());
    dispatch(resetSpatterSafePartitions());
    dispatch(resetMeltPartitions());
    dispatch(resetHeatBalancePartitions());
    dispatch(resetProjectSettings());
    dispatch(resetEditor());
    queryClient.removeQueries({
      queryKey: ['project-settings', { projectId }],
    });
    queryClient.removeQueries({
      queryKey: ['process-steps', { projectId }],
    });
    onLeave?.();
  }, [dispatch, onLeave, projectId, queryClient]);

  const handleSaveAndCloseProject = useCallback((): void => {
    if (!isSavingProject)
      onSaveProject().then(handleCloseProject).catch(console.error);
  }, [handleCloseProject, isSavingProject, onSaveProject]);

  useKeyboardShortcut(
    { key: 'n' },
    handleCloseProject,
    keyboardShortcutsDisabled,
  );
  useKeyboardShortcut(
    { key: 'y' },
    handleSaveAndCloseProject,
    keyboardShortcutsDisabled,
  );

  return (
    <AppDialog
      open={open}
      onClose={onStay}
      title={t('close_project')}
      actions={
        notGenerated ? (
          <>
            <AppButtonForm
              label={t('close_project_without_saving')}
              helperText={t('close_project_without_saving_helper')}
              componentProps={{
                onClick: handleCloseProject,
                startIcon: <DoNotSaveIcon />,
                disabled: isSavingProject,
              }}
            />
            <AppButtonForm
              label={t('close_project_and_saving')}
              helperText={t('close_project_and_saving_helper')}
              componentProps={{
                onClick: handleSaveAndCloseProject,
                startIcon: <SaveIcon />,
                variant: 'contained',
                color: 'primary',
                disabled: isSavingProject,
              }}
            />
          </>
        ) : (
          <AppButtonForm
            label={t('close_project')}
            helperText={t('close_project_helper')}
            componentProps={{
              onClick: handleCloseProject,
              startIcon: <CloseIcon />,
              variant: 'contained',
              color: 'primary',
              autoFocus: true,
            }}
          />
        )
      }
    >
      <Typography>
        {t(
          notGenerated
            ? 'close_project_confirmation_unsaved'
            : 'close_project_confirmation',
        )}
      </Typography>
    </AppDialog>
  );
};
