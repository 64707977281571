import {
  ODataRequestParams,
  ODataResponse,
  FilteredResponse,
  ResponseWithEtag,
} from 'api';

export enum UserRole {
  User = 'User',
  OrganizationAdmin = 'OrganizationAdmin',
  SuperAdmin = 'SuperAdmin',
}

export type CognitoStatus =
  | 'UNCONFIRMED'
  | 'CONFIRMED'
  | 'ARCHIVED'
  | 'COMPROMISED'
  | 'UNKNOWN'
  | 'RESET_REQUIRED'
  | 'FORCE_CHANGE_PASSWORD';

export interface User {
  readonly id: string;
  readonly email: string;
  readonly type: UserRole;
  readonly cognitoStatus: CognitoStatus;
}

export type UserRequestParams = ODataRequestParams<User>;
export type ODataUsers = ODataResponse<User>;
export type FilteredUsers = FilteredResponse<User>;

export interface UserPreview extends User {
  readonly organizationId: string;
  readonly cognitoId: string;
}

export type UserPreviewWithEtag = ResponseWithEtag<UserPreview>;

export interface CreateUser {
  email: string;
  password: string;
  type: UserRole;
}

export interface UpdateUser {
  email: string;
  type: UserRole;
}
