import { useCallback, useMemo } from 'react';
import {
  AuthenticationResultType,
  ChangePasswordCommand,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  InitiateAuthCommand,
  ResendConfirmationCodeCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import axios from 'axios';

import { region, clientId } from 'configs/aws';
import { useDispatch } from 'hooks';
import { User, setUser } from 'slices/userSlice';

interface IdToken extends Required<JwtPayload> {
  auth_time: number;
  'cognito:username': string;
  email: string;
  email_verified: boolean;
  event_id: string;
  origin_jti: string;
  token_use: string;
}

const client = new CognitoIdentityProviderClient({ region });
const ACCESS_TOKEN_EXPIRATION = 3600; // expiration time of access token in seconds
const EXPIRATION_MARGIN = 300; // seconds before access token needs to be refreshed

export const useAuthentication = () => {
  const dispatch = useDispatch();

  const authenticateWithPassword = useCallback(
    async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }): Promise<AuthenticationResultType> => {
      const { AuthenticationResult: result, ChallengeName } = await client.send(
        new InitiateAuthCommand({
          AuthFlow: 'USER_PASSWORD_AUTH',
          ClientId: clientId,
          AuthParameters: { USERNAME: email, PASSWORD: password },
        }),
      );

      if (ChallengeName === 'NEW_PASSWORD_REQUIRED')
        throw new Error('new-password-required');

      if (result) return result;
      throw new Error('authentication-failed');
    },
    [],
  );

  const authenticateWithRefreshToken =
    useCallback(async (): Promise<AuthenticationResultType> => {
      const refreshToken = sessionStorage.getItem('refreshToken');
      if (!refreshToken) throw new Error('refresh-token-missing');
      const { AuthenticationResult: result } = await client.send(
        new InitiateAuthCommand({
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: clientId,
          AuthParameters: { REFRESH_TOKEN: refreshToken },
        }),
      );

      if (result) return result;
      throw new Error('authentication-failed');
    }, []);

  const getUserFromIdToken = useCallback(
    (token: string | undefined): User | null => {
      if (!token) return null;
      const {
        sub: id,
        email,
        email_verified: verified,
      } = jwtDecode<IdToken>(token);
      return { id, email, verified };
    },
    [],
  );

  const signIn = useCallback(
    async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }): Promise<AuthenticationResultType> => {
      try {
        const result = await authenticateWithPassword({ email, password });

        if (result.IdToken) sessionStorage.setItem('idToken', result.IdToken);
        if (result.AccessToken) {
          sessionStorage.setItem('accessToken', result.AccessToken);
          axios.defaults.headers.common.Authorization = `Bearer ${result.AccessToken}`;
        }
        if (result.RefreshToken)
          sessionStorage.setItem('refreshToken', result.RefreshToken);

        dispatch(setUser(getUserFromIdToken(result.IdToken)));
        return result;
      } catch (error) {
        dispatch(setUser(null));
        throw error;
      }
    },
    [authenticateWithPassword, dispatch, getUserFromIdToken],
  );

  const signOut = useCallback((): void => {
    sessionStorage.clear();
    dispatch(setUser(null));
  }, [dispatch]);

  const confirmSignUp = useCallback(
    async ({ email, code }: { email: string; code: string }): Promise<void> => {
      await client.send(
        new ConfirmSignUpCommand({
          ClientId: clientId,
          ConfirmationCode: code,
          Username: email,
        }),
      );
    },
    [],
  );

  const forgotPassword = useCallback(async (email: string): Promise<void> => {
    await client.send(
      new ForgotPasswordCommand({ ClientId: clientId, Username: email }),
    );
  }, []);

  const confirmForgotPassword = useCallback(
    async ({
      email,
      password,
      code,
    }: {
      email: string;
      password: string;
      code: string;
    }): Promise<void> => {
      await client.send(
        new ConfirmForgotPasswordCommand({
          ClientId: clientId,
          ConfirmationCode: code,
          Username: email,
          Password: password,
        }),
      );
    },
    [],
  );

  const resendConfirmationCode = useCallback(
    async ({ email }: { email: string }): Promise<void> => {
      await client.send(
        new ResendConfirmationCodeCommand({
          ClientId: clientId,
          Username: email,
        }),
      );
    },
    [],
  );

  const changePassword = useCallback(
    async ({
      email,
      oldPassword,
      newPassword,
    }: {
      email: string;
      oldPassword: string;
      newPassword: string;
    }): Promise<void> => {
      const { AccessToken } = await signIn({ email, password: oldPassword });
      await client.send(
        new ChangePasswordCommand({
          AccessToken,
          PreviousPassword: oldPassword,
          ProposedPassword: newPassword,
        }),
      );
    },
    [signIn],
  );

  const getSession = useCallback(async (): Promise<void> => {
    const result = await authenticateWithRefreshToken();

    if (result.IdToken) sessionStorage.setItem('idToken', result.IdToken);
    if (result.AccessToken) {
      sessionStorage.setItem('accessToken', result.AccessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${result.AccessToken}`;
    }
    if (result.RefreshToken)
      sessionStorage.setItem('refreshToken', result.RefreshToken);

    dispatch(setUser(getUserFromIdToken(result.IdToken)));
  }, [authenticateWithRefreshToken, dispatch, getUserFromIdToken]);

  const startRefreshSessionInterval = useCallback((): NodeJS.Timeout => {
    return setInterval(
      () => void getSession(),
      (ACCESS_TOKEN_EXPIRATION - EXPIRATION_MARGIN) * 1000,
    );
  }, [getSession]);

  return useMemo(
    () => ({
      authenticateWithPassword,
      signIn,
      signOut,
      confirmSignUp,
      forgotPassword,
      confirmForgotPassword,
      resendConfirmationCode,
      changePassword,
      getSession,
      startRefreshSessionInterval,
    }),
    [
      authenticateWithPassword,
      signIn,
      signOut,
      confirmSignUp,
      forgotPassword,
      confirmForgotPassword,
      resendConfirmationCode,
      changePassword,
      getSession,
      startRefreshSessionInterval,
    ],
  );
};
