import { useMemo } from 'react';
import {
  Color,
  MeshPhongMaterial,
  MeshPhongMaterialParameters,
  WebGLProgramParametersWithUniforms,
  Vector2,
  WebGLRenderer,
} from 'three';

export const useMaterial = ({
  color,
  onBeforeCompile,
  parameters,
}: {
  color: Color;
  onBeforeCompile?: (
    parameters: WebGLProgramParametersWithUniforms,
    renderer: WebGLRenderer,
  ) => void;
  parameters?: Omit<MeshPhongMaterialParameters, 'color'>;
}): MeshPhongMaterial => {
  return useMemo<MeshPhongMaterial>(() => {
    const material = new MeshPhongMaterial({
      alphaMap: null,
      alphaToCoverage: false,
      aoMap: null,
      aoMapIntensity: 1,
      blendDst: 205,
      // blendDstAlpha: undefined,
      blendEquation: 100,
      // blendEquationAlpha: undefined,
      blendSrc: 204,
      // blendSrcAlpha: undefined,
      blending: 1,
      bumpMap: null,
      bumpScale: 1,
      clipIntersection: false,
      clipShadows: false,
      // clippingPlanes: undefined,
      color,
      colorWrite: true,
      combine: 0,
      depthFunc: 3,
      depthTest: true,
      depthWrite: true,
      displacementBias: 0,
      displacementMap: null,
      displacementScale: 1,
      dithering: false,
      emissive: new Color(0, 0, 0),
      emissiveIntensity: 1,
      emissiveMap: null,
      envMap: null,
      flatShading: true,
      fog: true,
      lightMap: null,
      lightMapIntensity: 1,
      map: null,
      name: '',
      normalMap: null,
      normalMapType: 0,
      normalScale: new Vector2(1, 1),
      opacity: 1,
      polygonOffset: false,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
      precision: null,
      premultipliedAlpha: false,
      reflectivity: 1,
      refractionRatio: 0.98,
      shininess: 30,
      side: 0,
      specular: new Color(0.01, 0.01, 0.01),
      specularMap: null,
      stencilFail: 7680,
      stencilFunc: 519,
      stencilFuncMask: 255,
      stencilRef: 0,
      stencilWrite: false,
      stencilWriteMask: 255,
      stencilZFail: 7680,
      stencilZPass: 7680,
      toneMapped: true,
      transparent: false,
      userData: {},
      vertexColors: false,
      visible: true,
      wireframe: false,
      wireframeLinecap: 'round',
      wireframeLinejoin: 'round',
      wireframeLinewidth: 1,
      ...parameters,
    });
    if (onBeforeCompile) material.onBeforeCompile = onBeforeCompile;
    return material;
  }, [color, onBeforeCompile, parameters]);
};
