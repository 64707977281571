import { FC, ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';

import { baseUrl } from 'configs/api';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
      gcTime: Infinity,
    },
  },
});

interface Props {
  enableDevtools?: boolean;
  children: ReactNode;
}

export const QueryProvider: FC<Props> = ({ enableDevtools, children }) => {
  useEffect(() => {
    axios.defaults.baseURL = baseUrl;
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {enableDevtools && <ReactQueryDevtools />}
      {children}
    </QueryClientProvider>
  );
};
