import { useMemo } from 'react';

import { useSelector } from 'hooks';
import { selectAllMeltPartitionsAreSaved } from 'slices/meltSlice';
import { selectAllSpatterSafePartitionsAreSaved } from 'slices/spatterSafeSlice';
import { selectAllJumpSafePartitionsAreSaved } from 'slices/jumpSafeSlice';
import { selectAllHeatBalancePartitionsAreSaved } from 'slices/heatBalanceSlice';
import { selectStartHeatSavedValue } from 'slices/startHeatSlice';

export const usePartitionStates = () => {
  const startHeatSavedValue = useSelector(selectStartHeatSavedValue);
  const allJumpSafePartitionsAreSaved = useSelector(
    selectAllJumpSafePartitionsAreSaved,
  );
  const allSpatterSafePartitionsAreSaved = useSelector(
    selectAllSpatterSafePartitionsAreSaved,
  );
  const allMeltPartitionsAreSaved = useSelector(
    selectAllMeltPartitionsAreSaved,
  );
  const allHeatBalancePartitionsAreSaved = useSelector(
    selectAllHeatBalancePartitionsAreSaved,
  );

  return useMemo(
    () => ({
      allPartitionsAreSaved:
        !!startHeatSavedValue &&
        allJumpSafePartitionsAreSaved &&
        allSpatterSafePartitionsAreSaved &&
        allMeltPartitionsAreSaved &&
        allHeatBalancePartitionsAreSaved,
    }),
    [
      allHeatBalancePartitionsAreSaved,
      allJumpSafePartitionsAreSaved,
      allMeltPartitionsAreSaved,
      allSpatterSafePartitionsAreSaved,
      startHeatSavedValue,
    ],
  );
};
