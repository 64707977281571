import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import exposureOrderColorMap from 'assets/images/exposure-order-color-map.svg';

export const ProjectLayerImageMap: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <Typography fontWeight="bold">{t('exposure_order')}:</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{t('first')}</Typography>
        <Typography>{t('last')}</Typography>
      </Stack>
      <Stack
        sx={{
          height: 50,
          borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          backgroundImage: `url(${exposureOrderColorMap})`,
          backgroundRepeat: 'repeat-y',
          backgroundSize: '100%, 100%',
        }}
      />
      <Typography fontWeight="bold">{t('double_or_outofbounds')}:</Typography>
      <Stack
        sx={{
          height: 50,
          borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          backgroundColor: '#E242E2',
        }}
      />
    </Stack>
  );
};
