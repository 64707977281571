import { FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Link,
  List,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ExpandMoreRounded as ExpandIcon } from '@mui/icons-material';

import { AppButtonListItem } from 'components/AppListItem';
import { AppButton } from 'components/AppButton';

interface UserGuideParagraph {
  title?: string;
  text?: string;
  image?: string;
}

const userGuideTopics = [
  'introduction',
  'organizations',
  'projects',
  'process_steps',
  'exposure_settings',
  'spot_spread_methods',
  'build_files',
  'presets',
  'models',
  'tooltips',
  'notifications',
  'contact',
  'keyboard_shortcuts',
];

export const UserGuide: FC = () => {
  const { t, i18n } = useTranslation('userGuide');
  const { palette, breakpoints } = useTheme();
  const smallScreenSize = useMediaQuery(breakpoints.down('md'), {
    noSsr: true,
  });
  const { hash } = useLocation();
  const [expanded, setExpanded] = useState(true);

  const userGuide = useMemo<Record<string, UserGuideParagraph[]>>(() => {
    const result: Record<string, UserGuideParagraph[]> = {};
    userGuideTopics.forEach((topic) => {
      result[topic] = t(topic, {
        returnObjects: true,
        defaultValue: [],
      });
    });
    return result;
  }, [t]);

  return (
    <Stack
      height={1}
      overflow="hidden"
      direction={smallScreenSize ? 'column' : 'row'}
      m={2}
    >
      <Stack
        component={Paper}
        sx={{
          width: { xs: 1, md: 250 },
          maxHeight: { xs: expanded ? 1 : 54, md: 1 },
          height: { xs: expanded ? 1 : 54, md: 1 },
          mr: { xs: 0, md: 2 },
          mb: { xs: expanded ? 0 : 2, md: 0 },
          transition: 'all 0.5s',
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" justifyContent="space-between" px={5} py={2}>
          <Typography variant="h4">{t('common:user-guide')}</Typography>
          <AppButton
            title={t('common:expand')}
            buttonProps={{
              onClick: () => setExpanded((prevState) => !prevState),
            }}
            buttonSx={{ display: { xs: 'block', md: 'none' } }}
          >
            <ExpandIcon
              sx={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          </AppButton>
        </Stack>
        <List
          sx={{
            overflowX: 'hidden',
            overflowY: { xs: expanded ? 'auto' : 'hidden', md: 'auto' },
          }}
        >
          {Object.entries(userGuide).map(([topic, paragraphs], i) => (
            <Fragment key={i}>
              <Link href={`#${encodeURI(topic)}`}>
                <AppButtonListItem
                  text={t(`topics.${topic}`)}
                  onClick={() => setExpanded(false)}
                  color={
                    hash === `#${encodeURI(topic)}`
                      ? palette.primary.main
                      : palette.text.primary
                  }
                />
              </Link>
              {paragraphs.map(({ title }, j) =>
                title ? (
                  <Link
                    key={`${i}${j}`}
                    href={`#${encodeURI(`${topic}.${title}`)}`}
                  >
                    <AppButtonListItem
                      text={title}
                      onClick={() => setExpanded(false)}
                      color={
                        hash === `#${encodeURI(`${topic}.${title}`)}`
                          ? palette.primary.main
                          : palette.text.primary
                      }
                      indent
                    />
                  </Link>
                ) : null,
              )}
            </Fragment>
          ))}
        </List>
      </Stack>
      <Paper
        sx={{
          flex: 1,
          height: { xs: expanded ? 0 : 1, md: 1 },
          maxHeight: { xs: expanded ? 0 : 1, md: 1 },
          p: { xs: expanded ? 0 : 4, md: 4 },
          transition: 'all 0.5s',
          overflow: 'hidden auto',
          scrollBehavior: 'smooth',
        }}
      >
        {Object.entries(userGuide).map(([topic, paragraphs], i) => (
          <Stack key={i} alignItems="flex-start">
            <Link
              component={Button}
              id={encodeURI(topic)}
              href={`#${encodeURI(topic)}`}
              sx={{ textTransform: 'none', minWidth: 0 }}
              variant="h4"
            >
              {t(`topics.${topic}`)}
            </Link>
            {paragraphs.map(({ title, text, image }, j) => (
              <Stack
                key={`${i}${j}`}
                alignItems="flex-start"
                pb={4}
                width={1}
                spacing={2}
              >
                {title && (
                  <Link
                    component={Button}
                    id={encodeURI(`${topic}.${title}`)}
                    href={`#${encodeURI(`${topic}.${title}`)}`}
                    sx={{ textTransform: 'none', minWidth: 0 }}
                    variant="h6"
                  >
                    {title}
                  </Link>
                )}
                {text && (
                  <Typography px={6} maxWidth={1500} align="justify">
                    {text}
                  </Typography>
                )}
                {image && (
                  <img
                    src={`/user-guide/${i18n.language}/${image}`}
                    style={{ maxWidth: '80%', alignSelf: 'center' }}
                    alt={image}
                  />
                )}
              </Stack>
            ))}
          </Stack>
        ))}
      </Paper>
    </Stack>
  );
};
