import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Stack } from '@mui/material';

import { ProjectHeader } from 'components/ProjectHeader';
import { ProjectViewMenu } from 'components/ProjectViewMenu';
import { ProjectModelPreview } from 'components/ProjectModelPreview';
import { ProjectBuildPreview } from 'components/ProjectBuildPreview';
import { AppTransition } from 'components/AppTransition';
import { CloseProjectDialog } from 'components/ProjectQuickSettings';
import {
  getGenerateState,
  getProjectProcessSteps,
  getProjectSettings,
  ProjectState,
  UserPreviewWithEtag,
} from 'api';
import { checkWritePermission } from 'utils';
import { useSelector, useDispatch, useSnackbar, useBlocker } from 'hooks';
import { View, selectView } from 'slices/editorSlice';
import { loadProjectSettings } from 'slices/projectSettingsSlice';
import { loadHeatBalance } from 'slices/heatBalanceSlice';
import { loadJumpSafe } from 'slices/jumpSafeSlice';
import { loadMelt } from 'slices/meltSlice';
import { loadSpatterSafe } from 'slices/spatterSafeSlice';
import { loadStartHeat } from 'slices/startHeatSlice';

const REFETCH_INTERVAL = 60000; // in milliseconds

export const Project: FC = () => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const view = useSelector(selectView);
  const [isInitialState, setIsInitialState] = useState(true);
  const [isRefetchingState, setIsRefetchingState] = useState(false);

  const { data: project, isError: isProjectError } = useQuery({
    queryKey: ['project-settings', { projectId }],
    queryFn: () => getProjectSettings(projectId),
  });

  useEffect(() => {
    if (isProjectError) {
      enqueueSnackbar({
        key: `get_project_settings_fail_${Date.now()}`,
        message: t('get_project_settings_fail'),
        variant: 'error',
        persist: true,
      });
      navigate('/projects');
    }
  }, [enqueueSnackbar, isProjectError, navigate, t]);

  const { data: processSteps, isError: isProcessStepsError } = useQuery({
    queryKey: ['process-steps', { projectId }],
    queryFn: () => getProjectProcessSteps(projectId),
  });

  useEffect(() => {
    if (isProcessStepsError) {
      enqueueSnackbar({
        key: `get_project_process_steps_fail_${Date.now()}`,
        message: t('get_project_process_steps_fail'),
        variant: 'error',
        persist: true,
      });
      navigate('/projects');
    }
  }, [enqueueSnackbar, isProcessStepsError, navigate, t]);

  const {
    data: generateState,
    isError: isGenerateStateError,
    isSuccess: isGenerateStateSuccess,
  } = useQuery({
    queryKey: ['generate', { projectId }],
    queryFn: () => getGenerateState(projectId),
    enabled: !!projectId,
    refetchInterval: isRefetchingState ? REFETCH_INTERVAL : false,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    if (isGenerateStateError)
      enqueueSnackbar({
        key: 'generate_state_fail',
        message: t('generate_state_fail'),
        variant: 'error',
      });
  }, [enqueueSnackbar, isGenerateStateError, t]);

  useEffect(() => {
    if (isGenerateStateSuccess && generateState) {
      closeSnackbar('generate_state_fail');
      if (!isInitialState) {
        if (generateState.projectState === ProjectState.GeneratedSuccessfully) {
          enqueueSnackbar({
            key: 'generate_complete',
            message: t('generate_complete'),
            variant: 'success',
            persist: true,
          });
        }
        if (generateState.projectState === ProjectState.GenerationFailed) {
          enqueueSnackbar({
            key: 'generate_fail',
            message: t('generate_fail'),
            variant: 'error',
            persist: true,
          });
        }
      }
      setIsInitialState(false);
    }
  }, [
    closeSnackbar,
    enqueueSnackbar,
    generateState,
    isGenerateStateSuccess,
    isInitialState,
    t,
  ]);

  const generateHasInitiated = useMemo<boolean>(
    () => generateState?.projectState !== ProjectState.NotGenerated,
    [generateState?.projectState],
  );

  const { data: currentUser } = useQuery<UserPreviewWithEtag>({
    queryKey: ['current-user'],
  });

  const userHasWritePermission = useMemo<boolean>(
    () => checkWritePermission(currentUser?.data, project?.data.createdBy),
    [currentUser?.data, project?.data.createdBy],
  );

  useEffect(() => {
    setIsRefetchingState(
      generateState?.projectState === ProjectState.GenerationQueued ||
        generateState?.projectState === ProjectState.Generating,
    );
  }, [generateState?.projectState]);

  useEffect(() => {
    if (project) {
      dispatch(loadProjectSettings(project.data));
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (processSteps) {
      dispatch(loadStartHeat(processSteps.data.startHeat));
      dispatch(loadSpatterSafe(processSteps.data.spatterSafe));
      dispatch(loadJumpSafe(processSteps.data.jumpSafe));
      dispatch(loadMelt(processSteps.data.melt));
      dispatch(loadHeatBalance(processSteps.data.heatBalance));
    }
  }, [dispatch, processSteps]);

  const { isBlocked, onLeave, onStay } = useBlocker();

  if (!project) return <AppTransition />;

  return (
    <>
      <Stack
        bgcolor="background.default"
        width="100vw"
        height="100vh"
        overflow="hidden"
      >
        <ProjectHeader
          projectState={generateState?.projectState}
          userHasWritePermission={userHasWritePermission}
          generateHasInitiated={generateHasInitiated}
        />
        <Box component="div" flex={1} position="relative" overflow="hidden">
          <ProjectViewMenu />
          {view === View.ModelPreview && (
            <ProjectModelPreview
              model={project.data.model}
              userHasWritePermission={userHasWritePermission}
              generateHasInitiated={generateHasInitiated}
            />
          )}
          {view === View.BuildPreview && <ProjectBuildPreview />}
        </Box>
      </Stack>
      <CloseProjectDialog open={isBlocked} onLeave={onLeave} onStay={onStay} />
    </>
  );
};
