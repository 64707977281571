import { Partition } from 'api';
import { isDesktop, isMacOs } from 'react-device-detect';

type EntitySelectionMode = 'single' | 'multiple' | 'extended';

export interface PartitionQuery {
  target: string | number | undefined;
  event?: { metaKey: boolean; ctrlKey: boolean };
}

const extractPartitionSelection = (event?: {
  metaKey: boolean;
  ctrlKey: boolean;
}): EntitySelectionMode => {
  if (isDesktop && event) {
    // if (e.shiftKey) return 'extended' // TODO: Implement extended selection type
    if ((isMacOs && event.metaKey) || (!isMacOs && event.ctrlKey))
      return 'multiple';
  }
  return 'single';
};

export const extractPartitionIds = ({
  query: { event, target },
  selectedPartitionIds,
  partitions,
}: {
  query: PartitionQuery;
  selectedPartitionIds: string[];
  partitions: Partition<unknown>[];
}): string[] => {
  const mode = extractPartitionSelection(event);
  const selected: string[] = [...selectedPartitionIds];
  if (mode === 'single') {
    if (target === undefined) return partitions.map(({ id }) => id);
    if (typeof target === 'string') return [target];
    if (typeof target === 'number')
      return partitions
        .filter(({ objectId }) => target === objectId)
        .map(({ id }) => id);
  }
  if (mode === 'multiple') {
    if (typeof target === 'string')
      return selected.includes(target)
        ? selected.filter((id) => id !== target)
        : [...selected, target];
    else {
      const list: string[] = partitions
        .filter(({ objectId }) => target === undefined || target === objectId)
        .map(({ id }) => id);
      const filteredSelected: string[] = selected.filter(
        (id) => !list.includes(id),
      );
      return list.every((id) => selected.includes(id))
        ? filteredSelected
        : [...filteredSelected, ...list];
    }
  }
  return selected;
};
