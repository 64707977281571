import {
  HeatBalance,
  HeatBalanceType,
  JumpSafe,
  Melt,
  Partition,
  SpatterSafe,
  TemperatureSensor,
} from 'api';

export const isTemperatureSensor = (
  value: unknown,
): value is TemperatureSensor =>
  typeof value === 'string' && Object.keys(TemperatureSensor).includes(value);

export const isHeatBalanceType = (value: unknown): value is HeatBalanceType =>
  typeof value === 'string' && Object.keys(HeatBalanceType).includes(value);

// TODO: Add process step validations
export const isValidJumpSafe = (value: unknown): value is JumpSafe => true;
export const isValidJumpSafePartition = (
  partitions: Partition<unknown>,
): partitions is Partition<JumpSafe> =>
  (!partitions.parameters.draft ||
    isValidJumpSafe(partitions.parameters.draft)) &&
  (!partitions.parameters.saved ||
    isValidJumpSafe(partitions.parameters.saved));

export const isValidSpatterSafe = (value: unknown): value is SpatterSafe =>
  true;
export const isValidSpatterSafePartition = (
  partitions: Partition<unknown>,
): partitions is Partition<SpatterSafe> =>
  (!partitions.parameters.draft ||
    isValidSpatterSafe(partitions.parameters.draft)) &&
  (!partitions.parameters.saved ||
    isValidSpatterSafe(partitions.parameters.saved));

export const isValidMelt = (value: unknown): value is Melt => true;
export const isValidMeltPartition = (
  partitions: Partition<unknown>,
): partitions is Partition<Melt> =>
  (!partitions.parameters.draft || isValidMelt(partitions.parameters.draft)) &&
  (!partitions.parameters.saved || isValidMelt(partitions.parameters.saved));

export const isValidHeatBalance = (value: unknown): value is HeatBalance =>
  true;
export const isValidHeatBalancePartition = (
  partitions: Partition<unknown>,
): partitions is Partition<HeatBalance> =>
  (!partitions.parameters.draft ||
    isValidHeatBalance(partitions.parameters.draft)) &&
  (!partitions.parameters.saved ||
    isValidHeatBalance(partitions.parameters.saved));
