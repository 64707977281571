import {
  ComponentProps,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import {
  FilterListRounded as FilterIcon,
  CheckRounded as ApplyIcon,
  FilterListOffRounded as ClearIcon,
} from '@mui/icons-material';

import { AppButton } from 'components/AppButton';
import { AppDialog } from 'components/AppDialog';
import { AppFormControl } from 'components/AppFormControl';

interface Filter {
  title: string;
  label: string;
  options: { value: string; label?: string }[];
}
type FilterValues = Record<string, string[]>;

export interface Props {
  filters: Filter[];
  activeFilters: FilterValues;
  onFilter: (result: FilterValues) => void;
  buttonProps?: ComponentProps<typeof AppButton>['buttonProps'];
}

export const FilterActionButton = forwardRef<HTMLDivElement, Props>(
  ({ filters, activeFilters, onFilter, buttonProps }, ref) => {
    const { t } = useTranslation();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const active = useMemo<boolean>(
      () => Object.values(activeFilters).some((v) => v.length > 0),
      [activeFilters],
    );

    const [draftFilters, setDraftFilters] =
      useState<FilterValues>(activeFilters);

    const handleClose = useCallback<() => void>(() => {
      setDraftFilters(activeFilters);
      setOpen(false);
    }, [activeFilters]);

    const onClearFilters = useCallback<() => void>(() => {
      onFilter(
        filters.reduce(
          (prevValue, { title }) => ({ ...prevValue, [title]: [] }),
          {},
        ),
      );
      setOpen(false);
    }, [filters, onFilter]);

    const onApplyFilters = useCallback<() => void>(() => {
      onFilter(draftFilters);
      setOpen(false);
    }, [draftFilters, onFilter]);

    return (
      <Stack ref={ref}>
        <AppButton
          title={t('filter')}
          ref={buttonRef}
          buttonProps={{
            ...buttonProps,
            onClick: () => setOpen(true),
          }}
          buttonSx={
            active
              ? {
                  color: 'primary.main',
                  borderColor: 'primary.main',
                  '&:hover, &:focus, &:active': {
                    color: 'primary.main',
                    borderColor: 'primary.main',
                  },
                }
              : undefined
          }
        >
          <FilterIcon />
        </AppButton>
        <AppDialog
          open={open}
          onClose={handleClose}
          title={t('filter_table', { table: t('projects') })}
          actions={
            <Stack width={1} direction="row" spacing={2}>
              <Button
                onClick={onClearFilters}
                variant="outlined"
                color="inherit"
                startIcon={<ClearIcon />}
                sx={{ flex: 1 }}
              >
                {t('clear')}
              </Button>
              <Button
                onClick={onApplyFilters}
                variant="contained"
                color="primary"
                startIcon={<ApplyIcon />}
                sx={{ flex: 2 }}
              >
                {t('apply')}
              </Button>
            </Stack>
          }
        >
          <Stack spacing={4}>
            {filters.map(({ title, label, options }, key) => (
              <Autocomplete
                key={key}
                multiple
                options={options
                  .map(({ value }) => value)
                  .sort((a, b) =>
                    a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1,
                  )}
                getOptionLabel={(option) =>
                  options.find(({ value }) => option === value)?.label ?? option
                }
                renderInput={(params) => (
                  <AppFormControl label={t(label)}>
                    <TextField {...params} />
                  </AppFormControl>
                )}
                value={draftFilters[title] ?? []}
                onChange={(_event, value) => {
                  setDraftFilters((prevState) => ({
                    ...prevState,
                    [title]: value,
                  }));
                }}
                ChipProps={{ size: 'small' }}
              />
            ))}
          </Stack>
        </AppDialog>
      </Stack>
    );
  },
);
FilterActionButton.displayName = 'FilterActionButton';
