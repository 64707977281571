import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemButton,
  List,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import {
  ArrowUpwardRounded as MoveUpIcon,
  ArrowDownwardRounded as MoveDownIcon,
  ArrowDropDownRounded as ExpandMoreIcon,
} from '@mui/icons-material';

import { SlicedModel } from 'api';
import { moveArray, subtract } from 'utils';
import { useSelector, useDispatch } from 'hooks';
import {
  selectProjectSettings,
  setObjectExposureOrder,
} from 'slices/projectSettingsSlice';
import { setModelHighlights } from 'slices/editorSlice';

interface Props {
  modelInfo: SlicedModel | undefined;
  disabled?: boolean;
}

export const ProjectExposureOrder: FC<Props> = ({ modelInfo, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { objectExposureOrder } = useSelector(selectProjectSettings);
  const [selectedObject, setSelectedObject] = useState<number | null>(null);

  const items = useMemo(
    (): { id: number; value: string; tooltip: string }[] =>
      (modelInfo?.objects ?? [])
        .toSorted(
          ({ id: a }, { id: b }) =>
            objectExposureOrder.indexOf(a) - objectExposureOrder.indexOf(b),
        )
        .map(({ id, name }) => ({
          id,
          value: name ?? t('object_{{id}}', { id }),
          tooltip: name ? t('object_{{id}}', { id }) : '',
        })),
    [modelInfo?.objects, objectExposureOrder, t],
  );

  const handleHighlightObject = useCallback(
    (objectId: number): void => {
      setSelectedObject((prevState) => {
        const targetObject = modelInfo?.objects.find(
          (object) => object.id === objectId && objectId !== prevState,
        );
        dispatch(
          setModelHighlights(
            targetObject
              ? [
                  {
                    startZMillimeter: subtract(
                      targetObject.startZMillimeter,
                      targetObject.layerThicknessMillimeter,
                    ),
                    endZMillimeter: targetObject.endZMillimeter,
                    objectId,
                  },
                ]
              : [],
          ),
        );
        return objectId !== prevState ? objectId : null;
      });
    },
    [dispatch, modelInfo?.objects],
  );

  const handleReorder = useCallback(
    (id: number, steps: number): void => {
      dispatch(
        setObjectExposureOrder(moveArray(objectExposureOrder, id, steps)),
      );
    },
    [dispatch, objectExposureOrder],
  );

  return (
    <Accordion sx={{ background: 'none', boxShadow: 'none' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
        <Typography fontWeight="bold">{t('exposure_order')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {items.map(({ id, value, tooltip }, index) => (
            <ListItem key={id} disablePadding>
              <Tooltip title={tooltip} placement="top" arrow>
                <ListItemButton
                  selected={selectedObject === id}
                  disabled={disabled}
                  onClick={() => handleHighlightObject(id)}
                  sx={{ flex: 10 }}
                >
                  <ListItemText primary={value} />
                </ListItemButton>
              </Tooltip>
              <ListItemButton
                onClick={() => handleReorder(id, -1)}
                disabled={index === 0}
                sx={{ flex: 1 }}
              >
                <ListItemIcon>
                  <MoveUpIcon />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton
                onClick={() => handleReorder(id, 1)}
                disabled={index === items.length - 1}
                sx={{ flex: 1 }}
              >
                <ListItemIcon>
                  <MoveDownIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
