import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'utils';
import {
  AreaShape,
  Coordinates,
  LayerFeed,
  ProHeat,
  ProjectSettingsResponse,
  UpdateProject,
} from 'api';

export const initialState: UpdateProject = {
  name: '',
  description: '',
  material: '',
  objectExposureOrder: [],
  shiftExposureOrder: false,
  simultaneousMelting: false,
  transformMillimeter: { x: 0, y: 0 },
  rotationDegree: 0,
  buildPlateSize: 100,
  plateShape: AreaShape.Circle,
  layerFeed: {
    buildPistonDistanceMillimeter: 0,
    powderPistonDistanceMillimeter: 0,
    recoaterSpeedMillimeterPerSecond: 200,
  },
  proHeat: {
    jumpSafe: false,
    spatterSafe: false,
    startHeat: false,
  },
};

const projectSettingsSlice = createSlice({
  name: 'projectSettings',
  initialState: initialState,
  reducers: {
    loadProjectSettings: (
      state,
      { payload }: PayloadAction<ProjectSettingsResponse>,
    ) => {
      state.name = payload.name;
      state.description = payload.description;
      state.material = payload.material;
      state.objectExposureOrder = payload.objectExposureOrder;
      state.shiftExposureOrder = payload.shiftExposureOrder;
      state.simultaneousMelting = payload.simultaneousMelting;
      state.transformMillimeter = payload.transformMillimeter;
      state.rotationDegree = payload.rotationDegree;
      state.buildPlateSize = payload.buildPlateSize;
      state.plateShape = payload.plateShape;
      state.layerFeed = payload.layerFeed;
      state.proHeat = payload.proHeat;
    },
    resetProjectSettings: (state) => {
      state.name = initialState.name;
      state.description = initialState.description;
      state.material = initialState.material;
      state.objectExposureOrder = initialState.objectExposureOrder;
      state.shiftExposureOrder = initialState.shiftExposureOrder;
      state.simultaneousMelting = initialState.simultaneousMelting;
      state.transformMillimeter = initialState.transformMillimeter;
      state.rotationDegree = initialState.rotationDegree;
      state.buildPlateSize = initialState.buildPlateSize;
      state.plateShape = initialState.plateShape;
      state.layerFeed = initialState.layerFeed;
      state.proHeat = initialState.proHeat;
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setDescription: (state, { payload }: PayloadAction<string>) => {
      state.description = payload;
    },
    setMaterial: (state, { payload }: PayloadAction<string>) => {
      state.material = payload;
    },
    setObjectExposureOrder: (state, { payload }: PayloadAction<number[]>) => {
      state.objectExposureOrder = payload;
    },
    setShiftExposureOrder: (state, { payload }: PayloadAction<boolean>) => {
      state.shiftExposureOrder = payload;
    },
    setSimultaneousMelting: (state, { payload }: PayloadAction<boolean>) => {
      state.simultaneousMelting = payload;
    },
    setTransformMillimeter: (
      state,
      { payload }: PayloadAction<{ key: keyof Coordinates; value: number }>,
    ) => {
      state.transformMillimeter = {
        ...state.transformMillimeter,
        [payload.key]: payload.value,
      };
    },
    setRotationDegree: (state, { payload }: PayloadAction<number>) => {
      state.rotationDegree = payload;
    },
    setLayerFeed: (
      state,
      { payload }: PayloadAction<{ key: keyof LayerFeed; value: number }>,
    ) => {
      state.layerFeed = { ...state.layerFeed, [payload.key]: payload.value };
    },
    setProHeat: (
      state,
      { payload }: PayloadAction<{ key: keyof ProHeat; value: boolean }>,
    ) => {
      state.proHeat = { ...state.proHeat, [payload.key]: payload.value };
    },
  },
});

export const {
  loadProjectSettings,
  resetProjectSettings,
  setName,
  setDescription,
  setMaterial,
  setObjectExposureOrder,
  setShiftExposureOrder,
  setSimultaneousMelting,
  setTransformMillimeter,
  setRotationDegree,
  setLayerFeed,
  setProHeat,
} = projectSettingsSlice.actions;

export const selectProjectSettings = (state: RootState): UpdateProject =>
  state.projectSettings;

export default projectSettingsSlice.reducer;
