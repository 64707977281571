import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { AppDeletionDialog } from 'components/AppDialog';
import { deleteModel, ErrorResponse, getModelPreview } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

interface Props {
  open: boolean;
  modelId: string;
  onClose: () => void;
}

export const DeleteModelDialog: FC<Props> = ({ open, modelId, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();

  const {
    data: preview,
    isLoading: isPreviewLoading,
    isRefetching: isPreviewRefetching,
    isError: isPreviewError,
    isRefetchError: isPreviewRefetchError,
  } = useQuery({
    queryKey: ['model', { modelId }],
    queryFn: () => (modelId ? getModelPreview(modelId) : undefined),
  });

  useEffect(() => {
    if (isPreviewError || isPreviewRefetchError) {
      enqueueSnackbar({
        key: `get_model_preview_fail_${Date.now()}`,
        message: t('get_model_preview_fail'),
        variant: 'error',
      });
      deleteSearchParam('rowId');
    }
  }, [
    deleteSearchParam,
    enqueueSnackbar,
    isPreviewError,
    isPreviewRefetchError,
    t,
  ]);

  const { mutate: onDeleteModel, isPending: isDeleting } = useMutation({
    mutationFn: () => {
      if (!preview) throw new Error('model preview does not exist');
      return deleteModel(preview.data.id, preview.etag);
    },
    onSuccess: () => {
      enqueueSnackbar({
        key: `delete_model_success_${Date.now()}`,
        message: t('delete_model_success', { name: preview?.data.name }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({ queryKey: ['models'] });
      queryClient.removeQueries({ queryKey: ['model', { modelId }] });
      queryClient.removeQueries({ queryKey: ['models-settings', { modelId }] });
      deleteSearchParam('rowId');
      onClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      if (response?.status === 412) {
        enqueueSnackbar({
          key: 'delete_model_decrepit',
          message: t('delete_model_decrepit', {
            name: preview?.data.name,
          }),
          variant: 'error',
          persist: true,
          action: (
            <IconButton
              color="inherit"
              onClick={() => {
                void queryClient
                  .refetchQueries({ queryKey: ['model', { modelId }] })
                  .then(() => {
                    closeSnackbar('delete_model_decrepit');
                  });
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        });
      } else {
        enqueueSnackbar({
          key: `delete_model_fail_${Date.now()}`,
          message: t('delete_model_fail', { name: preview?.data.name }),
          variant: 'error',
          persist: true,
        });
      }
    },
  });

  return (
    <AppDeletionDialog
      open={open}
      onClose={onClose}
      title={t('delete_model')}
      text={t('delete_model_confirmation', { name: preview?.data.name })}
      onConfirm={onDeleteModel}
      confirmDisabled={isDeleting || isPreviewLoading || isPreviewRefetching}
    />
  );
};
