export interface ValidateNumberOptions {
  min?: number;
  max?: number;
  orEqual?: boolean;
  devisable?: number;
}

export const isValidNumber = (
  value: string | number,
  options: ValidateNumberOptions | undefined = {},
): boolean => {
  const n: number = typeof value === 'number' ? value : parseFloat(value);
  return (
    !isNaN(n) &&
    isFinite(n) &&
    (options.min === undefined ||
      isNaN(options.min) ||
      (options.orEqual !== false ? n >= options.min : n > options.min)) &&
    (options.max === undefined ||
      isNaN(options.max) ||
      (options.orEqual !== false ? n <= options.max : n < options.max)) &&
    (options.devisable === undefined ||
      isNaN(options.devisable) ||
      n % options.devisable === 0)
  );
};

export const hasProperties = <T extends string>(
  value: unknown,
  ...properties: T[]
): value is Record<T, unknown> =>
  typeof value === 'object' &&
  properties.every((property) =>
    Object.prototype.hasOwnProperty.call(value, property),
  );

export const validateEmail = (email: string): boolean => {
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

/**
 * Validates if the password has all the requirements
 * @description WS Cognito password requirement:
    - 8-character minimum length
    - Contains at least 1 number
    - Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + = )
    - Contains at least 1 lowercase letter
    - Contains at least 1 uppercase letter
 * @param {string} password 
 * @returns {boolean}
 */
export const validateNewPassword = (password: string): boolean => {
  return !!password.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
  );
};
