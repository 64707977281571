import {
  AreaShape,
  Coordinates,
  FilteredResponse,
  ModelPreview,
  ODataRequestParams,
  ODataResponse,
  ResponseWithEtag,
  SuccessResponse,
} from 'api';

export enum ProjectState {
  NotGenerated = 'NotGenerated',
  GeneratedSuccessfully = 'GeneratedSuccessfully',
  Generating = 'Generating',
  GenerationFailed = 'GenerationFailed',
  GenerationCanceled = 'GenerationCanceled',
  GenerationQueued = 'GenerationQueued',
}

export interface LayerFeed {
  buildPistonDistanceMillimeter: number;
  powderPistonDistanceMillimeter: number;
  recoaterSpeedMillimeterPerSecond: number;
}
export interface Project {
  id: string;
  name: string;
  model: ModelPreview;
  state: ProjectState;
}

export type ProjectResponse = SuccessResponse<Project>;
export type ProjectRequestParams = ODataRequestParams<ProjectResponse>;
export type ODataProjects = ODataResponse<ProjectResponse>;
export type FilteredProjects = FilteredResponse<ProjectResponse>;

export interface ProjectPreview extends Project {
  description: string;
  material: string;
  progressPercentage: number;
  modelTransform: Coordinates;
}

export type ProjectPreviewResponse = SuccessResponse<ProjectPreview>;
export type ProjectPreviewWithEtag = ResponseWithEtag<ProjectPreviewResponse>;

export interface ProHeat {
  startHeat: boolean;
  jumpSafe: boolean;
  spatterSafe: boolean;
}

export interface ProjectSettings extends Omit<Project, 'state'> {
  description: string;
  material: string;
  objectExposureOrder: number[];
  shiftExposureOrder: boolean;
  simultaneousMelting: boolean;
  transformMillimeter: Coordinates;
  rotationDegree: number;
  buildPlateSize: number;
  plateShape: AreaShape;
  layerFeed: LayerFeed;
  proHeat: ProHeat;
}

export type ProjectSettingsResponse = SuccessResponse<ProjectSettings>;
export type ProjectSettingsResponseWithEtag =
  ResponseWithEtag<ProjectSettingsResponse>;

export interface CreateProject extends Omit<ProjectSettings, 'id' | 'model'> {
  modelId: string;
}

export type UpdateProject = Omit<ProjectSettings, 'id' | 'model'>;

export type CopyProject = Pick<
  ProjectSettings,
  'name' | 'description' | 'material'
>;

export enum ProcessStep {
  StartHeat = 'StartHeat',
  SpatterSafe = 'SpatterSafe',
  JumpSafe = 'JumpSafe',
  Melt = 'Melt',
  HeatBalance = 'HeatBalance',
}
