import {
  FC,
  useMemo,
  useState,
  createRef,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Stack, Button } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';

import { AppDialog } from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { createOrganization, CreateOrganization, ErrorResponse } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateOrganizationDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState<string>('');

  const [validationError, setValidationError] = useState<
    (keyof CreateOrganization)[]
  >([]);
  const refs = useMemo<
    Record<keyof CreateOrganization, RefObject<HTMLInputElement>>
  >(() => ({ name: createRef<HTMLInputElement>() }), []);

  const handleResetForm = useCallback<() => void>(() => {
    setName('');
  }, []);

  const handleClose = useCallback<() => void>(() => {
    handleResetForm();
    setValidationError([]);
    onClose();
  }, [handleResetForm, onClose]);

  const { mutate: onCreateOrganization, isPending: isCreating } = useMutation({
    mutationFn: () => createOrganization({ name }),
    onSuccess: ({ data }) => {
      enqueueSnackbar({
        key: `create_organization_success_${Date.now()}`,
        message: t('create_organization_success', { name: data.name }),
        variant: 'success',
      });
      void queryClient.invalidateQueries({
        queryKey: ['organizations'],
      });
      handleClose();
    },
    onError: ({ response }: AxiosError<ErrorResponse>) => {
      enqueueSnackbar({
        key: `create_organization_fail_${Date.now()}`,
        message: t('create_organization_fail'),
        variant: 'error',
        persist: true,
      });
      if (response?.status === 400) {
        if (response.data.errors) {
          const errorKeys = Object.keys(response.data.errors)
            .map<keyof CreateOrganization | undefined>((key) => {
              if (key === 'Name') return 'name';
              return undefined;
            })
            .filter((key): key is keyof CreateOrganization => !!key);
          setValidationError(errorKeys);
          refs[errorKeys[0]]?.current?.focus();
        }
      }
    },
  });

  useEffect(() => {
    setValidationError([]);
  }, [name]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('new_organization')}
      actions={
        <Button
          onClick={() => onCreateOrganization()}
          variant="contained"
          disabled={isCreating}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {t('create')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={name}
          onChange={setName}
          helperText={t('create_organization_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:create_organization.name')}
        />
      </Stack>
    </AppDialog>
  );
};
