import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AppTextForm,
  AppNumberForm,
  AppCheckboxForm,
} from 'components/AppFormControl';
import { AlgorithmSetting } from 'api';

interface Props extends AlgorithmSetting {
  value: string | undefined;
  setValue: (v: string) => void;
  method: 'pointSpread' | 'dwellTime';
  error?: boolean;
  disabled?: boolean;
  varies?: boolean;
}

export const ProjectAlgorithmSetting: FC<Props> = ({
  value = '',
  setValue,
  method,
  error,
  disabled,
  varies,
  settingsName,
  unit,
  required,
  type,
  minValue,
  maxValue,
}) => {
  const { t } = useTranslation();

  const parsedMinValue = useMemo<number | undefined>(
    () =>
      minValue && !isNaN(parseFloat(minValue))
        ? parseFloat(minValue)
        : undefined,
    [minValue],
  );

  const parsedMaxValue = useMemo<number | undefined>(
    () =>
      maxValue && !isNaN(parseFloat(maxValue))
        ? parseFloat(maxValue)
        : undefined,
    [maxValue],
  );

  const label = useMemo<string>(
    () => t(`algorithms:${method}.${settingsName}`),
    [method, settingsName, t],
  );

  const helperText = useMemo<string>(
    () => t(`algorithms:${method}.${settingsName}_helper`),
    [method, settingsName, t],
  );

  switch (type) {
    case 'string':
      return (
        <AppTextForm
          label={label}
          value={value}
          onChange={setValue}
          componentProps={{ disabled, required }}
          helperText={helperText}
          errorText={t(`validations:process_step_parameters.${method}Settings`)}
          error={error}
          varies={varies}
        />
      );
    case 'number':
      return (
        <AppNumberForm
          label={label}
          value={parseFloat(value)}
          onChange={(v) => setValue(String(v))}
          unit={unit ? t(`unit:${unit}`) : undefined}
          min={parsedMinValue}
          max={parsedMaxValue}
          componentProps={{ disabled, required }}
          helperText={helperText}
          errorText={t(`validations:process_step_parameters.${method}Settings`)}
          error={error}
          varies={varies}
        />
      );
    case 'boolean':
      return (
        <AppCheckboxForm
          label={label}
          checked={value === 'true'}
          onChange={(v) => setValue(String(v))}
          componentProps={{ disabled, required }}
          helperText={helperText}
          errorText={t(`validations:process_step_parameters.${method}Settings`)}
          error={error}
          varies={varies}
        />
      );
    default:
      return null;
  }
};
